import { DialogTitle } from '@components/titles'
import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { MedicalOrderDto, MedicalOrderStatusEnum } from '@services/api'
import { useSelector } from '@state/store'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { cleanStudy } from '@state/reducers/studyReducer'

import { Text } from '@components/texts'
import theme from '@utils/theme'

type ReportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}

export function StudyDialog({ open, setOpen }: ReportDialogProps) {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { study } = useSelector(({ study }) => ({
    study: study.selected as MedicalOrderDto,
  }))
  const documents = useMemo(() => study.documents, [study.documents])

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      return
    }
    return () => {
      dispatch(cleanStudy())
    }
  }, [study.reports, documents.length, open])

  const {
    referringDoctor,
    referringDoctorName,
    attendingDoctor,
    attendingDoctorName,
    site,
  } = study

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: '100%',
          background: '#E2E6F2',
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={`${study?.exam.label} - ${
          study.plannedDate
            ? study.plannedDate
              .toString()
              .replace(
                /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
                '$3/$2/$1',
              )
            : ''
        }`}
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent style={{ padding: 30 }}>
        <List>
          <Grid
            container
            spacing={2}
            style={{ background: '#fff', borderRadius: '20px', padding: 10 }}
          >
            <Grid item xs={6}>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    className="fa fa-user-md"
                    style={{ color: theme.palette.primary.main }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={intl.formatMessage({ id: 'referringDoctor' })}
                  secondary={
                    referringDoctor
                      ? `${referringDoctor?.firstName} ${referringDoctor?.lastName}`
                      : !referringDoctor && referringDoctorName
                        ? referringDoctorName
                        : intl.formatMessage({ id: 'noReferringDoctor' })
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black', textTransform: 'capitalize' },
                  }}
                />
              </ListItem>
              <Text
                text={referringDoctor?.skill}
                align="left"
                style={{
                  fontSize: '14px',
                  marginLeft: '72px',
                  marginTop: '-10px',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    className="fa fa-user-md"
                    style={{ color: theme.palette.primary.main }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={intl.formatMessage({ id: 'attendingDoctor' })}
                  secondary={
                    attendingDoctor
                      ? `${attendingDoctor?.firstName} ${attendingDoctor?.lastName}`
                      : !attendingDoctor && attendingDoctorName
                        ? attendingDoctorName
                        : intl.formatMessage({ id: 'noAttendingDoctor' })
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black', textTransform: 'capitalize' },
                  }}
                />
              </ListItem>
              <Text
                text={attendingDoctor?.skill}
                align="left"
                style={{
                  fontSize: '14px',
                  marginLeft: '72px',
                  marginTop: '-10px',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    className="fa fa-map-marker"
                    style={{ color: theme.palette.primary.main }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`Site: ${site.label}`}
                  secondary={
                    study.site.streetAddress +
                    (study.site.streetAddress.includes(
                      study.site.postalCode.toString(),
                    )
                      ? ''
                      : ', ' + study.site.postalCode)
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black' },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={6}>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    className="fa fa-phone"
                    style={{ color: theme.palette.primary.main }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Contact"
                  secondary={
                    site && site.phoneNumber ? (
                      <a href={`tel:${site.phoneNumber}`}>{site.phoneNumber}</a>
                    ) : (
                      intl.formatMessage({ id: 'noContact' })
                    )
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black' },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    className="fa fa-x-ray"
                    style={{ color: theme.palette.primary.main }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={intl.formatMessage({ id: 'modality' })}
                  secondary={intl.formatMessage({
                    id: 'enums.modality.' + study.exam.modality,
                  })}
                  secondaryTypographyProps={{
                    style: { color: 'black' },
                  }}
                />
              </ListItem>
            </Grid>
            {study.status === MedicalOrderStatusEnum.completed && (
              <Grid item xs={6}>
                <ListItem>
                  <ListItemIcon>
                    <Icon
                      className="fa fa-images"
                      style={{ color: theme.palette.primary.main }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage({ id: 'imageNumber' })}
                    secondary={
                      study.radiologyStudy?.imagesNumber
                        ? study.radiologyStudy.imagesNumber
                        : intl.formatMessage({ id: 'unknown' })
                    }
                    secondaryTypographyProps={{
                      style: { color: 'black' },
                    }}
                  />
                </ListItem>
              </Grid>
            )}
          </Grid>
        </List>
      </DialogContent>
    </Dialog>
  )
}
