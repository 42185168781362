import { DialogTitle } from '@components/titles'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { Button } from '@components/buttons'
import {
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import theme from '@utils/theme'
import { useState } from 'react'
import { MedicalOrderDto } from '../../services/api'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { cleanStudy } from '../../state/reducers/studyReducer'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { update } from '../../state/thunks/studyThunk'

type DoseDialogProps = {
  open: boolean
  study: MedicalOrderDto
  onClose: () => void
}

export function DoseDialog({ open, study, onClose }: DoseDialogProps) {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [forceDose, setForceDose] = useState(false)
  const [patientWeight] = useState(study.patient.weight)
  const [patientHeight] = useState(study.patient.height)
  const [dose, setDose] = useState(
    study.confirmedNmDose ||
      calculateDose(study.patient.weight, study.dosePerKg || 0),
  )

  const [commentaires, setCommentaires] = useState(study.commentairesDose ?? '')
  const dosePerKg = study.dosePerKg || 0

  function calculateIMC(weight: number, height: number) {
    const heightInMeters = height / 100
    return (weight / (heightInMeters * heightInMeters)).toFixed(2)
  }

  function calculateDose(weight: number, rule: number) {
    return weight && rule ? weight * rule : 0
  }

  function handleForceDose() {
    setForceDose(!forceDose)
  }

  function onSave() {
    dispatch(
      update({
        id: study.id,
        body: {
          confirmedNmDose: forceDose ? dose : null,
          commentairesDose: commentaires,
        },
      }),
    )
      .unwrap()
      .then(() => {
        setForceDose(false)
        dispatch(
          enqueueSnackbar({
            message: 'Sauvegarde réussie',
            type: 'success',
          }),
        )
      })
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: { borderRadius: 20, width: '100%', background: '#E2E6F2' },
      }}
      onClose={() => {
        onClose()
        dispatch(cleanStudy())
      }}
    >
      <IconButton
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#E91E63',
        }}
        onClick={() => {
          dispatch(cleanStudy())
          onClose()
        }}
      >
        <i className="fas fa-times" />
      </IconButton>
      <DialogTitle
        title={`${intl.formatMessage({ id: 'precribeDose' })} - ${
          study?.exam?.label
        } - ${study?.patient?.firstName[0]}.${study?.patient?.lastName}`}
        style={{ background: '#fff', color: theme.palette.primary.main }}
      />
      <DialogContent style={{ padding: '20px' }}>
        <Grid
          container
          style={{ background: '#fff', borderRadius: '20px', padding: '15px' }}
        >
          <Grid item xs={12}>
            <table
              style={{
                width: '100%',
                marginBottom: '20px',
                borderCollapse: 'collapse',
              }}
            >
              <thead>
                <tr style={{ background: '#D6E5FA' }}>
                  <th
                    style={{
                      padding: '10px',
                      borderTopLeftRadius: '8px',
                      fontWeight: 'bold',
                      color: '#3f3f3f',
                    }}
                  >
                    Taille
                  </th>
                  <th
                    style={{
                      padding: '10px',
                      fontWeight: 'bold',
                      color: '#3f3f3f',
                    }}
                  >
                    Poids
                  </th>
                  <th
                    style={{
                      padding: '10px',
                      borderTopRightRadius: '8px',
                      fontWeight: 'bold',
                      color: '#3f3f3f',
                    }}
                  >
                    IMC
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      border: '1px solid #e0e0e0',
                      fontWeight: 'bold',
                      color: '#3f3f3f',
                    }}
                  >
                    {patientHeight}
                  </td>
                  <td
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      border: '1px solid #e0e0e0',
                      fontWeight: 'bold',
                      color: '#3f3f3f',
                    }}
                  >
                    {patientWeight}
                  </td>
                  <td
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      border: '1px solid #e0e0e0',
                      fontWeight: 'bold',
                      color: '#3f3f3f',
                    }}
                  >
                    {patientWeight && patientHeight
                      ? calculateIMC(patientWeight, patientHeight)
                      : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={6}>
            {forceDose ? (
              <TextField
                id="dose"
                label={forceDose ? 'Dose forcée' : 'Dose calculée'}
                value={dose}
                onChange={(e) => setDose(parseInt(e.target.value))}
                type="number"
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              <>
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom
                  style={{
                    color: 'gray',
                    // set italic
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                  }}
                >
                  Dose pré-calculée: {calculateDose(patientWeight, dosePerKg)}{' '}
                  MBq
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {study.confirmedNmDose && !forceDose ? (
              <div
                style={{
                  display: 'flex',

                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom
                  style={{
                    color: '#3f3f3f',
                    fontWeight: 'bold',
                  }}
                >
                  Dose confirmée: {study.confirmedNmDose} MBq
                </Typography>
                <Icon
                  className="fas fa-check"
                  style={{
                    color: 'lightgreen',
                    transform: 'translateY(-5px) translateX(5px)',
                    fontSize: '1.2em',
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              padding: '10px 0px',
            }}
          >
            <InputLabel variant="standard" htmlFor="commentaire">
              Commentaires
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id="commentaire"
                value={commentaires}
                onChange={(ev) => {
                  setCommentaires(ev.target.value)
                }}
                multiline
                rows={4}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              text={forceDose ? 'Annuler' : 'Forcer Dose'}
              color="secondary"
              fullWidth
              onClick={handleForceDose}
              style={{
                marginRight: 20,
              }}
            />
            <Button
              variant="contained"
              text="Sauvegarder"
              color="primary"
              fullWidth
              onClick={onSave}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
