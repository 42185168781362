import { useEffect } from 'react'
import io from 'socket.io-client'
import { useSelector } from '../state/store'
import { MessageNamesEnum } from '../services/api'
import StorageService from '../services/storage'

const client = io(`${process.env.REACT_APP_WEBSOCKET_URL}/messages`, {
  auth: {
    token: StorageService.getAuthToken(),
  },
  transports: ['websocket'],
  upgrade: false,
})

client.connect()

client.on('connect', () => {
  console.log('connected')
})

client.on('connect_error', (error) => {
  console.error('WebSocket connection error:', error)
})

client.on('connect_timeout', (timeout) => {
  console.error('WebSocket connection timeout:', timeout)
})
export type MessageHandlerFunc = <T>(data: T) => void
export interface MessageHandler {
  handlers: { messageName: MessageNamesEnum; fn: MessageHandlerFunc }[]
}

export function useWebsocket(props: MessageHandler) {
  const account = useSelector((state) => state.auth.account)

  useEffect(() => {
    if (client.connected) {
      props.handlers.forEach((handler) => {
        client.on(handler.messageName, handler.fn)
      })
    }
    return () => {
      props.handlers.forEach((handler) => {
        client.off(handler.messageName, handler.fn)
      })
    }
  }, [client.connected])

  const sendMessage = <T>(messageName: MessageNamesEnum, data: T) => {
    const message: MessagePayload<T> = {
      accountId: account?.id || 0,
      payload: data,
    }

    client.emit(messageName, message)
  }

  return { sendMessage }
}

export interface MessagePayload<T> {
  accountId: number
  payload: T
}
