import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import {
  AsyncThunk,
  createSlice,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit'

import { AccountDto, Doctor, LoginResDto, Patient } from '@services/api'
import StorageService from '@services/storage'
import {
  findMyInfos,
  getCaptcha,
  refreshToken,
  registerUser,
  updateEmail,
  updatePatient,
  updatePhone,
  verifyCode,
} from '@state/thunks/authThunk'

type authState = {
  captcha?: string
  isAuth: boolean
  hasConsent: boolean
  account?: AccountDto
  user?: Patient | Doctor
  language: string
  signFail: {
    failure: number
    period: number
    window: number
    timeout: number
  }
  register: {
    isRegistered: boolean
  }
  isAuthChecked: boolean
  loading: boolean
  message?: string | null
  isError?: string | null
  userConfiguration: {
    tableState: GridInitialStatePro | null
  }
  isPhoneUpdateRequestSent: boolean
}

const initialState: authState = {
  isAuth: false,
  hasConsent: false,
  language: navigator.language.split(/[-_]/)[0],
  signFail: {
    failure: 0,
    period: 0,
    window: 0,
    timeout: 0,
  },
  register: {
    isRegistered: false,
  },
  isAuthChecked: false,
  loading: false,
  userConfiguration: {
    tableState: null,
  },
  isPhoneUpdateRequestSent: false,
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginFulfilled: (state, { payload }: PayloadAction<LoginResDto>) => {
      StorageService.setAuthToken(payload.token as string)
      state.account = payload.user
      state.account.devices = payload.devices
      state.isAuth = true
    },
    updateAccount: (state, { payload }: PayloadAction<any>) => {
      state.account = { ...state.account, ...payload }
    },
    updateDisplayPreferences: (state, { payload }: PayloadAction<any>) => {
      if (state.user) {
        state.user.preferencesDisplayHomeColumnorder =
          payload.preferencesDisplayHomeColumnorder

        state.user.preferencesDisplayHomeColumnwidth =
          payload.preferencesDisplayHomeColumnwidth
        state.user.preferencesDisplayHomeHiddencolumns =
          payload.preferencesDisplayHomeHiddencolumns
      }
    },
    updateHiddenColumns: (state, { payload }: PayloadAction<any>) => {
      if (state.user) state.user.preferencesDisplayHomeHiddencolumns = payload
    },
    updateColumnOrder: (state, { payload }: PayloadAction<any>) => {
      if (state.user) state.user.preferencesDisplayHomeColumnorder = payload
    },
    updateColumnWidths: (state, { payload }: PayloadAction<any>) => {
      if (state.user) state.user.preferencesDisplayHomeColumnwidth = payload
    },
    setIsPhoneUpdateRequestSent: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isPhoneUpdateRequestSent = payload
    },
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload
    },
    consentFulfilled: (state) => {
      state.hasConsent = true
    },
    setIsAuthChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthChecked = payload
    },
    setUserConfiguration: (
      state,
      {
        payload,
      }: PayloadAction<{
        tableState: GridInitialStatePro | null
      }>,
    ) => {
      state.userConfiguration = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCaptcha.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.captcha = payload.captcha
        },
      )
      .addCase(
        findMyInfos.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.user = payload
          if (state.user && payload.preferencesDisplayHomeColumnwidth) {
            state.user.preferencesDisplayHomeColumnwidth = JSON.parse(
              payload.preferencesDisplayHomeColumnwidth,
            )
          }
        },
      )
      .addCase(
        refreshToken.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          StorageService.setAuthToken(payload)
          state.isAuth = true
          state.isAuthChecked = true
        },
      )
      .addCase(registerUser.fulfilled, (state) => {
        state.register.isRegistered = true
      })
      .addCase(
        updatePatient.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.user = payload
        },
      )
      .addCase(verifyCode.fulfilled, (state) => {
        state.isAuth = true
        if (state.account) {
          state.account.isPhoneVerified = true
        }
      })
      .addCase(updatePhone.fulfilled, (state) => {
        state.isPhoneUpdateRequestSent = true
      })

      .addCase(updateEmail.pending, (state) => {
        state.loading = true
        state.isError = null
        state.message = null
      })
      .addCase(updateEmail.fulfilled, (state) => {
        state.loading = false
        state.message = 'Email updated successfully'
        state.isError = null
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.loading = false
        state.message = null
        if (action.payload && action.payload.message) {
          state.isError = action.payload.message
        } else {
          state.isError = 'Failed to update email'
        }
      })
      .addMatcher<RejectedAction>(isRejected, (state, { payload }) => {
        if (payload && (payload as any).statusCode === 401) {
          state.isAuth = false
        }
      })
  },
})

export const {
  loginFulfilled,
  consentFulfilled,
  setLanguage,
  updateAccount,
  updateHiddenColumns,
  updateColumnOrder,
  updateColumnWidths,
  setIsAuthChecked,
  setUserConfiguration,
  setIsPhoneUpdateRequestSent,
} = authSlice.actions

export default authSlice.reducer
