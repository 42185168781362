import TelemedTable from '../../../components/tables/medical-order-table/telemedicine/TelemedTable'

const HomeTelemedDoctor = () => {
  return (
    <div
      style={{
        width: 'calc(100vw - 30px)',
      }}
    >
      <TelemedTable />
    </div>
  )
}

export default HomeTelemedDoctor
