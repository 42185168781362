import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordInput, TextInput } from '@ether-healthcare/inputs'
import { Button } from '@components/buttons'

import { AppDispatch, useSelector } from '@state/store'
import { registerGuestSchema } from '@utils/schemas'

import { AuthCard } from '../../components/cards/AuthCard'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { registerGuest } from '@state/thunks/authThunk'
import { useQuery } from '@hooks/useQuery'
const useStyles = makeStyles({
  img: {
    backgroundColor: 'white',
  },
  gridItem: {
    backgroundColor: 'white',
    marginBottom: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    padding: '0px 20px',
  },
})

interface Values {
  verificationUUID: string
  id: string
  newPassword: string
  confirmNewPassword: string
  lastName: string
  firstName: string
}

export default function RegisterGuest() {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const query = useQuery()
  const loading = useSelector(({ loading }) => loading['auth/register/guest'])
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Values>({
    resolver: yupResolver(registerGuestSchema),
  })

  const onSubmit = (values: Values) => {
    dispatch(
      registerGuest({
        firstName: values.firstName.trim(),
        lastname: values.lastName.trim(),
        id: parseInt(values.id),
        verificationUUID: values.verificationUUID,
        password: values.newPassword.trim(),
      }),
    )
  }

  useEffect(() => {
    if (location && location.search) {
      const uuid = query.get('uuid')
      const id = query.get('id')
      if (id && uuid) {
        setValue('verificationUUID', uuid)
        setValue('id', id)
      } else {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: 'Invalid user informations',
          }),
        )
        navigate('/auth/login')
      }
    } else {
      navigate('/auth/login', { replace: true })
    }
  }, [dispatch, history, location, query, control, setValue])

  const Content = (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.grid}
    >
      <Grid item xs={12} sm={12}>
        <TextInput
          label="lastName"
          name="lastName"
          errors={errors}
          control={control}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          label="firstName"
          name="firstName"
          errors={errors}
          control={control}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <PasswordInput
          name="newPassword"
          label="Password"
          variant="filled"
          errors={errors}
          control={control}
          autoComplete="new-password"
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <PasswordInput
          name="confirmNewPassword"
          variant="filled"
          label="Password"
          errors={errors}
          control={control}
          autoComplete="new-password"
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          color="secondary"
          format
        />
      </Grid>
    </Grid>
  )

  return (
    <AuthCard>
      <AuthCard.Header title="register" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions backToLoginLink />
    </AuthCard>
  )
}
