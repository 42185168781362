import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
// import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'

type DateInputProps = {
  name: string
  control: Control<any>
  errors?: DeepMap<any, FieldError>
  minDate?: Date
  maxDate?: Date
  defaultDate?: Date
  readOnly?: boolean
  variant?: 'outlined' | 'standard' | 'filled'
}

const useStyles = makeStyles({
  datePicker: {
    margin: 0,
  },
  inputLabelProps: {
    fontWeight: 500,
    padding: '0px 5px 2px 5px',
    borderRadius: 2,
  },
})

export function DateInput({
  name,
  control,
  errors,
  minDate = new Date('01/01/1900'),
  maxDate = new Date('01/01/2025'),
  defaultDate = new Date(),
  variant = 'outlined',
  readOnly,
}: DateInputProps) {
  // const intl = useIntl()

  const classes = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultDate}
      render={({ field: { onChange, value } }) => (
        <KeyboardDatePicker
          className={classes.datePicker}
          fullWidth
          size="small"
          onChange={onChange}
          value={value}
          clearable
          margin="dense"
          inputVariant={variant}
          minDate={minDate}
          maxDate={maxDate}
          InputAdornmentProps={{ position: 'end' }}
          format="dd/MM/yyyy"
          InputLabelProps={{
            style: {
              fontWeight: 500,
              padding: '0px 5px 2px 5px',
              borderRadius: 2,
            },
          }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
            },
            readOnly,
          }}
          // label={name}
          error={errors && !!errors[name]}
          helperText={errors && errors[name] && errors[name].message}
        />
      )}
    />
  )
}
