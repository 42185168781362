import { MedicalOrderDto } from './api'

export const openViewer = async (
  { radiologyStudy }: MedicalOrderDto,
  token: string | null,
) => {
  try {
    if (!token) {
      alert('Unable to open the viewer, please try again another time')
      return
    }
    const url = `${process.env.REACT_APP_RADIO_VIEWER_URL}/launch?username=authtoken&password=${token}&action=view&StudyInstanceUID=${radiologyStudy.uid}`

    const win = window.open(url, '_blank')
    if (win) {
      win.focus()
    } else {
      throw new Error('OPEN_VIEWER_ERROR')
    }
  } catch (e) {
    console.log(e)
  }
}
