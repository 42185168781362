import { SharingExpirationEnum } from '@services/api'
import moment from 'moment'

export const isSharingExpired = (
  startDate: Date,
  expiration: SharingExpirationEnum,
) => {
  let expirationDate = new Date()
  switch (expiration) {
    case SharingExpirationEnum.ONE_YEAR:
      expirationDate = moment(startDate).add(12, 'months').toDate()
      break
    case SharingExpirationEnum.NO_EXPIRATION:
    default:
      return false
  }
  if (expirationDate.getTime() < Date.now()) return true
  else return false
}

export function formatNumber(phone: string): string {
  if (phone.startsWith('6') || phone.startsWith('7')) {
    phone = '0' + phone
  }
  const frenchFormat = 'xx xx xx xx xx'
  let output = ''
  let pos = 0
  const inputLength = phone.length
  for (let i = 0; i < frenchFormat.length; i++) {
    const f = frenchFormat[i]
    if (f === 'x') {
      output += phone[pos++]
      if (pos >= inputLength) {
        break
      }
    } else {
      output += ' '
    }
  }
  return output
}

export function validateMobileNumber(phone: string): boolean {
  if (phone.startsWith('0') && phone.length === 1) {
    return true
  }
  return (
    phone.startsWith('06') ||
    phone.startsWith('07') ||
    phone.startsWith('6') ||
    phone.startsWith('7')
  )
}

export const getMimeType = (fileName: string): string => {
  const ext = fileName.split('.').pop()
  switch (ext) {
    case 'pdf':
      return 'application/pdf'
    case 'png':
      return 'image/png'
    case 'jpg':
      return 'image/jpeg'
    case 'jpeg':
      return 'image/jpeg'
    case 'gif':
      return 'image/gif'
    case 'doc':
      return 'application/msword'
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'ppt':
      return 'application/vnd.ms-powerpoint'
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    default:
      return 'application/octet-stream'
  }
}
