import { Button } from '@components/buttons'
import { Text } from '@components/texts'
import { AuthTitle } from '@components/titles'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { motion } from 'framer-motion/dist/framer-motion'

interface CardProps {
  width?: number
  children?: any
}

type HeaderCardProps = {
  title: string
  children?: JSX.Element
}

type ContentCardProps = {
  children: JSX.Element
}

type ActionsCardProps = {
  backToLoginLink?: boolean
  children?: JSX.Element
}

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 455,
    backgroundImage: 'linear-gradient(45deg, #303f9f, #7b1fa2)',
    borderRadius: 25,
  },
  text: {
    fontWeight: 300,
    paddingBottom: 10,
    paddingTop: 10,
    color: '#fff',
  },
  cardContent: {
    padding: 0,
  },
  cardAction: {
    padding: 10,
  },
  iosIcon: {
    color: theme.palette.text.secondary,
  },
}))

function AuthCard({ children }: CardProps) {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      direction="column"
      style={{ zIndex: 10 }}
    >
      <motion.div
        animate={{
          y: [1500, 0],
        }}
        transition={{ duration: 0.5 }}
      >
        <Card
          className={`gradient-indigo-purple ${classes.card}`}
          elevation={0}
        >
          {children}
        </Card>
      </motion.div>
    </Grid>
  )
}
function Header({ title, children }: HeaderCardProps) {
  const classes = useStyles()

  return (
    <CardHeader
      title={<AuthTitle />}
      subheader={
        <>
          {children}
          <Text
            text={title}
            variant="h4"
            align="center"
            className={classes.text}
            format
          />
        </>
      }
    />
  )
}

function Content({ children }: ContentCardProps) {
  const classes = useStyles()

  return <CardContent className={classes.cardContent}>{children}</CardContent>
}

function Actions({ children, backToLoginLink }: ActionsCardProps) {
  const classes = useStyles()

  return (
    <CardActions className={classes.cardAction}>
      <Grid item container xs={12} sm={12}>
        {children}
        {backToLoginLink && (
          <Button
            startIcon={<ArrowBackIosIcon className={classes.iosIcon} />}
            text="back-to-login"
            link="/auth/login"
            format
          />
        )}
      </Grid>
    </CardActions>
  )
}
// AuthCard.Title = Title
AuthCard.Header = Header
AuthCard.Content = Content
AuthCard.Actions = Actions

export { AuthCard }
