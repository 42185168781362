import {
  Avatar,
  Badge,
  Container,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import { AccountCircle, ExitToApp, Notifications } from '@material-ui/icons'
import { useSelector } from '@state/store'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { logout } from '@state/thunks/authThunk'

import { Text } from '@components/texts'
import { AccountRoleEnum } from '@services/api'
import theme from '@utils/theme'
import { FormattedMessage } from 'react-intl'
import logo from '../assets/img/logos/logo-ether.png'
import { readNotifications } from '../state/thunks/notificationThunk'
import { getNotificationText } from '../utils/helpers'
import { HelpDialog } from './dialogs'

export default function NavBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setHelpOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null)

  const { user, account } = useSelector(({ auth }) => ({
    user: auth.user,
    account: auth.account,
  }))

  const notifications = useSelector(
    ({ notification }) => notification.notifications.datas,
  )

  const handleHelpOpen = () => {
    setHelpOpen(true)
  }
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget)
    dispatch(readNotifications({ ids: notifications.map((notif) => notif.id) }))
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null)
  }
  const handleLogout = () => {
    dispatch(logout())
  }

  const handleProfile = () => navigate('/profile')

  const unreadLength = useMemo(() => {
    let count = 0
    notifications.forEach((_notif) => {
      if (!_notif.isRead) {
        count++
      }
    })
    return count
  }, [notifications])

  const menuId = 'account-menu'
  const renderAccountMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleProfile(), handleMenuClose()
        }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="account" />} />
      </MenuItem>
      <MenuItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="logout" />} />
      </MenuItem>
    </Menu>
  )

  const notificationMenuId = 'notification-menu'

  const renderNotificationMenu = (
    <Menu
      id={notificationMenuId}
      anchorEl={notificationAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(notificationAnchorEl)}
      onClose={handleNotificationMenuClose}
    >
      {notifications.map((_n, index) => (
        <MenuItem key={index}>
          <Text
            key={index}
            variant="caption"
            text={getNotificationText(_n)}
            style={{
              color:
                _n.isRead === false ? theme.palette.secondary.main : 'grey',
            }}
          />
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <>
      <HelpDialog open={open} setHelpOpen={setHelpOpen} />
      <Toolbar
        style={{
          borderBottom: '1px solid grey',
          background: '#fff',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          position: 'fixed',
          top: 0,
          boxShadow: '-5px 5px 2px -5px #000000',
          zIndex: 100,
          height: '48px',
        }}
      >
        <div
          onClick={() => navigate('/')}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {location.pathname === '/profile' ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  className="fa fa-home"
                  style={{ color: theme.palette.secondary.main }}
                />
                <Text
                  variant={'h6'}
                  text="backToHome"
                  style={{
                    color: theme.palette.secondary.main,
                    marginLeft: '10px',
                    transform: 'translateY(2px)',
                  }}
                  format
                />
              </div>
            </>
          ) : (
            <>
              <Icon
                className="fa fa-home"
                style={{ color: theme.palette.primary.main }}
              />
              <Text
                variant={'h6'}
                text={
                  account?.role === AccountRoleEnum.patient
                    ? 'my-studies'
                    : 'my-patients'
                }
                style={{
                  color: theme.palette.primary.main,
                  marginLeft: '10px',
                }}
                format
              />
            </>
          )}
        </div>

        <img
          src={logo}
          alt="logo"
          style={{
            width: '125px',
            position: 'absolute',
            left: '50%',
            marginLeft: '-125px',
            display: 'block',
          }}
        ></img>

        <div style={{ marginRight: '20px' }}>
          {user && (
            <Container
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '1.2em',
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  textTransform: 'capitalize',
                }}
              >{`${user.firstName} ${user.lastName}`}</div>
              <div>
                <Tooltip title="Aide">
                  <Icon
                    className="fas fa-question-circle"
                    onClick={handleHelpOpen}
                    style={{
                      fontSize: '1.2em',
                      color: theme.palette.primary.main,
                      marginLeft: '15px',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </div>
              <Tooltip title="notification(s)">
                <span>
                  <IconButton
                    aria-label="notifications"
                    aria-controls={notificationMenuId}
                    onClick={handleNotificationMenuOpen}
                    disabled={!notifications.length}
                  >
                    <Badge badgeContent={unreadLength} color="secondary">
                      <Notifications />
                    </Badge>
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  user.firstName.toUpperCase() +
                  ' ' +
                  user.lastName.toUpperCase()
                }
              >
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                >
                  <Avatar
                    style={{
                      color: '#fff',
                      background: theme.palette.primary.main,
                      textTransform: 'capitalize',
                    }}
                  ></Avatar>
                </IconButton>
              </Tooltip>
            </Container>
          )}
        </div>
        {renderNotificationMenu}
        {renderAccountMenu}
      </Toolbar>
    </>
  )
}
