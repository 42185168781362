import { axios, getConfigs, IRequestConfig, MessageDto } from './api'

export class MessageService {
  private static basePath = process.env.REACT_APP_API_URL + '/messages'

  static findMessages(
    entityId: number,
    entityType: string,
    page = 0,
    limit = 20,
  ): Promise<{ totalCount: number; datas: MessageDto[] }> {
    return new Promise((resolve, reject) => {
      const url = `${this.basePath}?entityId=${entityId}&entityType=${entityType}&page=${page}&limit=${limit}`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }
}
