import { Card, CardContent, Typography, Box, IconButton } from '@mui/material'
import React from 'react'
import { Client, ReportTemplateDto } from '../../services/api'
import moment from 'moment'
import { Link } from 'react-router-dom'

interface Props {
  template: ReportTemplateDto
  client: Client
  onEdit: (template: ReportTemplateDto) => void
  onDeleteClick: (template: ReportTemplateDto) => void
}
const TemplateCard: React.FC<Props> = ({ template, onEdit, onDeleteClick }) => {
  return (
    <Card sx={{ display: 'flex', position: 'relative' }}>
      <div
        style={{
          fontSize: '8px !important',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <IconButton
          style={{
            color: 'black',
            fontSize: '8px !important',
          }}
          onClick={() => {
            onEdit(template)
          }}
        >
          <i style={{ fontSize: '14px' }} className="fas fa-pen"></i>
        </IconButton>
        <IconButton
          style={{
            color: 'black',
            fontSize: '8px !important',
          }}
          onClick={() => {
            onDeleteClick(template)
          }}
        >
          <i style={{ fontSize: '14px' }} className="fas fa-trash"></i>
        </IconButton>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '4px',
          flex: 1,
        }}
      >
        <CardContent sx={{ flex: '1' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography component="div" variant="h5">
                {template.name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {template.modality}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    fontSize: '14px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  >
                    Créé par
                  </Typography>
                  {template.createdBy?.member?.firstName}{' '}
                  {template.createdBy?.member?.lastName}
                </div>
                <div
                  style={{
                    marginLeft: '20px',
                    fontSize: '14px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  >
                    Le
                  </Typography>
                  {moment(template.createdAt).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Link
                style={{
                  color: '#2980b9',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
                to={`/report-templates/${template.id}/paragraphs`}
              >
                Paragraphes
              </Link>
            </div>
          </div>
        </CardContent>
      </Box>
    </Card>
  )
}

export default TemplateCard
