import { Button } from '@components/buttons'
import { Text } from '@components/texts'
import { DialogTitle } from '@components/titles'
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { logout } from '@state/thunks/authThunk'
import { useDispatch } from 'react-redux'
type ConsentDialogProps = {
  open: boolean
  acceptConsent: () => void
}

export function ConsentDialog({ open, acceptConsent }: ConsentDialogProps) {
  const dispatch = useDispatch()

  const handleAccept = () => {
    acceptConsent()
  }

  const handleRefuse = () => {
    dispatch(logout())
  }

  return (
    <Dialog open={open} aria-labelledby="simple-dialog-title" maxWidth="sm">
      <DialogTitle title="consent" format />
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ overflowY: 'scroll', height: 400 }}>
            <Text text="dataProtectionConsent" format />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          text="consent-refuse"
          onClick={handleRefuse}
          format
          color="primary"
        />
        <Button
          text="consent-validation"
          startIcon={<Check />}
          onClick={handleAccept}
          format
          color="secondary"
        />
      </DialogActions>
    </Dialog>
  )
}
