import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DoctorDto } from '../../services/api'
import { getDoctorByRpps } from '../thunks/doctorThunk'

interface DoctorState {
  doctorByRpps: DoctorDto | null
}

const initialState: DoctorState = {
  doctorByRpps: null,
}

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setDoctorByRpps: (
      state,
      action: { payload: DoctorDto | null; type: string },
    ) => {
      state.doctorByRpps = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDoctorByRpps.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.doctorByRpps = payload
      },
    )
  },
})

export const { setDoctorByRpps } = doctorSlice.actions

export default doctorSlice.reducer
