import { createAsyncThunk } from '@reduxjs/toolkit'
import { PatientService, PortalUserPreferencesDto } from '../../services/api'
import { updateColumnOrder, updateColumnWidths } from '../reducers/authReducer'
import { PatientService as PatientServiceV2 } from '../../services/patient.service'
import { enqueueSnackbar } from '../reducers/alertReducer'

export const resetPatientColumns = createAsyncThunk(
  'auth/reset-portal-columns',
  async (userId: number, { rejectWithValue, dispatch }) => {
    try {
      const dto: PortalUserPreferencesDto =
        await PatientService.resetPatientColumns({ id: userId })
      dispatch(updateColumnOrder(dto.preferencesDisplayHomeColumnorder))
      dispatch(updateColumnWidths(dto.preferencesDisplayHomeColumnwidth))
      window.location.reload()
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findPatients = createAsyncThunk(
  'patient/find-patients',
  async (search: string, { rejectWithValue }) => {
    try {
      const response = await PatientServiceV2.findAll(search)
      return response.datas
    } catch (e: any) {
      return rejectWithValue(e.response?.data?.message)
    }
  },
)

export const getPatientDocument = createAsyncThunk(
  'study/getDocuments',
  async ({ id }: any, { rejectWithValue, dispatch }) => {
    try {
      console.log('ID: ' + id)
      const response = await PatientServiceV2.getDocuments(id)

      return response
    } catch (e: any) {
      console.log({ e })
      dispatch(
        enqueueSnackbar({ type: 'error', message: e?.response?.data?.message }),
      )
      rejectWithValue(e.response.data)
      throw e
    }
  },
)
