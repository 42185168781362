import { TooltipButton } from '@components/buttons'
import {
  CircularProgress,
  createTheme,
  Portal,
  Snackbar,
  ThemeProvider,
} from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { AccountDto, MedicalOrderDto } from '@services/api'
import { useSelector } from '@state/store'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

type DownloadTooltipProps = {
  study: MedicalOrderDto
  account?: AccountDto
}

const customTheme = createTheme({
  overrides: {
    MuiPaper: {
      elevation6: {
        boxShadow: 'none',
      },
    },
  },
})

export function DownloadTooltip({ study }: DownloadTooltipProps) {
  const downloadingStudiesIds = useSelector(
    ({ study }) => study.downloadingStudiesIds,
  )

  const isDownloading = useMemo(
    () => downloadingStudiesIds.includes(study.id),
    [downloadingStudiesIds.length],
  )

  const handleImageDownload = () => {
    // window.open(
    //   `${process.env.REACT_APP_API_URL}/images/download/${study.studyInstanceUID}`,
    //   '_blank',
    // // )
    //url is the URL to the zip file
    const link = document.createElement('a')
    const linkText = document.createTextNode('&nbsp;')
    link.appendChild(linkText)
    link.href = `${process.env.REACT_APP_API_URL}/images/download/${study.radiologyStudy?.uid}`
    link.setAttribute('download', 'test.zip') // Set the filename here without the extension

    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const portalRoot = document.getElementById('portal-root')

  return (
    <>
      <TooltipButton
        title="download"
        id="download-image"
        onClick={handleImageDownload}
        disable={!study.radiologyStudy}
        color={study.radiologyStudy ? 'primary' : undefined}
        icon={isDownloading ? <CircularProgress size={20} /> : <GetApp />}
      />
      <Portal container={portalRoot}>
        <ThemeProvider theme={customTheme}>
          <div
            style={{
              position: 'fixed',
              top: '100%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
            }}
          >
            <Snackbar
              open={isDownloading}
              message={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CircularProgress
                    size={20}
                    style={{ marginRight: '8px', color: 'white' }}
                  />
                  <span style={{ fontWeight: 600, fontSize: '12px' }}>
                    <FormattedMessage id="downloading" />
                  </span>
                </div>
              }
            />
          </div>
        </ThemeProvider>
      </Portal>
    </>
  )
}
