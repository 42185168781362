import { Dialog, DialogContent, Grid } from '@mui/material'
import { useEffect } from 'react'
import { MedicalOrderDto } from '../../services/api'
import { useDispatch } from 'react-redux'
import { cleanStudy } from '../../state/reducers/studyReducer'
import { useSelector } from '../../state/store'
import { DialogTitle } from '../titles'
import theme from '@utils/theme'
import ReportingLayout from '../reporting/ReportingLayout'

type ManageReportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}

export const ManageReportDialog = ({
  open,
  setOpen,
}: ManageReportDialogProps) => {
  const dispatch = useDispatch()

  const { study } = useSelector(({ study }) => ({
    study: study.selected as MedicalOrderDto,
  }))

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      return
    }
    return () => {
      dispatch(cleanStudy())
    }
  }, [open])

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: '100%',
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={`${study?.exam.label} - ${
          study.plannedDate
            ? study.plannedDate
              .toString()
              .replace(
                /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
                '$3/$2/$1',
              )
            : ''
        }`}
        style={{
          background: '#E2E6F240',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent
        id="editor-container"
        style={{ backgroundColor: '#E2E6F240' }}
      >
        <Grid container spacing={2}>
          <ReportingLayout study={study} />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ManageReportDialog
