import React, { useEffect, useMemo, useState } from 'react'
import MedicalOrderTable from '../MedicalOrderTable'
import { useFetchMedicalOrders } from '../../../../hooks/useFetchMedicalOrders'
import { telemedTableColumns } from '../columns'
import { useIntl } from 'react-intl'
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro'
import {
  MedicalOrderDto,
  MedicalOrderStatusEnum,
  TelemedQueryStatusEnum,
} from '../../../../services/api'
import { makeStyles } from '@mui/styles'
import {
  setSearchfilter,
  setSelected,
} from '../../../../state/reducers/studyReducer'
import {
  StudyTooltip,
  FileUploadTooltip,
  ReportTooltipMemo,
  ImageTooltip,
  DownloadTooltip,
} from '../../../tooltips'
import { useSelector } from '../../../../state/store'
import { useDispatch } from 'react-redux'
import {
  ShareStudyDialog,
  FileUploadDialog,
  StudyDialog,
} from '../../../dialogs'
import { Icon, IconButton, Tab, Tabs, Tooltip } from '@mui/material'
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan'
import AssignmentIcon from '@mui/icons-material/Assignment'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswerRounded'
import ManageAquisitionProtocolDialog from '../../../dialogs/ManageAquisitionProtocolDialog'
import ManageReportDialog from '../../../dialogs/ManageReportDialog'
import { DoseDialog } from '../../../telemed/DoseDialog'
import CreateMedicalOrderDialog from '../../../dialogs/CreateMedicalOrderDialog'
import { useAppSelector } from '../../../../hooks/reduxHooks'
import VeccinesIcon from '@mui/icons-material/Vaccines'
import { findAll } from '../../../../state/thunks/studyThunk'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  iconBtn: {
    padding: 0,
  },
  root: {
    '& .MuiDataGrid-row': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#e8eeff',
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#fff',
      },
    },
    boxShadow: '0px 1px 2px  rgba(0,0,0,0.3)',
  },
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TelemedTableProps {}

const TelemedTable: React.FC<TelemedTableProps> = () => {
  const intl = useIntl()
  const classes = useStyles()

  const {
    account,
    viewerToken,
    study: selectedMedicalOrder,
    userConfiguration,
  } = useSelector(({ study, loading, auth }) => ({
    account: auth?.account,
    study: study.selected,
    loading: loading['study/findAll'],
    viewerToken: study.viewer.token,
    userConfiguration: auth.userConfiguration,
  }))

  const dispatch = useDispatch()

  const [studyOpen, setStudyOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [manageAquisitionProtocolOpen, setManageAquisitionProtocolOpen] =
    useState(false)
  const [, setSelectedRow] = useState<string | undefined>(undefined)
  const [reportOpen, setReportOpen] = useState(false)
  const [doseOpen, setDoseOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(TelemedQueryStatusEnum.TODO)

  const isCreateStudyOpen = useAppSelector(
    (state) => state.study.isCreateStudyOpen,
  )
  const searchFilter = useAppSelector((state) => state.study.searchFilter)

  const { onSortingChange, studies } = useFetchMedicalOrders(
    searchFilter.isTelemedicine,
  )

  useEffect(() => {
    dispatch(
      setSearchfilter({
        ...searchFilter,
        isTelemedicine: true,
        filterStatus: MedicalOrderStatusEnum.scheduled,
        telemedStatus: TelemedQueryStatusEnum.TODO,
      }),
    )
  }, [])

  const hasConfirmedDose = (study: MedicalOrderDto) => {
    return study.exam.nmDose && study.confirmedNmDose
  }

  const hasAcquisitionProtocolDeclared = (study: MedicalOrderDto) => {
    return (
      study.acquisitionProtocols?.length !== undefined &&
      study.acquisitionProtocols?.length > 0
    )
  }

  const renderActions = (
    el: GridRenderCellParams<MedicalOrderDto, any, any, GridTreeNodeWithRender>,
  ): JSX.Element => {
    return (
      <div className={classes.div}>
        <StudyTooltip
          onClick={() => {
            openStudyDialog(el.row)
            setSelectedRow(el.row.id.toString())
          }}
          study={el.row}
        />
        <FileUploadTooltip
          onClick={() => {
            openFileUploadDialog(el.row)
            setSelectedRow(el.row.id.toString())
          }}
          study={el.row}
        />
        <Tooltip title="Créer / Editer le compte-rendu">
          <span>
            <IconButton
              onClick={() => {
                setReportOpen(true)
                dispatch(setSelected(el.row))
                setSelectedRow(el.row.id.toString())
              }}
              style={{
                padding: 0,
                color: '#3f3f3f',
              }}
            >
              <AssignmentIcon fontSize="medium" />
            </IconButton>
          </span>
        </Tooltip>
        <div onClick={() => setSelectedRow(el.row.id.toString())}>
          <ReportTooltipMemo study={el.row} />
        </div>
        <ImageTooltip
          viewerToken={viewerToken}
          study={el.row}
          account={account}
          setSelectedRow={setSelectedRow}
        />

        <DownloadTooltip study={el.row} account={account} />
        {/* <ShareTooltip
          onClick={() => {
            onpenShareStudyDialog(el.row)
            setSelectedRow(el.row.id.toString())
          }}
          study={el.row}
          account={account}
        /> */}
        <Tooltip
          title={el.row.exam.nmDose ? 'Activité' : 'Examen sans activité'}
        >
          <span>
            <IconButton
              style={{
                padding: 0,
                color: !el.row.exam.nmDose ? '#ccc' : '#3f3f3f',
              }}
              onClick={() => {
                setDoseOpen(true)
                dispatch(setSelected(el.row))
              }}
              disabled={!el.row.exam.nmDose}
            >
              <VeccinesIcon fontSize="medium" />
            </IconButton>
            <Icon
              className={
                el.row.exam.nmDose && el.row.confirmedNmDose
                  ? 'fas fa-check'
                  : el.row.exam.nmDose && !el.row.confirmedNmDose
                    ? 'fas fa-times'
                    : ''
              }
              style={{
                color: hasConfirmedDose(el.row) ? '#2dd88e' : '#E91E63',
                fontSize: '1rem',
                transform: 'translate(-5px, 0px)',
              }}
            />
          </span>
        </Tooltip>
        <Tooltip
          title={
            el.row.exam.hasAcquisitionProtocol
              ? 'Protocole'
              : 'Examen sans protocole'
          }
        >
          <span>
            <IconButton
              onClick={() => {
                setManageAquisitionProtocolOpen(true)
                dispatch(setSelected(el.row))
                setSelectedRow(el.row.id.toString())
              }}
              style={{
                padding: 0,
                color: !el.row.exam.hasAcquisitionProtocol ? '#ccc' : '#3f3f3f',
              }}
              disabled={!el.row.exam.hasAcquisitionProtocol}
            >
              <SettingsOverscanIcon fontSize="medium" />
            </IconButton>
            <Icon
              className={
                el.row.exam.hasAcquisitionProtocol &&
                hasAcquisitionProtocolDeclared(el.row)
                  ? 'fas fa-check'
                  : el.row.exam.hasAcquisitionProtocol &&
                    !hasAcquisitionProtocolDeclared(el.row)
                    ? 'fas fa-times'
                    : ''
              }
              style={{
                color: hasAcquisitionProtocolDeclared(el.row)
                  ? '#2dd88e'
                  : '#E91E63',
                fontSize: '1rem',
                transform: 'translate(-5px, 0px)',
              }}
            />
          </span>
        </Tooltip>
        <Tooltip title="Chat">
          <span>
            <IconButton
              style={{
                padding: 0,
                color: '#3f3f3f',
              }}
            >
              <QuestionAnswerIcon fontSize="medium" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const openStudyDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setStudyOpen(true)
  }

  const openFileUploadDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setFileUploadOpen(true)
  }

  const columns = useMemo(() => {
    const order = userConfiguration.tableState?.columns?.orderedFields
    const widths: { [key: string]: number } = {}

    if (userConfiguration.tableState?.columns?.dimensions) {
      Object.entries(userConfiguration.tableState.columns.dimensions).map(
        ([f, w]) => {
          widths[f] = w.width || 150
        },
      )
    }
    return telemedTableColumns(intl, renderActions, order, widths)
  }, [viewerToken, userConfiguration.tableState])

  const onFilterChange = (status: TelemedQueryStatusEnum) => {
    dispatch(
      setSearchfilter({
        ...searchFilter,
        todayOnly: false,
        filterStatus: MedicalOrderStatusEnum.scheduled,
        telemedStatus: status,
      }),
    )
  }

  useEffect(() => {
    if (!doseOpen || !manageAquisitionProtocolOpen || !reportOpen) {
      const limit = studies.size
      const page = studies.page

      dispatch(
        findAll({
          limit,
          page,
          sortBy: {
            columnName: 'date',
            direction: 'desc',
          },
        }),
      )
    }
  }, [doseOpen, manageAquisitionProtocolOpen, reportOpen])

  return (
    <>
      <MedicalOrderTable
        density="standard"
        columns={columns}
        data={studies.datas}
        pagination={{
          page: studies.page,
          size: studies.size,
          totalCount: studies.totalCount,
        }}
        onSortingChange={onSortingChange}
        tabs={
          <Tabs
            value={activeTab}
            onChange={(_e, value) => {
              onFilterChange(value)
              setActiveTab(value)
            }}
            sx={{
              display: 'inline-flex',
              backgroundColor: '#efefef',
              borderRadius: '8px 8px 0px 0px',
              '& .Mui-selected': {
                color: '#3f3f3f !important',

                fontStyle: 'normal',
                fontSize: '0.9rem',
                backgroundColor: '#fff',
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#3f3f3f',
                height: '3px',
              },
            }}
          >
            <Tab
              value={TelemedQueryStatusEnum.PendingDose}
              label={intl.formatMessage({ id: 'doseTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.PendingProtocol}
              label={intl.formatMessage({ id: 'protocolTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.TODO}
              label={intl.formatMessage({ id: 'todoTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.DONE}
              label={intl.formatMessage({ id: 'doneTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.UPCOMING}
              label={intl.formatMessage({ id: 'upcomingTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
          </Tabs>
        }
        isTodayOnlyHidden
        hideDatePicker
      />
      {selectedMedicalOrder && (
        <>
          <ShareStudyDialog
            open={shareOpen}
            setOpen={setShareOpen}
            study={selectedMedicalOrder}
          />
          <FileUploadDialog
            open={fileUploadOpen}
            setOpen={setFileUploadOpen}
            study={selectedMedicalOrder}
          />
          <StudyDialog
            open={studyOpen}
            setOpen={setStudyOpen}
            study={selectedMedicalOrder}
          />
          <ManageAquisitionProtocolDialog
            open={manageAquisitionProtocolOpen}
            setOpen={setManageAquisitionProtocolOpen}
            study={selectedMedicalOrder}
          />
          <ManageReportDialog
            open={reportOpen}
            setOpen={setReportOpen}
            study={selectedMedicalOrder}
          />
          {doseOpen && (
            <DoseDialog
              open={doseOpen}
              study={selectedMedicalOrder}
              onClose={() => {
                setDoseOpen(false)
              }}
            />
          )}
        </>
      )}
      <CreateMedicalOrderDialog open={isCreateStudyOpen} />
    </>
  )
}

export default TelemedTable
