import { axios, getConfigs, IRequestConfig } from './api'

export class JoyService {
  private readonly basePath = process.env.REACT_APP_JOY_URL

  uploadFile(file: any, payload?: { [key: string]: string }) {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/smb/'

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        {},
      )

      const data = new FormData()

      if (Object.prototype.toString.call(file === '[object Array]')) {
        for (const item of file) {
          data.append('file', item as any)
        }
      } else {
        data.append('file', file as any)
      }
      if (payload) {
        Object.entries(payload).forEach(([key, value]) => {
          data.append(key, value)
        })
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
