import Footer from '@components/Footer'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from '@state/store'
import { motion } from 'framer-motion/dist/framer-motion'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/img/logos/logo-ether.png'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },

  outlet: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
})

export default function AuthLayout() {
  const classes = useStyles()
  const auth = useSelector(({ auth }) => auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (auth.isAuth) {
      if (auth.account?.email && auth.account?.isEmailVerified) {
        navigate('/')
      }
    }
  }, [auth])

  return (
    <>
      <main className={classes.container}>
        <div
          style={{
            height: '100vh',
            background: '#E2E6F2',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'hidden',
          }}
        >
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                scale: [0, 1],
              }}
              transition={{
                duration: 1.75,
              }}
              style={{
                position: 'absolute',
                top: -300,
                left: -280,
                width: '1100px',
                height: '1100px',
                borderRadius: '50%',
                background: '#d0c7ed',
              }}
            />
            <motion.div
              initial={{
                opacity: 0,
                x: 1500,
              }}
              animate={{
                opacity: 1,
                scale: [0, 1],
                x: 0,
              }}
              transition={{
                duration: 1.75,
              }}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '600px',
                height: '600px',
                borderRadius: '50%',
                background: '#d0c7ed',
              }}
            />
            <motion.div
              initial={{
                opacity: 0,
                x: -500,
              }}
              animate={{
                opacity: 1,
                scale: [0, 1],
                x: 0,
              }}
              transition={{
                duration: 1.75,
              }}
              style={{
                position: 'absolute',
                top: 350,
                left: 170,
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: '350px',
                  display: 'block',
                }}
              ></img>
            </motion.div>
            <motion.div
              initial={{
                opacity: 0,
                x: -700,
                y: 700,
              }}
              animate={{
                opacity: 1,
                scale: [0, 1],
                x: 0,
                y: 0,
              }}
              transition={{
                duration: 1.75,
              }}
              style={{
                position: 'absolute',
                top: 550,
                right: 450,
                width: '200px',
                height: '200px',
                borderRadius: '50%',
                background: '#d0c7ed',
              }}
            />
            <motion.div
              initial={{
                opacity: 0,
                y: -500,
              }}
              animate={{
                opacity: 1,
                scale: [0, 1],
                y: 0,
              }}
              transition={{
                duration: 1.75,
              }}
              style={{
                position: 'absolute',
                top: 700,
                right: 130,
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                background: '#d0c7ed',
              }}
            />
          </div>
        </div>
        <div className={classes.outlet}>
          <Outlet />
        </div>
        <Footer />
      </main>
    </>
  )
}
