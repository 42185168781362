import React, { useEffect, useRef, useState } from 'react'
import { useWebsocket } from '../../hooks/useWebsocket'
import {
  CreateMessageDto,
  EntityName,
  GedFileDto,
  MessageNamesEnum,
} from '../../services/api'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  Message,
  Avatar,
} from '@chatscope/chat-ui-kit-react'
import { useSelector } from '../../state/store'
import { addMessage } from '../../state/reducers/messageReducer'
import { findMessages } from '../../state/thunks/messageThunk'
import { Box, Button, Dialog, DialogActions, Grid, Modal } from '@mui/material'
import { uploadFiles } from '../../state/thunks/studyThunk'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { Constants } from '../../utils/Constants'
import StorageService from '../../services/storage'

interface Props {
  medicalOrderId: number
}
const ReportMessages: React.FC<Props> = ({ medicalOrderId }) => {
  const messages = useSelector((state) => state.messages.messages)
  const currentUser = useSelector((state) => state.auth.user)

  const dispatch = useAppDispatch()

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [, setMsgInputValue] = useState('')
  const [fileToPreview, setFileToPreview] = useState<GedFileDto | null>(null)

  const onMessage = <MessageDto,>(message: MessageDto) => {
    dispatch(addMessage(message))
  }

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      setSelectedFiles(Array.from(files))
    }
  }

  const { sendMessage } = useWebsocket({
    handlers: [
      {
        messageName: MessageNamesEnum.ReceiveMessage,
        fn: onMessage,
      },
    ],
  })

  const handleSend = (message: any) => {
    setMsgInputValue('')

    const dto: CreateMessageDto = {
      entityId: medicalOrderId,
      entityName: EntityName.medicalOrder,
      message: message.trim(),
    }
    sendMessage(MessageNamesEnum.ReceiveMessage, dto)
  }

  useEffect(() => {
    sendMessage(MessageNamesEnum.JoinChat, {
      entityId: medicalOrderId,
    })
    dispatch(
      findMessages({
        entityId: medicalOrderId,
        entityName: EntityName.medicalOrder,
      }),
    )
  }, [])

  const handleAttachClick = () => {
    if (!fileInputRef.current) {
      return
    }

    fileInputRef.current.click()
  }

  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const clearFiles = () => {
    setSelectedFiles([])
    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  const sendFiles = () => {
    dispatch(
      uploadFiles({
        id: medicalOrderId,
        files: selectedFiles,
        type: 'CHAT_FILE',
      }),
    )
      .unwrap()
      .then((res) => {
        clearFiles()
        if (fileInputRef.current) fileInputRef.current.value = ''
        const message: CreateMessageDto = {
          entityId: medicalOrderId,
          entityName: EntityName.medicalOrder,
          message: '',
          gedFileIds: res.map((file) => file.id),
        }
        sendMessage(MessageNamesEnum.ReceiveMessage, message)
      })
  }

  const token = useRef(StorageService.getAuthToken()).current
  console.log({ fileToPreview })

  const renderFile = (file: GedFileDto) => {
    if (file.mimeType.includes('image')) {
      return (
        <Message.ImageContent
          key={file.id}
          src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${file.filePath}/${file.filename}?token=${token}`}
          width={100}
        />
      )
    } else if (file.mimeType.includes('video')) {
      return (
        <Grid item xs={6} md={4} lg={3}>
          <Message.CustomContent>
            <video
              src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${file.filePath}/${file.filename}?token=${token}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              controls
            />
          </Message.CustomContent>
        </Grid>
      )
    } else {
      return (
        <Grid item xs={6} md={4} lg={3}>
          <a
            href={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${file.filePath}/${file.filename}?token=${token}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="contained"
              style={{
                width: '100%',
                height: '100%',
                textTransform: 'none',
              }}
            >
              {file.filename}
            </Button>
          </a>
        </Grid>
      )
    }
  }

  return (
    <MainContainer
      style={{
        width: '100%',
        maxHeight: '80vh',
      }}
    >
      <ChatContainer>
        <MessageList>
          {messages.datas.map((message) => (
            <Message
              key={message.id}
              model={{
                message: message.message,
                position: 'normal',
                direction:
                  currentUser?.accountId === message.sender.id
                    ? 'outgoing'
                    : 'incoming',
                type: 'text',
              }}
            >
              <Avatar src={Constants.avatarPlaceHolder} />
              {message.files.length && (
                <Message.CustomContent>
                  <Grid container>
                    {message.files.map((file) => (
                      <Grid
                        xs={6}
                        key={file.id}
                        item
                        onClick={() => {
                          if (
                            file.mimeType.includes('image') ||
                            file.mimeType.includes('video')
                          ) {
                            setFileToPreview(file)
                          }
                        }}
                      >
                        {renderFile(file)}
                      </Grid>
                    ))}
                  </Grid>
                </Message.CustomContent>
              )}
            </Message>
          ))}
        </MessageList>
        <MessageInput
          onAttachClick={handleAttachClick}
          placeholder="Type message here"
          onSend={handleSend}
        />
      </ChatContainer>
      <input
        ref={fileInputRef}
        id="hiddenFileInput"
        type="file"
        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileSelection}
      />
      <Dialog
        open={selectedFiles.length !== 0}
        onClose={clearFiles}
        onAbort={clearFiles}
        onAbortCapture={clearFiles}
        style={{
          minHeight: '80vh',
        }}
      >
        <Box sx={{ p: 2, minHeight: '100%', height: '100%' }}>
          <Grid container>
            {selectedFiles.map((file) => (
              <Grid item xs={12} md={6} key={file.name}>
                {file.type.includes('image') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      overflow: 'hidden',
                    }}
                  />
                ) : (
                  <iframe
                    src={URL.createObjectURL(file)}
                    title={file.name}
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={sendFiles}>Confirm</Button>
          <Button onClick={clearFiles}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={!!fileToPreview}
        onClose={() => setFileToPreview(null)}
        onAbort={() => setFileToPreview(null)}
        onAbortCapture={() => setFileToPreview(null)}
        style={{ backgroundColor: 'white' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
            width: '80%',
          }}
        >
          {fileToPreview && (
            <Box sx={{ p: 2, minHeight: '50vh' }}>
              {fileToPreview?.mimeType.includes('image') ? (
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${fileToPreview.filePath}/${fileToPreview.filename}?token=${token}`}
                  alt={fileToPreview.filename}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    overflow: 'hidden',
                  }}
                />
              ) : (
                <video
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${fileToPreview.filePath}/${fileToPreview.filename}?token=${token}`}
                  title={fileToPreview.filename}
                  style={{ width: '100%', height: '100%' }}
                  controls
                />
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </MainContainer>
  )
}

export default ReportMessages
