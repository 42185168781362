import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ReportParagraphDto, ReportTemplateDto } from '../../services/api'
import {
  createParagraph,
  createReportTemplate,
  deleteParagraph,
  deleteReportTemplate,
  findAllReportTemplates,
  findParagraphs,
  updateParagraph,
  updateReportTemplate,
} from '../thunks/reportingThunk'

interface ReportingState {
  templates: ReportTemplateDto[]
  paragraphs: ReportParagraphDto[]
}

const initialState: ReportingState = {
  templates: [],
  paragraphs: [],
}

export const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createReportTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload)
    })
    builder.addCase(findAllReportTemplates.fulfilled, (state, action) => {
      state.templates = action.payload
    })
    builder.addCase(updateReportTemplate.fulfilled, (state, action) => {
      const index = state.templates.findIndex(
        (template) => template.id === action.payload.id,
      )
      state.templates[index] = action.payload
    })
    builder.addCase(deleteReportTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload.id,
      )
    })
    builder.addCase(
      createParagraph.fulfilled,
      (state, action: PayloadAction<ReportParagraphDto>) => {
        state.paragraphs.push(action.payload)
      },
    )
    builder.addCase(
      updateParagraph.fulfilled,
      (state, action: PayloadAction<ReportParagraphDto>) => {
        state.paragraphs = state.paragraphs.map((paragraph) => {
          if (paragraph.id === action.payload.id) {
            return action.payload
          }
          return paragraph
        })
      },
    )
    builder.addCase(
      deleteParagraph.fulfilled,
      (state, action: PayloadAction<ReportParagraphDto>) => {
        state.paragraphs = state.paragraphs.filter(
          (paragraph) => paragraph.id !== action.payload.id,
        )
      },
    )
    builder.addCase(findParagraphs.fulfilled, (state, action) => {
      state.paragraphs = action.payload
    })
  },
})

export default reportingSlice.reducer
