import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { findAll, getToken } from '../state/thunks/studyThunk'
import { useSelector } from '../state/store'
import { Sorting } from '../services/api'

export const useFetchMedicalOrders = (enabled?: boolean) => {
  const [sorting, setSorting] = useState<Sorting[]>([
    {
      columnName: 'date',
      direction: 'desc',
    },
  ])

  useEffect(() => {
    dispatch(getToken())
  }, [])

  const onSortingChange = (sortings: Sorting[]) => {
    setSorting(sortings)
  }

  const dispatch = useDispatch()

  const studies = useSelector(({ study }) => study.studies)

  const searchFilter = useSelector(({ study }) => study.searchFilter)

  useEffect(() => {
    if (!enabled) {
      return
    }
    const limit = studies.size
    const page = studies.page

    dispatch(
      findAll({
        limit,
        page,
        sortBy: sorting.length !== 0 ? sorting[0] : undefined,
      }),
    )
  }, [
    studies.page,
    studies.size,
    length,
    searchFilter.filterStatus,
    searchFilter.selectedDate,
    sorting,
    searchFilter.todayOnly,
    searchFilter.isTelemedicine,
    searchFilter.telemedStatus,
    enabled,
  ])

  return {
    onSortingChange,
    studies,
  }
}
