import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

import { store } from '@state/store'
import { serviceOptions } from './api'
import StorageService from './storage'
import { logout } from '@state/thunks/authThunk'

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

instance.interceptors.request.use(onRequestIntercepted, onRequestRejected)
instance.interceptors.response.use(onResponseIntercepted, onResponseRejected)

function onRequestIntercepted(config: AxiosRequestConfig) {
  const token = StorageService.getAuthToken()
  if (token) config.headers['Authorization'] = 'Bearer ' + token
  return config
}

function onResponseIntercepted(res: AxiosResponse) {
  if (res.headers['content-disposition']) {
    const filename = res.headers['content-disposition'].split('filename=')[1]
    res.data = { url: URL.createObjectURL(res.data), filename, data: res.data }
  }
  return res
}

function onRequestRejected(error: any) {
  return Promise.reject(error)
}

function onResponseRejected(error: any) {
  const axiosError: AxiosError = error
  const { dispatch } = store

  if (axiosError.isAxiosError) {
    if (axiosError.message === 'NETWORK_ERROR') {
      dispatch({
        type: 'INTERNET_ERROR',
        message: 'error.network',
      })
      return Promise.reject(error)
    }

    if (axiosError.response && axiosError.response.status === 500) {
      dispatch({
        type: 'INTERNAL_ERROR',
        message: 'error.internal',
      })
      return Promise.reject(error)
    }

    if (axiosError.response && axiosError.response.status === 401) {
      const token = StorageService.getAuthToken()
      if (token) {
        dispatch(logout())
      }

      return Promise.reject(error)
    }
  }

  return Promise.reject(error)
}
serviceOptions.axios = instance
