import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Exam, ExamAquisitionProtocolDto } from '../../services/api'
import { getExamAquisitionProtocol, getExamsBySiteId } from '../thunks/examThuk'

export interface ExamState {
  exams: Exam[]
  aquisitionProtocols: ExamAquisitionProtocolDto[]
}

const initialState: ExamState = {
  exams: [],
  aquisitionProtocols: [],
}

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setAquisitionProtocol: (
      state,
      { payload }: PayloadAction<ExamAquisitionProtocolDto[]>,
    ) => {
      state.aquisitionProtocols = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getExamAquisitionProtocol.fulfilled,
      (state, { payload }: PayloadAction<ExamAquisitionProtocolDto[]>) => {
        state.aquisitionProtocols = payload
      },
    )
    builder.addCase(
      getExamsBySiteId.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ totalCount: Number; datas: Exam[] }>,
      ) => {
        state.exams = payload.datas
      },
    )
  },
})

export default examSlice.reducer
