import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import ReportingDataPanel from './ReportingDataPanel'
import ReportingEditor, { ReportingEditorRef } from './ReportingEditor'
import { MedicalOrderDto, ReportTemplateDto } from '../../services/api'
import { useSelector } from '../../state/store'
import { useDispatch } from 'react-redux'
import { findAllReportTemplates } from '../../state/thunks/reportingThunk'
import ReportTemplatePreview from './ReportTemplatePreview'
import { getPatientDocument } from '../../state/thunks/patientThunk'
import DocumentList from '../telemed/DocumentList'

interface ReportingLayoutProps {
  study: MedicalOrderDto
}
const ReportingLayout: React.FC<ReportingLayoutProps> = ({ study }) => {
  const dispatch = useDispatch()
  const templates = useSelector((state) => state.reporting.templates)
  const documents = useSelector((state) => state.patients.patientDocuments)

  useEffect(() => {
    dispatch(
      findAllReportTemplates({
        examIds: [study.examId],
      }),
    )
  }, [])

  const [templateSearchValue] = useState('')
  const [LeftPanelActiveTab, setLeftPanelActiveTab] = useState(0)

  const useTemplate = (template: ReportTemplateDto) => {
    if (editorRef.current) {
      editorRef.current.useTemplate(template)
    }
  }
  const onUseTemplate = (template: ReportTemplateDto) => {
    const isConfirmed = confirm('Voulez-vous utiliser ce modèle ?')
    if (isConfirmed) {
      useTemplate(template)
    }
  }

  const editorRef = useRef<ReportingEditorRef | null>(null)

  useEffect(() => {
    if (study.reports.length !== 0) {
      const indication = study.reports[0].indication
      const technique = study.reports[0].technique
      const resultat = study.reports[0].resultat
      const conclusion = study.reports[0].conclusion
      const examDetails = study.reports[0].examDetails

      useTemplate({
        indication,
        technique,
        resultat,
        conclusion,
        examDetails,
        body: '',
      } as ReportTemplateDto)
    }
    if (study) {
      console.log('Patinet ID ' + study.patientId)
      dispatch(getPatientDocument({ id: study.patientId }))
    }
  }, [study])

  const templateItems = templateSearchValue
    ? templates.filter((t) =>
      t.name.toLowerCase().includes(templateSearchValue.toLowerCase()),
    )
    : templates

  return (
    <Grid container>
      <Grid item sm={12} md={3} id="report-left">
        <Box
          sx={{
            padding: '10px',
            position: 'fixed',
            width: '23%',
            left: 0,
            top: 0,
            paddingTop: '40px',
            borderRight: '1px solid #CCC',
            backgroundColor: '#E2E6F2',
            boxShadow: '0 0 3px 0 rgba(0,0,0,0.4)',
          }}
        >
          <Tabs
            style={{
              marginTop: 10,
            }}
            value={LeftPanelActiveTab}
            onChange={(_, value) => setLeftPanelActiveTab(value)}
            centered
          >
            <Tab value={0} label="Variables" />
            <Tab value={1} label="Modeles" />
          </Tabs>
          <Box>
            {LeftPanelActiveTab === 0 && (
              <div>
                <div
                  style={{
                    height: 'calc(50vh - 50px)',
                    overflowY: 'scroll',
                  }}
                >
                  <ReportingDataPanel />
                </div>
                <h4 style={{ textAlign: 'center' }}>Documents</h4>
                <div
                  style={{
                    height: 'calc(50vh - 50px)',
                    overflowY: 'scroll',
                  }}
                >
                  {documents.length ? (
                    <DocumentList documents={documents} />
                  ) : (
                    <p
                      style={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                      }}
                    >
                      Aucun document
                    </p>
                  )}
                </div>
              </div>
            )}
            {LeftPanelActiveTab === 1 &&
              templateItems.map((template) => (
                <Box key={template.id} sx={{ width: '95%' }}>
                  <ReportTemplatePreview
                    study={study}
                    template={template}
                    onUseTemplateClick={onUseTemplate}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        sm={11}
        md={9}
        sx={{
          // backgroundColor: '#fff',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ReportingEditor study={study} ref={editorRef} hiddenActions={[]} />
      </Grid>
    </Grid>
  )
}

export default ReportingLayout
