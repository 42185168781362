import { TooltipButton } from '@components/buttons'
import { Icon } from '@material-ui/core'
import { MedicalOrderDto } from '@services/api'

type StudyTooltipProps = {
  study: MedicalOrderDto
  onClick: () => void
}

export function StudyTooltip({ study, onClick }: StudyTooltipProps) {
  return (
    <TooltipButton
      title="study"
      disable={study.status == 'canceled'}
      onClick={onClick}
      icon={
        <Icon
          fontSize="small"
          className="fas fa-info"
          color={study.status == 'canceled' ? 'disabled' : 'primary'}
        />
      }
    />
  )
}
