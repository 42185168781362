import React from 'react'
import { Typography } from '@mui/material'
import { reportingVariables } from '../../utils/reporting-utils'
import { useIntl } from 'react-intl'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import './ReportingDataPanel.css'

const ReportingDataPanel: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      {reportingVariables.map((variable) => (
        <div
          className="report-variable"
          key={variable}
          draggable
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '2px 0',
            textAlign: 'center',
            alignItems: 'center',
            border: '1px solid #CCC',
            borderRadius: 4,
            padding: '4px',
            backgroundColor: '#FFF',
            cursor: 'pointer',
          }}
          onDragStart={(ev) => {
            ev.dataTransfer.setData('text/html', `{{ ${variable} }}`)
          }}
        >
          <DragIndicatorIcon
            style={{
              color: '#6A6A6A',
              fontSize: '14px',
            }}
          />
          <Typography
            key={variable}
            fontSize="12px"
            variant="h6"
            fontWeight="bold"
          >
            {intl.formatMessage({ id: 'reporting.' + variable })}
          </Typography>
        </div>
      ))}
    </>
  )
}

export default ReportingDataPanel
