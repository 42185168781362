import { Button } from '@components/buttons'
import { PasswordInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { AccountDto } from '@services/api'
import { updatePassword } from '@state/thunks/authThunk'
import { resetPasswordSchema } from '@utils/schemas'
import theme from '@utils/theme'
import { PasswordStrengthMeter } from 'src/containers/auth/PasswordStrengthMeter'
import { Text } from '../texts'
import { useState } from 'react'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type Values = {
  newPassword: string
  confirmNewPassword: string
}
type UpdatePasswordFormProps = {
  account?: AccountDto
}

export function UpdatePasswordForm({ account }: UpdatePasswordFormProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isUpdating, setIsUpdating] = useState(false)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Values>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  })
  const watchPassword = watch('newPassword', '')

  const onSubmit = (values: Values) => {
    if (account) {
      dispatch(updatePassword({ body: values, id: account.id }))
      setIsUpdating(false)
    }
  }

  const handleCancel = () => {
    setIsUpdating(false)
    reset()
  }

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon
                className="fa fa-lock"
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item>
              <Text
                text="password"
                variant={'h6'}
                format
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={1}
          style={{
            opacity: isUpdating ? 1 : 0.3,
          }}
        >
          <Grid item xs={12} sm={6} style={{ marginBottom: -10 }}>
            <PasswordInput
              name="newPassword"
              placeholder="New Password"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              autoComplete="new-password"
              disabled={!isUpdating}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordStrengthMeter password={watchPassword} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PasswordInput
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              autoComplete="new-password"
              disabled={!isUpdating}
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" justifyContent="flex-end">
          {isUpdating && (
            <Grid item>
              <Button
                text="cancel"
                onClick={handleCancel}
                format
                color="primary"
                style={{ marginRight: 10 }}
              />
            </Grid>
          )}
          <Grid item>
            <Button
              text={isUpdating ? 'Update' : 'update'}
              onClick={
                isUpdating ? handleSubmit(onSubmit) : () => setIsUpdating(true)
              }
              format
              color="secondary"
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
