import { Dialog, DialogContent } from '@mui/material'
import { useEffect } from 'react'
import { MedicalOrderDto } from '../../services/api'
import { useDispatch } from 'react-redux'
import { cleanStudy } from '../../state/reducers/studyReducer'
import { useSelector } from '../../state/store'
import { DialogTitle } from '../titles'
import theme from '@utils/theme'
import ExamAquisitionProtocolManager from '../telemed/ExamAquisitionProtocolManager'
import { useIntl } from 'react-intl'

type ManageAquisitionProtocolDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}

const ManageAquisitionProtocolDialog = ({
  open,
  setOpen,
}: ManageAquisitionProtocolDialogProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const { study } = useSelector(({ study }) => ({
    study: study.selected as MedicalOrderDto,
  }))

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      return
    }
    return () => {
      dispatch(cleanStudy())
    }
  }, [open])

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="lg"
      PaperProps={{
        style: {
          borderRadius: 20,
          background: '#E2E6F2',
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={`${intl.formatMessage({ id: 'manage_aquisition_protocol' })} - ${
          study?.exam?.label
        } - ${study?.patient?.firstName[0]}.${study?.patient?.lastName}`}
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent style={{ padding: 30 }}>
        <ExamAquisitionProtocolManager medicalOrder={study} />
      </DialogContent>
    </Dialog>
  )
}

export default ManageAquisitionProtocolDialog
