import { createSlice } from '@reduxjs/toolkit'
import { MessageDto } from '../../services/api'
import { findMessages } from '../thunks/messageThunk'
import { PaginatedDto } from '../../services/extendedType'

type MessageState = {
  messages: PaginatedDto<MessageDto>
}

const initialState: MessageState = {
  messages: {
    datas: [],
    totalCount: 0,
  },
}

export const messageReducer = createSlice({
  name: 'message',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.datas.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findMessages.fulfilled, (state, action) => {
      state.messages = action.payload
    })
  },
})

export default messageReducer.reducer

export const { addMessage } = messageReducer.actions
