import { Document, IRequestConfig, PatientDto, axios, getConfigs } from './api'
import { PaginatedDto } from './extendedType'

export class PatientService {
  private static basePath = process.env.REACT_APP_API_URL

  static findAll(
    search?: string,
    limit?: number,
    page?: number,
  ): Promise<PaginatedDto<PatientDto>> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/patient/v2'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )
      configs.params = {
        search,
        limit,
        page,
      }

      axios(configs, resolve, reject)
    })
  }

  static getDocuments(id: number): Promise<Document[]> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/patient/' + id + '/documents'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }
}
