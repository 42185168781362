import { Input, debounce } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import { useDispatch } from 'react-redux'
import {
  setIsCreateReportDialogOpen,
  setSearchText,
  setSearchfilter,
  setStudyPagination,
} from '../../../state/reducers/studyReducer'
import { useSelector } from '../../../state/store'
import { useMemo, useState } from 'react'
import { findAll } from '../../../state/thunks/studyThunk'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import { useIntl } from 'react-intl'
import { IconButton, InputAdornment, Tooltip } from '@mui/material'
import theme from '@utils/theme'
import moment from 'moment'
import RefreshIcon from '@mui/icons-material/Refresh'
import { AccountRoleEnum } from '../../../services/api'
import { Link } from 'react-router-dom'

export function AppToolbar({
  isTodayOnlyHidden = false,
  hideDatePicker = false,
}) {
  const dispatch = useDispatch()
  const [filterStatus, setFilterStatus] = useState('')
  const [dateValue, setDateValue] = useState<Date | null>(null)
  const intl = useIntl()

  const studies = useSelector(({ study }) => study.studies)
  const sorting = useSelector(({ study }) => study.sorting)
  const page = useSelector(({ study }) => study.studies.page)
  const size = useSelector(({ study }) => study.studies.size)
  const searchFilter = useSelector(({ study }) => study.searchFilter)
  const role = useSelector(({ auth }) => auth.account?.role)

  const searchMedicalOrders = useMemo(() => {
    return debounce((value: string) => {
      dispatch(setSearchText(value))
      const limit = size

      dispatch(
        findAll({
          limit,
          page: 0,
        }),
      )
    }, 300)
  }, [])

  const _onSearchChange = (value: string) => {
    if (page !== 0) {
      dispatch(
        setStudyPagination({
          page: 0,
        }),
      )
    }
    setFilterStatus(value)
    searchMedicalOrders(value)
  }

  const onDateChange = (date: Date | null) => {
    setDateValue(date)
    dispatch(
      setSearchfilter({
        ...searchFilter,
        todayOnly: false,
        selectedDate: date || undefined,
      }),
    )
  }

  const handleRefresh = () => {
    const limit = studies.size
    const page = studies.page
    dispatch(
      findAll({
        limit,
        page,
        sortBy: sorting.length !== 0 ? sorting[0] : undefined,
      }),
    )
  }

  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        width: 'calc(100% - 15px)',
        justifyContent: 'space-between',
        padding: '5px 5px 5px 10px',
        borderBottom: '1px solid ',
      }}
    >
      <div>
        <GridToolbarColumnsButton
          sx={{
            backgroundColor: '#3f3f3f',
            color: '#fff',
            marginRight: '10px',
            '&:hover': {
              backgroundColor: '#3f3f3f70',
            },
          }}
        />
        <GridToolbarDensitySelector
          sx={{
            backgroundColor: '#3f3f3f',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3f3f3f70',
            },
          }}
        />
        <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        {/* <GridToolbarExport /> */}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isTodayOnlyHidden && (
          <FormControlLabel
            control={
              <Checkbox
                name="isTodayOnlyChecked"
                onChange={(_, checked) => {
                  let selectedDate: Date | undefined = undefined
                  if (checked) {
                    selectedDate = new Date()
                  }
                  setDateValue(selectedDate || null)
                  dispatch(
                    setSearchfilter({
                      ...searchFilter,
                      selectedDate,
                      todayOnly: checked,
                    }),
                  )
                }}
                checked={searchFilter.todayOnly}
                value={searchFilter.todayOnly}
              />
            }
            label={intl.formatMessage({ id: 'isTodayOnlyChecked' })}
          />
        )}

        {!hideDatePicker && (
          <>
            <DatePicker
              // label="Date"
              value={dateValue ? (moment(dateValue) as any as Date) : null}
              onChange={onDateChange}
              sx={{
                height: '40px',
              }}
              slotProps={{ textField: { size: 'small' } }}
            />
            {dateValue && (
              <IconButton
                aria-label="clear date"
                edge="end"
                onClick={(e) => {
                  e.preventDefault()
                  onDateChange(null)
                }}
              >
                <ClearIcon
                  sx={{
                    color: theme.palette.secondary.main,
                    fontSize: '1.5rem',
                    marginLeft: '5px',
                  }}
                />
              </IconButton>
            )}
          </>
        )}
        {role === AccountRoleEnum.telemed_doctor && (
          <>
            <Button
              variant="outlined"
              style={{
                borderColor: 'black',
                color: 'black',
                marginLeft: '16px',
              }}
              onClick={() => {
                dispatch(setIsCreateReportDialogOpen(true))
              }}
            >
              <i
                className="fa fa-plus"
                style={{
                  marginRight: '4px',
                }}
              ></i>
              Ajouter un examen
            </Button>
            <Link
              to="/report-templates"
              style={{
                textDecoration: 'none',
                marginLeft: 4,
              }}
            >
              <Tooltip title="Modèle(s) de compte-rendu">
                <IconButton size="medium" style={{ color: '#3f3f3f' }}>
                  <DocumentScannerIcon />
                </IconButton>
              </Tooltip>
            </Link>
          </>
        )}
      </div>
      {role === AccountRoleEnum.member || role === AccountRoleEnum.doctor ? (
        <Input
          value={filterStatus}
          onChange={(ev) => {
            _onSearchChange(ev.target.value)
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder={intl.formatMessage({
            id: 'searchPatient',
            defaultMessage: 'Search Patient',
          })}
          style={{
            height: '40px',
            width: '250px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '0 10px',
          }}
          disableUnderline
        />
      ) : (
        <div></div>
      )}
    </GridToolbarContainer>
  )
}
