import { createAsyncThunk } from '@reduxjs/toolkit'
import { MessageService } from '../../services/message.service'
import { EntityName } from '../../services/api'

export const findMessages = createAsyncThunk(
  'messages/findMessages',
  async (
    query: {
      entityId: number
      entityName: EntityName
      page?: number
      limit?: number
    },
    { rejectWithValue },
  ) => {
    try {
      return await MessageService.findMessages(
        query.entityId,
        query.entityName,
        query.page,
        query.limit,
      )
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)
