import { Box } from '@material-ui/core'

import { EcSpaceHorizontal } from '@utils/Spacing'

// const getScoreLabel = (score: number) => {
//   switch (score) {
//     case 2:
//       return 'not-enough'
//     case 3:
//       return 'not-enough'
//     case 4:
//       return 'almost'
//     case 5:
//       return 'strong'
//     default:
//       return 'bad'
//   }
// }

const getScore = (password: string) => {
  let score = 0
  if (/[A-Z]/.test(password)) {
    score++
  }
  if (/[a-z]/.test(password)) {
    score++
  }
  if (/[0-9]/.test(password)) {
    score++
  }
  if (/[@$!%*#?&]/.test(password)) {
    score++
  }
  if (password.length >= 8) {
    score++
  }
  return score
}

export function PasswordStrengthMeter({ password }: { password: string }) {
  const color =
    getScore(password) <= 0
      ? '#f1f1f1'
      : getScore(password) <= 2
        ? 'red'
        : getScore(password) === 3
          ? '#F8B72F'
          : getScore(password) === 4
            ? '#6dbd1a'
            : getScore(password) >= 5
              ? 'green'
              : 'red'

  return (
    <Box
      mt={1}
      pt={0.1}
      pb={0.1}
      pl={0.3}
      bgcolor="white"
      display="flex"
      alignItems="center"
    >
      <Box display="flex">
        <One position={1} score={getScore(password)} color={color} />
        <One position={2} score={getScore(password)} color={color} />
        <One position={3} score={getScore(password)} color={color} />
        <One position={4} score={getScore(password)} color={color} />
        <One position={5} score={getScore(password)} color={color} />
      </Box>
      <EcSpaceHorizontal size={3} />

      {/* <Text
        text={getScoreLabel(getScore(password))}
        style={{
          fontSize: 11.5,
          color: color,
        }}
      /> */}
    </Box>
  )
}

const One = ({
  position,
  score,
  color,
}: {
  position: number
  score: number
  color: string
}) => {
  return (
    <Box
      borderRadius={8}
      width={50}
      height={5}
      bgcolor={position <= score ? color : '#f1f1f1'}
      mr={0.4}
    ></Box>
  )
}
