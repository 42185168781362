/* eslint-disable indent */
import { ContentCut, ContentCopy, ContentPaste } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material'
import Quill from 'quill'
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  useEffect,
} from 'react'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import { ReportParagraphDto, ReportTemplateDto } from '../../services/api'
import Draggable from 'react-draggable'
import { useSelector } from '../../state/store'
import SearchIcon from '@mui/icons-material/Search'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { findParagraphs } from '../../state/thunks/reportingThunk'
import ReportTemplatePreview from './ReportTemplatePreview'

interface Props {
  quill: Quill
  paragraphs: ReportParagraphDto[]
  style?: React.CSSProperties
  ref?: React.Ref<HTMLDivElement>
  close: () => void
}
const ReportContextMenu = forwardRef<
  {
    divRef?: React.RefObject<HTMLDivElement>
  },
  Props
>(({ quill, paragraphs, style, close }, ref) => {
  const dispatch = useAppDispatch()

  const [isParagraphsOpen, setIsParagraphsOpen] = useState(false)
  const [templateSearchValue, setTetmplateSearchValue] = useState('')
  const [selectedTemplate, setSelectedTemplate] =
    useState<ReportTemplateDto | null>(null)

  const currentMousePosition = useRef({ x: 0, y: 0 })

  const templates = useSelector((state) => state.reporting.templates)

  const _ref = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    divRef: _ref,
  }))

  const insertHtmlAtCursor = (html: string) => {
    const range = quill.getSelection()
    if (range) {
      quill.clipboard.dangerouslyPasteHTML(range.index, html)
    }
  }

  const onAddParagraph = (paragraph: ReportParagraphDto) => {
    console.log('Add paragraph!')
    insertHtmlAtCursor(paragraph.text)
  }

  const handleCopyClick = () => {
    const range = quill.getSelection()
    if (range) {
      const text = quill.getText(range.index, range.length)
      navigator.clipboard.writeText(text)
    }
    close()
  }

  const handlePasteClick = () => {
    navigator.clipboard.readText().then((text) => {
      insertHtmlAtCursor(text)
      close()
    })
  }

  const handleCutClick = () => {
    const range = quill.getSelection()
    if (range) {
      const text = quill.getText(range.index, range.length)
      navigator.clipboard.writeText(text)
      quill.deleteText(range.index, range.length)
    }
    close()
  }

  const filteredTemplates = useMemo(() => {
    return templateSearchValue === ''
      ? templates
      : templates.filter((t) =>
          t.name.toLowerCase().includes(templateSearchValue.toLowerCase()),
        )
  }, [templateSearchValue, templates])

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(findParagraphs(selectedTemplate.id))
    }
  }, [selectedTemplate])

  return (
    <>
      <Paper
        sx={{
          width: 320,
          maxWidth: '100%',
          position: 'absolute',
          zIndex: 2,
          ...style,
        }}
        ref={_ref}
        id="report-context-menu"
      >
        <MenuList>
          <MenuItem onClick={handleCutClick}>
            <ListItemIcon>
              <ContentCut fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cut</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘X
            </Typography>
          </MenuItem>
          <MenuItem>
            <ListItemIcon onClick={handleCopyClick}>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘C
            </Typography>
          </MenuItem>
          <MenuItem onClick={handlePasteClick}>
            <ListItemIcon>
              <ContentPaste fontSize="small" />
            </ListItemIcon>
            <ListItemText>Paste</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘V
            </Typography>
          </MenuItem>
          <Divider />
          <ListItemButton
            onClick={() => {
              const container = document.getElementById('editor-container')
              const reportLeft = document.getElementById('report-left')

              if (container && reportLeft) {
                currentMousePosition.current = {
                  x: container.getBoundingClientRect().left,
                  y: container.scrollTop,
                }

                setIsParagraphsOpen(true)
              }
            }}
          >
            <ListItemIcon>
              <FormatAlignLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Inserer un paragraph" />
          </ListItemButton>
        </MenuList>
      </Paper>
      <Draggable
        positionOffset={{
          x: currentMousePosition.current.x,
          y: currentMousePosition.current.y,
        }}
      >
        {isParagraphsOpen ? (
          <div
            style={{
              position: 'absolute',
              width: '30vw',
              height: '60vh',
              border: '1px solid #CCC',
              zIndex: 100,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // backgroundColor: '#F9FAFB',
              }}
            >
              <Button
                color="error"
                className="times"
                onClick={() => setIsParagraphsOpen(false)}
                style={{
                  backgroundColor: '#FEF1F2',
                }}
              >
                <i
                  style={{
                    fontSize: '1.5rem',
                  }}
                  className="fas fa-times"
                ></i>
              </Button>
            </div>
            <Divider />
            <Box>
              {selectedTemplate ? (
                <Button
                  style={{
                    backgroundColor: '#F9FAFB',
                    borderRadius: 0,
                  }}
                  onClick={() => setSelectedTemplate(null)}
                >
                  <i
                    style={{
                      fontSize: '1.5rem',
                    }}
                    className="fas fa-arrow-left"
                  ></i>
                </Button>
              ) : (
                <div
                  style={{
                    margin: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #CCC',
                    marginBottom: '16px',
                    borderRadius: '4px',
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Chercher"
                    value={templateSearchValue}
                    onChange={(e) => setTetmplateSearchValue(e.target.value)}
                    inputProps={{ 'aria-label': 'Chercher' }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SearchIcon style={{ color: 'black' }} />
                  </IconButton>
                </div>
              )}

              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  padding: '12px',
                }}
              >
                {selectedTemplate
                  ? paragraphs.map((paragraph) => (
                      <span
                        key={paragraph.id}
                        style={{
                          padding: '8px',
                          cursor: 'pointer',
                          color: 'black',
                        }}
                        onClick={() => {
                          quill.focus()
                          onAddParagraph(paragraph)
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                          }}
                        >
                          {paragraph.title}
                        </Typography>
                        <div
                          className="ql-container ql-editor ql-snow"
                          style={{ backgroundColor: 'white', border: 'none' }}
                          dangerouslySetInnerHTML={{
                            __html: paragraph.text,
                          }}
                        ></div>
                        <Divider />
                      </span>
                    ))
                  : filteredTemplates.map((template) => (
                      <div
                        key={template.id}
                        onClick={() => {
                          setSelectedTemplate(template)
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <ReportTemplatePreview
                          onUseTemplateClick={() => {}}
                          template={template}
                          hideActions
                        />
                      </div>
                    ))}
              </Box>
            </Box>
          </div>
        ) : (
          <div />
        )}
      </Draggable>
    </>
  )
})

export default ReportContextMenu
