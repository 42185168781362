import { createTheme } from '@material-ui/core'

export default createTheme({
  palette: {
    primary: { main: '#3f3f3f' },
    secondary: { main: '#E91E63' },
    text: {
      primary: '#000',
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  overrides: {
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'white',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#d9e1f780',
        },
      },
    },

    MuiContainer: {
      root: {
        display: 'flex',
      },
    },

    MuiIcon: {
      root: {
        overflow: 'visible',
      },
    },
  },
})
