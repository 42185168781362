import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'

import { MedicalOrderDto, ReportStatus } from '../../services/api'
import { createReport, updateReport } from '../../state/thunks/reportingThunk'
import { ReportHtml } from './ReportingEditor'
import { useState } from 'react'
import { CustomizablePage } from '../../common/interfaces'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { useAppDispatch } from '../../hooks/reduxHooks'

interface Props {
  reportHtml: ReportHtml
  medicalOrder: MedicalOrderDto
  pages: CustomizablePage[]
  onClose: () => void
}
const SaveReportForm: React.FC<Props> = ({
  reportHtml,
  medicalOrder,
  pages,
  onClose,
}) => {
  const [reportStatus, setReportStatus] = useState<string>(
    medicalOrder.reports.length !== 0
      ? medicalOrder.reports[0].status
      : ReportStatus.TODO,
  )
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useAppDispatch()

  const onSaveClick = () => {
    const el = document.getElementById('report_preview')
    if (!el) {
      dispatch(
        enqueueSnackbar({
          message: 'Error occured saving report: element not found',
          type: 'error',
        }),
      )
      return
    }

    const existingReport =
      medicalOrder.reports.length !== 0 ? medicalOrder.reports[0] : null
    const pageElements = document.getElementsByClassName('report-page')
    const pagesContent: string[] = []

    for (let i = 0; i < pageElements.length; i++) {
      pagesContent.push(pageElements[i].outerHTML)
    }

    setIsSaving(true)

    const cb = () => {
      setIsSaving(false)
      dispatch(
        enqueueSnackbar({
          message: 'Compte rendu enregistré avec succès',
          type: 'success',
        }),
      )
      onClose()
    }

    if (existingReport) {
      dispatch(
        updateReport({
          id: existingReport.id,
          dto: {
            ...reportHtml,
            medicalOrderId: medicalOrder.id,
            status: reportStatus,
            pages: JSON.stringify(pages),
            htmlContent: el.innerHTML,
            pagesContent,
          },
        }),
      )
        .unwrap()
        .then(cb)
    } else {
      dispatch(
        createReport({
          dto: {
            ...reportHtml,
            medicalOrderId: medicalOrder.id,
            status: reportStatus,
            pages: JSON.stringify(pages),
            htmlContent: el.innerHTML,
            pagesContent,
          },
        }),
      )
        .unwrap()
        .then(cb)
    }
  }
  return (
    <Box>
      <LinearProgress
        style={{ margin: '8px 0', visibility: isSaving ? 'visible' : 'hidden' }}
      />
      <FormControl fullWidth>
        <InputLabel id="reportStatus">Report Status</InputLabel>
        <Select
          value={reportStatus}
          onChange={(ev) => setReportStatus(ev.target.value)}
          fullWidth
          id="reportStatus"
        >
          <MenuItem value={ReportStatus.TODO}>Todo</MenuItem>
          <MenuItem value={ReportStatus.IN_PROGRESS}>In-progress</MenuItem>
          <MenuItem value={ReportStatus.PENDING_VALIDATION}>
            Pending Validation
          </MenuItem>
          <MenuItem value={ReportStatus.COMPLETED}>Completed</MenuItem>
        </Select>
      </FormControl>
      <Button
        style={{ marginTop: '8px', width: '100px' }}
        variant="contained"
        disabled={isSaving}
        onClick={onSaveClick}
      >
        Save
      </Button>
    </Box>
  )
}

export default SaveReportForm
