import { DateInput, TextInput } from '@ether-healthcare/inputs'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { AccountDto, AccountRoleEnum, User } from '@services/api'
import theme from '@utils/theme'
// import { useDispatch } from 'react-redux'
import { Text } from '../texts'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type UserInfosCardProps = {
  user: User | undefined
  account: AccountDto
}
// type Values = {
//   address: string
// }

export function UserInfosCard({ user, account }: UserInfosCardProps) {
  // const dispatch = useDispatch()
  const classes = useStyles()
  const {
    formState: { errors },
    // handleSubmit,
    control,
  } = useForm<User>({
    defaultValues: user,
  })

  // const onSubmit = ({ address }: Values) => {
  //   // dispatch(updateAddress({ address }))
  //   console.log(address)
  // }

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon
                className="fa fa-file-alt"
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item>
              <Text
                text="profile"
                variant={'h6'}
                format
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {account.role === AccountRoleEnum.patient ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="firstName"
                placeholder="firstName"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                name="lastName"
                placeholder="lastName"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextInput
                name="address"
                placeholder="address"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateInput
                name="birthDate"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInput
                placeholder="birthPlace"
                name="birthPlace"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                name="sex"
                placeholder="Sex"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                name="ssn"
                placeholder="ssn"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
        ) : account.role === AccountRoleEnum.doctor ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="firstName"
                placeholder="firstName"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                name="lastName"
                placeholder="lastName"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                name="rppsNumber"
                placeholder="rppsNumber"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                name="skill"
                placeholder="skill"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="firstName"
                placeholder="firstName"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                name="lastName"
                placeholder="lastName"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      {/* TODO Add back after creating crud for doctor address */}
      {/* {account.role === AccountRoleEnum.doctor && (
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button
                text="submit"
                onClick={handleSubmit(onSubmit)}
                format
                color="secondary"
              />
            </Grid>
          </Grid>
        </CardActions>
      )} */}
    </Card>
  )
}
