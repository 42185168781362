import { Dialog, DialogContent } from '@material-ui/core'

import React from 'react'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { setIsCreateReportDialogOpen } from '../../state/reducers/studyReducer'
import MedicalOrderForm from '../forms/MedicalOrderForm'
import { Box } from '@mui/material'

const CreateMedicalOrderDialog = ({ open }) => {
  const dispatch = useAppDispatch()

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={() => {
        dispatch(setIsCreateReportDialogOpen(false))
      }}
      PaperProps={{
        style: { borderRadius: 20, width: '100%', border: '2px solid black' },
      }}
    >
      <DialogContent>
        <Box>
          <MedicalOrderForm />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateMedicalOrderDialog
