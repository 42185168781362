import React, { useState } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

export default function DicomGallery({ study }) {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const handleOpen = (img) => {
    setSelectedImage(img)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom fontWeight="bold">
          {study.exam.label}
        </Typography>
      </div>
      <ImageList cols={3} rowHeight={164} gap={15}>
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            sx={{
              position: 'relative',
              '&:hover, &:focus-visible': {
                opacity: 0.7,
                '& .image-title': {
                  display: 'block',
                },
              },
            }}
          >
            <img
              srcSet={`${process.env.PUBLIC_URL}/${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${process.env.PUBLIC_URL}/${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              onClick={() => handleOpen(item.img)}
              style={{ cursor: 'zoom-in', width: '100%', height: 'auto' }}
            />
            <Typography
              className="image-title"
              variant="subtitle1"
              sx={{
                display: 'none',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                textAlign: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                padding: '4px 0',
              }}
            >
              {item.title}
            </Typography>
          </ImageListItem>
        ))}
      </ImageList>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/${selectedImage}`}
            alt="Selected"
            style={{ maxWidth: '100%', maxHeight: '90vh' }}
          />
        </Box>
      </Modal>
    </>
  )
}

const itemData = [
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-1.png',
    title: 'Image1',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-2.png',
    title: 'Image2',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-3.png',
    title: 'Image3',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-4.png',
    title: 'Image4',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-1.png',
    title: 'Image1',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-2.png',
    title: 'Image2',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-3.png',
    title: 'Image3',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-4.png',
    title: 'Image4',
  },
]
