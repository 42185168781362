import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

const questionList = [
  {
    id: 2,
    type: 'CHOICES',
    text: 'La douleur se manifeste-t-elle au repos, en activité ou les deux?',
    choices: [
      { id: 3, text: 'Actif' },
      { id: 7, text: 'Repos' },
      { id: 8, text: 'Les deux' },
    ],
  },
  { id: 4, type: 'CLOSED', text: 'Es-ce gonflé ou rouge?', choices: [] },
  { id: 7, type: 'CLOSED', text: 'Avez-vous du diabete?', choices: [] },
  { id: 8, type: 'CLOSED', text: 'Avez vous de l\'hypertension?', choices: [] },
  {
    id: 24,
    type: 'CLOSED',
    text: 'Avez vous des troubles de la coagulation?',
    choices: [],
  },
  { id: 25, type: 'CLOSED', text: 'etes vous sous anticoagulant.', choices: [] },
  { id: 34, type: 'CLOSED', text: 'Etes vous fumeur?', choices: [] },
  {
    id: 35,
    type: 'CLOSED',
    text: 'Consommez vous regulierement de l\'alcool?',
    choices: [],
  },
  {
    id: 37,
    type: 'CLOSED',
    text: 'Avez vous un syndrome de Raynaud?',
    choices: [],
    askSecondaryIf: 'TRUE',
    secondarytext: {
      id: 1000,
      type: 'DATE',
      text: 'Depuis quand?',
      choices: [],
    },
  },
  {
    id: 69,
    type: 'CLOSED',
    text: 'Avez vous des douleurs lors de la marche?',
    choices: [],
  },
  {
    id: 6,
    type: 'CLOSED',
    text: 'Avez vous subi une opération en rapport avec votre examen d\'aujourd\'hui?',
    choices: [],
  },
]

export const findAllQuestions = createAsyncThunk(
  Actions.QUESTION_FIND_ALL,
  //   async (params: { examId: number }, { rejectWithValue }) => {
  async () => {
    try {
      return questionList
    } catch (e: any) {
      //   if (e.response) {
      //     return rejectWithValue(e.response.data.message)
      //   }
      //   return rejectWithValue(e.response.data.message)
    }
  },
)
