import { Button } from '@components/buttons'
import { Button as MButton, Grid, Icon } from '@material-ui/core'
import { useSelector } from '@state/store'
import { findMyInfos, updatePhone } from '@state/thunks/authThunk'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ConfirmPhoneDialog } from '../../components/dialogs'
import { TextInput } from '../../components/inputs/TextInput'
import { formatNumber, validateMobileNumber } from '../../utils/utils'
import { setIsPhoneUpdateRequestSent } from '../../state/reducers/authReducer'

export interface PhoneVerificationProps {
  isUpdateButtonVisible?: boolean
}
export default function PhoneVerification({
  isUpdateButtonVisible,
}: PhoneVerificationProps) {
  const dispatch = useDispatch()
  const { account, phoneUpdateSent } = useSelector(({ auth }) => ({
    account: auth.account,
    phoneUpdateSent: auth.isPhoneUpdateRequestSent,
  }))

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<{ phoneNumber: string; hiddenNumber: string }>({
    defaultValues: {},
  })

  const [openPhoneDialog, setOpenPhoneDialog] = useState(false)
  const prevValue = useRef('')
  const [isPhoneHidden, setIsPhoneHidden] = useState(false)
  const [updating, setUpdating] = useState(false)
  const phoneRef = useRef('')
  const hiddenPhoneRef = useRef('')
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenPhoneDialog = () => {
    setOpenPhoneDialog(true)
  }
  const handleClosePhoneDialog = () => {
    setOpenPhoneDialog(false)
  }
  const handleUpdatingPhone = () => {
    setUpdating(true)
    setIsPhoneHidden(false)
  }
  const handleUpdatePhone = ({ phoneNumber }: { phoneNumber: string }) => {
    if (errors.phoneNumber) {
      return
    }
    let phone = isPhoneHidden ? phoneRef.current : phoneNumber
    if (!phone) {
      setError('phoneNumber', { message: 'Phone number is required' })
      return
    }
    if (phone.startsWith('0')) {
      phone = phone.substring(1, phone.length)
    }
    if (!phone.includes('+33')) {
      phone = '+33 ' + phone
    }
    dispatch(updatePhone({ phoneNumber: phone }))
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 3000)
  }

  useEffect(() => {
    dispatch(findMyInfos())
    dispatch(setIsPhoneUpdateRequestSent(false))
  }, [])

  useEffect(() => {
    const phone = account?.phone

    if (phone) {
      const p = phone.replace(/\s/g, '').replace('+33', '')
      const hidden = p.substring(0, p.length - 3)
      const text = hidden.split('').map(() => 'X')
      const visible = p.substring(p.length - 3)
      const formated = formatNumber(text.join('') + visible)
      setIsPhoneHidden(true)
      phoneRef.current = phone
      hiddenPhoneRef.current = formated
      setValue('hiddenNumber', formated)
    }
  }, [account?.phone])

  useEffect(() => {
    const p = watch().phoneNumber

    if (!p) {
      return
    }

    if (isPhoneHidden) {
      setIsPhoneHidden(false)
      return
    }

    const isValid = validateMobileNumber(p)

    if (!isValid) {
      setError('phoneNumber', { message: 'Format invalide' })
      setValue('phoneNumber', prevValue.current)
      return
    }
    const phoneNumberError = errors.phoneNumber
    if (phoneNumberError) {
      clearErrors('phoneNumber')
    }

    const cleaned = p.replace(/\s/g, '')
    if (/\D+/g.test(cleaned)) {
      setValue('phoneNumber', prevValue.current)
      return
    }
    const formated = formatNumber(cleaned)

    if (!formated) {
      return
    }
    if (formated === p) {
      return
    }

    setValue('phoneNumber', formated)
    prevValue.current = formated
  }, [watch('phoneNumber')])

  useEffect(() => {
    const p = watch('hiddenNumber')
    if (p !== hiddenPhoneRef.current) {
      setIsPhoneHidden(false)
    }
  }, [watch('hiddenNumber')])

  return (
    <Grid container style={{ padding: 22 }}>
      <Grid
        item
        xs
        style={{
          display: 'flex',
        }}
      >
        <MButton
          startIcon={
            <Icon
              style={{ color: 'white', marginLeft: '8px' }}
              className="fas fa-mobile-alt"
            />
          }
          style={{
            backgroundColor: '#3e3e3e',
            marginTop: 15,
            marginBottom: 15,
            width: '10%',
            borderRadius: '4px 0px 0px 4px',
            flexWrap: 'wrap',
          }}
          size="small"
          disabled
        />
        {/* {isPhoneHidden ? ( */}
        {isPhoneHidden && (
          <TextInput
            control={control}
            name="hiddenNumber"
            errors={errors}
            style={{ width: '60%' }}
            type="text"
            label="phoneNumberLabel"
            onChange={() => setIsPhoneHidden(false)}
            inputStyle={{
              paddingLeft: '8px',
            }}
            disabled={!isUpdateButtonVisible}
          />
        )}
        {!isPhoneHidden && (
          <TextInput
            control={control}
            name="phoneNumber"
            style={{ width: '60%' }}
            errors={errors}
            inputStyle={{
              paddingLeft: '8px',
            }}
            type="text"
            label="phoneNumberLabel"
          />
        )}
        {(updating || isUpdateButtonVisible) && (
          <Button
            text="gotCode"
            style={{
              marginTop: 16,
              marginBottom: 15,
              borderRadius: '0px 4px 4px 0px',
              width: '40%',
            }}
            color="secondary"
            size="small"
            onClick={handleOpenPhoneDialog}
            disabled={!phoneUpdateSent}
          />
        )}
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
        {updating || isUpdateButtonVisible ? (
          <>
            {!isUpdateButtonVisible && (
              <Button
                type="submit"
                onClick={() => {
                  setUpdating(false)
                  setIsPhoneHidden(true)
                  setIsLoading(true)
                  setTimeout(() => setIsLoading(false), 3000)
                }}
                text="cancel"
                fullWidth
                color="primary"
                format
                style={{
                  marginTop: 5,
                  marginRight: 5,
                }}
              />
            )}
            <Button
              type="submit"
              onClick={handleSubmit(handleUpdatePhone)}
              text="sendCode"
              fullWidth
              color="secondary"
              format
              style={{
                marginTop: 5,
              }}
              loading={isLoading}
            />
          </>
        ) : (
          <Button
            text="updatePhone"
            onClick={handleUpdatingPhone}
            fullWidth
            color="secondary"
            format
            style={{
              marginTop: 5,
            }}
          />
        )}
      </Grid>
      <ConfirmPhoneDialog
        open={openPhoneDialog}
        onClose={handleClosePhoneDialog}
      />
    </Grid>
  )
}
