import React, { FC, useRef } from 'react'
import { useDrag } from 'react-dnd'

export interface DraggableImageProps {
  src: string
  onDoubleClick: (ev) => void
  id: string
  style: React.CSSProperties
  containerstyle?: React.CSSProperties
}

const DraggableImage: FC<DraggableImageProps> = ({
  src,
  onDoubleClick,
  id,
  style,
  containerstyle,
}) => {
  const [, drag] = useDrag(() => ({
    type: 'DraggableImage',
    item: { src },
  }))

  const imageRef = useRef<HTMLImageElement>()

  return (
    <div
      ref={(node) => {
        drag(node)
        imageRef.current = node as HTMLImageElement
      }}
      style={{
        ...containerstyle,
      }}
    >
      <img
        id={id}
        src={src}
        alt=""
        className="draggable-image"
        style={style}
        onDoubleClick={onDoubleClick}
      />
    </div>
  )
}

export default DraggableImage
