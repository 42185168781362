import { Dialog, DialogContent } from '@material-ui/core'
import HelpImage from 'src/assets/img/HowToPortal.png'
// import { useIntl } from 'react-intl'

type HelpDialogProps = {
  open: boolean
  setHelpOpen: (open: boolean) => void
}

export function HelpDialog({ open, setHelpOpen }: HelpDialogProps) {
  // const intl = useIntl()

  const onClose = () => {
    setHelpOpen(false)
  }

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: '100%',
          background: '#3f3f3f',
        },
      }}
    >
      <DialogContent style={{ cursor: 'pointer' }} onClick={onClose}>
        <img
          src={HelpImage}
          alt="Help"
          style={{ display: 'block', width: '100%', margin: 'auto' }}
        />
      </DialogContent>
    </Dialog>
  )
}
