import { Button, Divider, Grid } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DroppableFileCard } from '../cards/DroppableFileCard'
import {
  deepCopy,
  fileToDataUrl,
  getImageFromClipboard,
} from '../../utils/helpers'
import { ColumnInfos, RowInfos } from '../../common/interfaces'
import ToggleButton from '../buttons/ToggleButton'
import { reportLayouts } from '../../utils/Constants'
import * as markerJs from 'markerjs2'
import DraggableImage from '../draggable-image/DraggableImage'

interface ClipboardItem {
  readonly types: string[]
  readonly presentationStyle: 'unspecified' | 'inline' | 'attachment'
  getType(): Promise<Blob>
}

interface ClipboardItemData {
  [mimeType: string]: Blob | string | Promise<Blob | string>
}

declare let ClipboardItem: {
  prototype: ClipboardItem
  new (itemData: ClipboardItemData): ClipboardItem
}

interface ReportPageProps {
  pageIndex: number
  rowInfos: RowInfos[]
  onChange: (rowInfos: RowInfos[]) => void
  isSaving: boolean
}
const ReportPage: React.FC<ReportPageProps> = ({
  onChange,
  rowInfos,
  isSaving,
}) => {
  const [editingGrid] = useState('')
  const [editingGridLabel] = useState('')
  const [orientation] = useState<'landscape' | 'portrait'>('portrait')
  const [selectedImage, setSelectedImage] = useState<{
    target: HTMLImageElement
    column: ColumnInfos
  } | null>(null)

  const pageRef = useRef<HTMLDivElement>(null)
  const [selectedGrid, setSelectedGrid] = useState<{
    colIndex: number
    id: string
  } | null>(null)

  const handleAddColumnClick = () => {
    if (rowInfos.length === 4) {
      return
    }
    const newState = [...rowInfos]
    newState.push({
      children: [
        {
          id: uuidv4(),
        },
      ],
    })
    onChange(newState)
  }

  const onSplitElementClick = (rowIndex: number, colIndex: number) => {
    const newState = [...rowInfos]
    newState[colIndex].children?.push({
      id: uuidv4(),
    })
    onChange(newState)
  }

  const onClearGrid = (id: string, colIndex: number) => {
    const newState = [...rowInfos]
    if (newState[colIndex].children) {
      const el = newState[colIndex].children?.find((el) => el.id === id)
      if (el && el.fileUrl) {
        el.fileUrl = ''
        onChange(newState)
      }
    }
  }

  const onDeleteElementClick = (id: string) => {
    const newState = [...rowInfos]
    if (newState.length === 1 && newState[0].children?.length === 1) {
      return
    }
    for (let i = 0; i < newState.length; i++) {
      const index = newState[i].children?.findIndex((el) => el.id === id)
      if (index !== undefined && index !== -1) {
        newState[i].children?.splice(index, 1)
        if (!newState[i].children?.length) {
          newState.splice(i, 1)
        }
        break
      }
    }

    onChange(newState)
  }

  const onFileDrop = async (files: File[], id: string) => {
    const newState = [...rowInfos]
    if (files.length) {
      for (let i = 0; i < newState.length; i++) {
        const index = newState[i].children?.findIndex((el) => el.id === id)
        if (index !== undefined && index !== -1) {
          const children = newState[i].children
          if (!children) {
            return
          }
          children[index].fileUrl = await fileToDataUrl(files[0])
          onChange(newState)

          break
        }
      }
    }
  }

  const handlePasteClick = async (id: string, colIndex: number) => {
    const f = await getImageFromClipboard()
    if (f) {
      const newState = [...rowInfos]
      const children = newState[colIndex].children
      if (!children) {
        return
      }

      const index = newState[colIndex].children?.findIndex((el) => el.id === id)
      if (index !== undefined && index !== -1) {
        const children = newState[colIndex].children
        if (!children) {
          return
        }
        children[index].fileUrl = await fileToDataUrl(f)
        onChange(newState)
      }
    }
  }

  useEffect(() => {
    if (!selectedImage) {
      return
    }

    const markerArea = new markerJs.MarkerArea(selectedImage.target)

    markerArea.addEventListener('render', (event) => {
      selectedImage.target.src = event.dataUrl
      selectedImage.column.fileUrl = event.dataUrl
    })
    markerArea.settings.displayMode = 'popup'

    markerArea.show()
  }, [selectedImage])

  useEffect(() => {
    const onPaste = () => {
      if (selectedGrid) {
        handlePasteClick(selectedGrid.id, selectedGrid.colIndex)
      }
    }
    window.addEventListener('paste', onPaste)

    const onCopy = () => {
      if (!selectedGrid) {
        return
      }
      const img = document.getElementById(selectedGrid.id) as HTMLImageElement
      if (img) {
        const canvas = document.getElementById('imageCanvas') as any
        if (!canvas) {
          return
        }
        const container = img.parentElement
        if (!container) {
          return
        }
        const scaleX = container.clientWidth / img.width
        const scaleY = container.clientHeight / img.height
        const scale = Math.min(scaleX, scaleY)

        // Calculate the new img dimensions
        const newWidth = img.width * scale
        const newHeight = img.height * scale

        // Calculate the position to center the image
        const x = (canvas.width - newWidth) / 2
        const y = canvas.height - newHeight

        // Draw the resized image on the canvas
        const context = canvas.getContext('2d')
        context.drawImage(img, x, y, newWidth, newHeight)

        // Copy the canvas as an image to the clipboard
        canvas.toBlob(function (blob) {
          const item = new ClipboardItem({ 'image/png': blob })
          ;(navigator.clipboard as any)
            .write([item])
            .then(function () {
              alert('Image copied to clipboard!')
            })
            .catch(function (err) {
              console.error('Unable to copy image to clipboard: ', err)
            })
        }, 'image/png')
      }
    }
    window.addEventListener('copy', onCopy)

    return () => {
      window.removeEventListener('copy', onCopy)
      window.removeEventListener('paste', onPaste)
    }
  }, [selectedGrid])

  const items = useMemo(() => {
    const elements: React.ReactElement[] = []
    rowInfos.forEach((column, colIndex) => {
      const h = (275 / (column.children?.length || 1)).toString() + 'mm'
      const w = (200 / (rowInfos.length || 1)).toString() + 'mm'
      elements.push(
        <Grid
          key={colIndex}
          style={{
            flex: 1,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          xs
        >
          {column.children?.map((el, i) => {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: h,
                    width: '100%',
                  }}
                  key={el.id}
                >
                  <DroppableFileCard
                    isSelected={selectedGrid?.id === el.id && !isSaving}
                    onDrop={({ files, src }) => {
                      if (files) {
                        onFileDrop(files, el.id)
                      } else if (src) {
                        console.log({ src })
                      }
                    }}
                    onClick={() => {
                      setSelectedGrid({
                        colIndex,
                        id: el.id,
                      })
                    }}
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flex: 1,
                    }}
                    title={el.title || ''}
                  >
                    {!isSaving && (
                      <div
                        style={{
                          border: '1px solid #CCC',
                          display: 'grid',
                          justifyContent: 'center',
                          alignItems: 'center',
                          opacity: el.fileUrl ? 0.8 : 1,
                          height: '32px !important',
                          flexWrap: 'nowrap',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                        }}
                        className="layout-action-buttons-wrapper"
                      >
                        {/* {editingGrid === el.id && (
                          <FormControl>
                            <TextField
                              variant="outlined"
                              value={editingGridLabel}
                              onChange={(ev) =>
                                setEditingGridLabel(ev.target.value)
                              }
                              fullWidth
                            />
                            <Button
                              onClick={() => {
                                const newState = [...rowInfos]
                                const children = newState[colIndex].children
                                if (!children) {
                                  return
                                }
                                const index = children.findIndex(
                                  (el) => el.id === editingGrid,
                                )
                                if (index !== undefined && index !== -1) {
                                  children[index].title = editingGridLabel
                                  setEditingGrid('')
                                  setEditingGridLabel('')
                                }
                              }}
                            >
                              Save
                            </Button>
                          </FormControl>
                        )} */}
                        <div
                          className="layout-action-buttons"
                          id={`layout-action-buttons-${el.id}`}
                        >
                          {/* <Button
                            onClick={() => {
                              setEditingGrid(el.id)
                              setEditingGridLabel(el.title || '')
                            }}
                          >
                            <i className="fas fa-edit" />
                          </Button> */}

                          <Button
                            onClick={() => {
                              onClearGrid(el.id, colIndex)
                            }}
                          >
                            <i className="fas fa-minus-circle"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              onSplitElementClick(colIndex, colIndex)
                            }}
                          >
                            <i
                              style={{ transform: 'rotate(90deg)' }}
                              className="fas fa-columns"
                            ></i>
                          </Button>
                          <Button
                            onClick={() => {
                              onDeleteElementClick(el.id)
                            }}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                          <Button
                            onClick={() => handlePasteClick(el.id, colIndex)}
                          >
                            <i className="fas fa-paste" />
                          </Button>
                        </div>
                      </div>
                    )}
                    <DraggableImage
                      src={el.fileUrl || ''}
                      id={el.id}
                      style={{
                        maxWidth: w,
                        maxHeight: h,
                      }}
                      onDoubleClick={(ev) => {
                        ev.preventDefault()
                        setSelectedImage({
                          target: ev.target as HTMLImageElement,
                          column: el,
                        })
                      }}
                    />
                  </DroppableFileCard>
                </div>
              </>
            )
          })}
        </Grid>,
      )
    })
    return elements
  }, [rowInfos, editingGrid, editingGridLabel, isSaving, selectedGrid])

  return (
    <Grid container>
      <Grid item xs={10}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* <ToggleButton
            onClick={() => setOrientation('landscape')}
            isActive={orientation === 'landscape'}
            icon={
              <i
                style={{
                  color: orientation === 'landscape' ? '#1EBC86' : '#6A6A6A',
                }}
                className="fas fa-image"
              ></i>
            }
            text="Landscape"
          />

          <ToggleButton
            onClick={() => setOrientation('portrait')}
            isActive={orientation === 'portrait'}
            icon={
              <i
                style={{
                  color: orientation === 'portrait' ? '#1EBC86' : '#6A6A6A',
                }}
                className="fas fa-portrait"
              ></i>
            }
            text="Portrait"
          /> */}
        </div>
        <div
          className="report-page"
          style={{
            height: '297mm',
            width: '210mm',
            display: 'flex',

            flexDirection: 'column',
            transform:
              orientation === 'landscape'
                ? 'rotate(90deg) translate(0, -200px) '
                : 'none',
          }}
          ref={pageRef}
        >
          <Grid
            container
            style={{
              flex: 1,
              position: 'relative',
            }}
          >
            {items}
            {!isSaving && (
              <Button
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  position: 'absolute',
                  bottom: 0,
                  top: 0,
                  right: -90,
                  backgroundColor: 'white',
                }}
                onClick={() => handleAddColumnClick()}
                variant="outlined"
              >
                <i className="fas fa-plus"></i>
                <i
                  style={{
                    fontSize: 20,
                  }}
                  className="fas fa-chevron-right"
                ></i>
              </Button>
            )}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={1}>
        <Grid
          xs={2}
          item
          style={{
            paddingLeft: 100,
          }}
        >
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <ToggleButton
              style={{
                width: '100px',
                height: '100px',
                border: 'none',
              }}
              isActive={false}
              icon={
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/assets/images/layout-1.png`}
                  style={{ width: '100%' }}
                  alt=""
                />
              }
              text=""
              onClick={() => {
                onChange(deepCopy(reportLayouts.layout1))
              }}
            />
          </div>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <ToggleButton
              style={{
                width: '100px',
                height: '100px',
                border: 'none',
              }}
              isActive={false}
              icon={
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/assets/images/layout-2.png`}
                  style={{ width: '100%' }}
                  alt=""
                />
              }
              text=""
              onClick={() => {
                onChange(deepCopy(reportLayouts.layout2))
              }}
            />
          </div>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <ToggleButton
              style={{
                width: '100px',
                height: '100px',
                border: 'none',
              }}
              isActive={false}
              icon={
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/assets/images/layout-3.png`}
                  style={{ width: '100%' }}
                  alt=""
                />
              }
              text=""
              onClick={() => {
                onChange(deepCopy(reportLayouts.layout3))
              }}
            />
          </div>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <ToggleButton
              style={{
                width: '100px',
                height: '100px',
                border: 'none',
              }}
              isActive={false}
              icon={
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/assets/images/layout-4.png`}
                  style={{ width: '100%' }}
                  alt=""
                />
              }
              text=""
              onClick={() => {
                onChange(deepCopy(reportLayouts.layout4))
              }}
            />
          </div>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <ToggleButton
              style={{
                width: '100px',
                height: '100px',
                border: 'none',
              }}
              isActive={false}
              icon={
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/assets/images/layout-5.png`}
                  style={{ width: '100%' }}
                  alt=""
                />
              }
              text=""
              onClick={() => {
                onChange(deepCopy(reportLayouts.layout5))
              }}
            />
          </div>
        </Grid>
      </Grid>
      <canvas id="imageCanvas" style={{ display: 'none' }}></canvas>
    </Grid>
  )
}

export default ReportPage
