import { useEffect, useState } from 'react'
import { useSelector } from '@state/store'
import { useDispatch } from 'react-redux'
import { refreshToken } from '@state/thunks/authThunk'
import { setIsAuthChecked } from '../state/reducers/authReducer'

export function useAuthCheck() {
  const [isAuthCheckDispatched, setIsAuthCheckDispatched] = useState(false)
  const account = useSelector(({ auth }) => auth.account)
  const { isAuthChecked } = useSelector(({ auth }) => ({
    isAuthChecked: auth.isAuthChecked,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthCheckDispatched && isAuthChecked) {
      dispatch(setIsAuthChecked(false))
    }
  }, [isAuthCheckDispatched, isAuthChecked])

  useEffect(() => {
    if (account && account.id) {
      dispatch(refreshToken(account.id))
      setIsAuthCheckDispatched(true)
    }
  }, [account])

  return isAuthCheckDispatched && isAuthChecked
}
