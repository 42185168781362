import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar, TextField } from '@material-ui/core'
import { Button } from '@components/buttons'
import { TextInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { updateEmail } from '@state/thunks/authThunk'
import { updateEmailSchema } from '@utils/schemas'
import theme from '@utils/theme'
import { Text } from '../texts'
import { RootState } from '@state/store'
import { Alert } from '@mui/material'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type UpdateEmailFormProps = {
  currentEmail?: string
}

type Values = {
  email: string
  newEmail: string
  confirmEmail: string
}

export function UpdateEmailForm({ currentEmail }: UpdateEmailFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isUpdating, setIsUpdating] = useState(false)
  const { message, isError } = useSelector((state: RootState) => state.auth)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<Values>({
    resolver: yupResolver(updateEmailSchema),
    defaultValues: {
      email: currentEmail,
      newEmail: '',
      confirmEmail: '',
    },
  })

  const onSubmit = (values: Values) => {
    if (values.newEmail === values.confirmEmail) {
      dispatch(updateEmail({ email: values.newEmail }))
    } else {
      console.log('Emails do not match.')
    }
    setIsUpdating(false)
    reset()
  }

  const handleUpdatingEmail = () => {
    setIsUpdating(true)
  }

  const handleCancelUpdatingEmail = () => {
    setIsUpdating(false)
    reset()
  }

  const email = watch('email')
  const newEmail = watch('newEmail')
  const confirmEmail = watch('confirmEmail')

  const isFieldEmpty = !email || !newEmail || !confirmEmail

  const doEmailsMatch = newEmail === confirmEmail
  const isEmailValid = !errors.newEmail
  const updateEmailButtonDisabled =
    !isEmailValid || !doEmailsMatch || isFieldEmpty

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon
                className="fa fa-envelope"
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item>
              <Text
                text="email"
                variant={'h6'}
                format
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={6} sm={6}>
            {!isUpdating ? (
              <TextField
                name="email"
                placeholder="Current Email"
                size="small"
                variant="outlined"
                value={currentEmail}
                disabled
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <>
                <TextInput
                  name="newEmail"
                  placeholder="New Email"
                  size="small"
                  variant="outlined"
                  control={control}
                  errors={errors}
                  style={{ marginBottom: '1rem' }}
                />
                <TextInput
                  name="confirmEmail"
                  placeholder="Confirm New Email"
                  size="small"
                  variant="outlined"
                  control={control}
                  errors={errors}
                />
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          {isUpdating && (
            <Grid item>
              <Button
                text="cancel"
                onClick={handleCancelUpdatingEmail}
                format
                color="primary"
              />
            </Grid>
          )}
          <Grid item>
            {isUpdating ? (
              <Button
                text="submit"
                onClick={handleSubmit(onSubmit)}
                format
                color="secondary"
                disabled={updateEmailButtonDisabled}
              />
            ) : (
              <Button
                text="update"
                onClick={handleUpdatingEmail}
                format
                color="secondary"
              />
            )}
          </Grid>
        </Grid>
      </CardActions>
      <Snackbar open={!!message} autoHideDuration={6000}>
        <Alert severity={isError ? 'error' : 'success'}>{message}</Alert>
      </Snackbar>
    </Card>
  )
}
