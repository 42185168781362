import {
  axios,
  Exam,
  ExamAquisitionProtocolDto,
  getConfigs,
  IRequestConfig,
} from './api'

export class ExamService {
  private static basePath = process.env.REACT_APP_API_URL

  static getExamAquisitionProtocol(
    examTypeId: number,
  ): Promise<ExamAquisitionProtocolDto[]> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + `/exam/${examTypeId}/protocol`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )
      const data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static getExamsBySiteId(
    siteIds: number[],
  ): Promise<{ totalCount: number; datas: Exam[] }> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/exam?'
      console.log({ siteIds })
      siteIds.forEach((id) => {
        url + `&siteIds[]=${id}`
      })
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }
}
