import { createRef, memo, useState } from 'react'

import { Text } from '@components/texts'
import { Grid, MenuItem, Select, Tooltip } from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { useIntl } from 'react-intl'
import {
  Document,
  EnumGedFileType,
  MedicalOrderDto,
} from '../../../services/api'
import FileItem from './FileItem'
import { documentTypes, isAllowed } from '../../../utils/helpers'

interface Props {
  accept?: string
  files: Document[]
  onChange: (files: File[], type: EnumGedFileType) => void
  onDeleteFile: (file: Document) => void
  onDownloadFile: (file: Document) => void
  multiple?: boolean
  study: MedicalOrderDto
}

export const FileInput = ({
  accept,
  files,
  onChange,
  onDeleteFile,
  multiple,
  onDownloadFile,
  study,
}: Props) => {
  const inputRef = createRef<HTMLInputElement>()
  const [over, setover] = useState(false)
  const [selectedType, setSelectedType] = useState(EnumGedFileType.PRESCRIPTION)

  const intl = useIntl()

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (event.target.files.length > 1) {
        alert('Max number of file is 1 !')
        return false
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index]
        if (file.size > 4194304) {
          alert('Max file size is 4Mo !')
          return false
        }
        const _isAllowed = isAllowed(file.name, documentTypes)
        if (!_isAllowed) {
          alert('This file type is not allowed')
          return
        }
      }

      onChange(Array.from(event.target.files), selectedType)
    }
  }

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
      <ul className="input-files">
        {!multiple && files.length < 10 && (
          <Grid item container>
            <Grid item xs={12}>
              <Text
                variant="caption"
                text="addDocument"
                style={{ color: 'grey' }}
                format
              />
            </Grid>
            {study.reports.length === 0 && (
              <Grid item container alignItems="center">
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Text variant="body1" text="chooseType" format />
                </Grid>
                <Grid item xs={6} style={{ marginRight: '20px' }}>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={selectedType}
                    onChange={(event) =>
                      setSelectedType(event.target.value as EnumGedFileType)
                    }
                    name="fileType"
                    style={{
                      marginBottom: '10px',
                      height: '35px',
                      backgroundColor: 'white',
                    }}
                  >
                    {Object.values(EnumGedFileType).map((v) => (
                      <MenuItem key={v} value={v}>
                        {intl.formatMessage({ id: 'enums.GedFileType.' + v })}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={2}>
                  <li
                    onDrop={(e: any) => {
                      e.preventDefault()
                      // e.persist()
                      e.target = e.dataTransfer
                      handleFilesChange(e)
                    }}
                    onDragOver={(e) => {
                      e.preventDefault()
                      setover(true)
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault()
                      setover(false)
                    }}
                    onClick={handleClick}
                    className={
                      over
                        ? 'item-base item-input over'
                        : 'item-base item-input'
                    }
                  >
                    <Tooltip title={intl.formatMessage({ id: 'addDocument' })}>
                      <Add />
                    </Tooltip>
                    <input
                      ref={inputRef}
                      type="file"
                      name="myFiles"
                      accept={accept}
                      multiple={multiple}
                      onChange={handleFilesChange}
                    />
                  </li>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {files.map((file, i) => (
          <FileItem
            key={file.id}
            index={i}
            filetype={intl.formatMessage({
              id: 'enums.fileTypeEnum.' + file.fileType,
            })}
            file={file}
            onDelete={onDeleteFile}
            onDownload={onDownloadFile}
          />
        ))}
      </ul>
    </Grid>
  )
}

export const MemoizedFileInput = memo(FileInput)
