import { FormattedMessage, IntlShape } from 'react-intl'
import { MedicalOrderDto } from '../../../services/api'
import MaleIcon from '@mui/icons-material/Male'
import FemaleIcon from '@mui/icons-material/Female'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro'
import moment from 'moment'
import { Icon, Tooltip } from '@material-ui/core'

export const nuclMedicineColumns = (
  intl: IntlShape,
  renderActions: (
    row: GridRenderCellParams<
      MedicalOrderDto,
      any,
      any,
      GridTreeNodeWithRender
    >,
  ) => JSX.Element,
  order?: string[],
  widths?: {
    [key: string]: number
  },
): GridColDef<MedicalOrderDto>[] => {
  const columns = [
    {
      field: 'firstName',
      width: 200,
      headerName: intl.formatMessage({ id: 'firstName' }),
      valueGetter: (params) => {
        return params.row.patient.firstName
      },
    },
    {
      field: 'lastName',
      width: 200,
      headerName: intl.formatMessage({ id: 'lastName' }),
      valueGetter: (params) => {
        return params.row.patient.lastName
      },
    },
    {
      field: 'age',
      width: 80,
      headerName: intl.formatMessage({ id: 'age' }),
      valueGetter: (params) => {
        return (
          new Date().getFullYear() -
          new Date(params.row.patient.birthDate).getFullYear() +
          ' ans'
        )
      },
    },
    {
      field: 'sex',
      width: 75,
      headerName: intl.formatMessage({ id: 'sex' }),
      renderCell: (params) => {
        return params.row.patient.sex === 'M' ? (
          <MaleIcon
            sx={{
              color: '#1976d2',
              fontSize: '2rem',
            }}
          />
        ) : params.row.patient.sex === 'F' ? (
          <FemaleIcon
            sx={{
              color: '#f50057',
              fontSize: '2rem',
            }}
          />
        ) : (
          <QuestionMarkIcon
            sx={{
              color: '#3f3f3f',
              fontSize: '2rem',
            }}
          />
        )
      },
    },
    {
      field: 'status',
      width: 60,
      headerName: intl.formatMessage({ id: 'status' }),
      renderCell: (params) => {
        const getIconData = (status) => {
          switch (status) {
            case 'scheduled':
              return { className: 'fa fa-calendar-day', color: '#3f3f3f' }
            case 'canceled':
            case 'error':
              return { className: 'fa fa-calendar-times', color: '#E91E63' }
            case 'completed':
            case 'available':
              return { className: 'fa fa-calendar-check', color: '#009DA0' }
            default:
              return { className: '', color: '' }
          }
        }

        const { className, color } = getIconData(params.row.status)

        return (
          <Tooltip title={<FormattedMessage id={params.row.status} />}>
            <Icon className={className} style={{ color: color }} />
          </Tooltip>
        )
      },
      sortComparator: (v1, v2) => {
        return (
          new Date(v1.plannedDate).getTime() -
          new Date(v2.plannedDate).getTime()
        )
      },
    },
    {
      field: 'plannedDate',
      width: 200,
      headerName: intl.formatMessage({ id: 'date' }),
      valueGetter: (params) => {
        return `${moment(params.row.plannedDate).format(
          'DD/MM/YYYY',
        )} - ${moment(params.row.plannedDate).format('HH:mm')}`
      },
      sortComparator: (v1, v2) => {
        return (
          new Date(v1.plannedDate).getTime() -
          new Date(v2.plannedDate).getTime()
        )
      },
    },

    {
      field: 'site',
      width: 180,
      headerName: intl.formatMessage({ id: 'site' }),
      valueGetter: (params) => {
        return params.row.site.label
      },
    },
    {
      field: 'description',
      width: 400,
      headerName: intl.formatMessage({ id: 'description' }),
      valueGetter: (params) => {
        const label = params.row.exam.label
        return label
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      },
    },
    {
      field: 'Actions',
      width: 250,
      renderCell: renderActions,
    },
  ]
  let sortedColumns: any[] = []

  if (order) {
    order.forEach((o) => {
      const foundCol = columns.find((c) => c.field === o)
      if (foundCol) {
        sortedColumns.push(foundCol)
      }
    })
  } else {
    sortedColumns = columns
  }
  if (widths && Object.keys(widths).length > 0) {
    sortedColumns = sortedColumns.map((c) => {
      return {
        ...c,
        width: widths[c?.field],
      }
    })
  }
  return sortedColumns
}
export const telemedTableColumns = (
  intl: IntlShape,
  renderActions: (
    row: GridRenderCellParams<
      MedicalOrderDto,
      any,
      any,
      GridTreeNodeWithRender
    >,
  ) => JSX.Element,
  order?: string[],
  widths?: {
    [key: string]: number
  },
): GridColDef<MedicalOrderDto>[] => {
  const columns = [
    {
      field: 'firstName',
      width: 150,
      headerName: intl.formatMessage({ id: 'firstName' }),
      valueGetter: (params) => {
        return params.row.patient.firstName
      },
    },
    {
      field: 'lastName',
      width: 150,
      headerName: intl.formatMessage({ id: 'lastName' }),
      valueGetter: (params) => {
        return params.row.patient.lastName
      },
    },
    {
      field: 'age',
      width: 80,
      headerName: intl.formatMessage({ id: 'age' }),
      valueGetter: (params) => {
        return (
          new Date().getFullYear() -
          new Date(params.row.patient.birthDate).getFullYear() +
          ' ans'
        )
      },
    },
    {
      field: 'sex',
      width: 75,
      headerName: intl.formatMessage({ id: 'sex' }),
      renderCell: (params) => {
        return params.row.patient.sex === 'M' ? (
          <MaleIcon
            sx={{
              color: '#1976d2',
              fontSize: '2rem',
            }}
          />
        ) : params.row.patient.sex === 'F' ? (
          <FemaleIcon
            sx={{
              color: '#f50057',
              fontSize: '2rem',
            }}
          />
        ) : (
          <QuestionMarkIcon
            sx={{
              color: '#3f3f3f',
              fontSize: '2rem',
            }}
          />
        )
      },
    },
    // {
    //   field: 'status',
    //   width: 60,
    //   headerName: intl.formatMessage({ id: 'status' }),
    //   renderCell: (params) => {
    //     const getIconData = (status) => {
    //       switch (status) {
    //         case 'scheduled':
    //           return { className: 'fa fa-calendar-day', color: '#3f3f3f' }
    //         case 'canceled':
    //         case 'error':
    //           return { className: 'fa fa-calendar-times', color: '#E91E63' }
    //         case 'completed':
    //         case 'available':
    //           return { className: 'fa fa-calendar-check', color: '#009DA0' }
    //         default:
    //           return { className: '', color: '' }
    //       }
    //     }

    //     const { className, color } = getIconData(params.row.status)

    //     return (
    //       <Tooltip title={<FormattedMessage id={params.row.status} />}>
    //         <Icon className={className} style={{ color: color }} />
    //       </Tooltip>
    //     )
    //   },
    //   sortComparator: (v1, v2) => {
    //     return (
    //       new Date(v1.plannedDate).getTime() -
    //       new Date(v2.plannedDate).getTime()
    //     )
    //   },
    // },
    {
      field: 'plannedDate',
      width: 150,
      headerName: intl.formatMessage({ id: 'date' }),
      valueGetter: (params) => {
        return `${moment(params.row.plannedDate).format(
          'DD/MM/YYYY',
        )} - ${moment(params.row.plannedDate).format('HH:mm')}`
      },
      sortComparator: (v1, v2) => {
        return (
          new Date(v1.plannedDate).getTime() -
          new Date(v2.plannedDate).getTime()
        )
      },
    },

    {
      field: 'site',
      width: 180,
      headerName: intl.formatMessage({ id: 'site' }),
      valueGetter: (params) => {
        return params.row.site.label
      },
    },
    {
      field: 'description',
      width: 300,
      headerName: intl.formatMessage({ id: 'description' }),
      valueGetter: (params) => {
        const label = params.row.exam.label
        return label
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      },
    },

    {
      field: 'confirmedNmDose',
      width: 200,
      headerName: intl.formatMessage({ id: 'injectedDose' }),
    },
    {
      field: 'Actions',
      width: 450,
      renderCell: renderActions,
    },
  ]
  let sortedColumns: any[] = []

  if (order) {
    order.forEach((o) => {
      const foundCol = columns.find((c) => c.field === o)
      if (foundCol) {
        sortedColumns.push(foundCol)
      }
    })
  } else {
    sortedColumns = columns
  }
  if (widths && Object.keys(widths).length > 0) {
    sortedColumns = sortedColumns.map((c) => {
      return {
        ...c,
        width: widths[c?.field],
      }
    })
  }
  return sortedColumns
}

export type MedicalOrderTableColumnsFunc = typeof telemedTableColumns
