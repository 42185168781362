import {
  axios,
  CreateParagraphDto,
  CreateReportDto,
  CreateReportTemplateDto,
  FindReportTemplatesQuery,
  getConfigs,
  IRequestConfig,
  Report,
  ReportParagraphDto,
  ReportTemplateDto,
  UpdateParagraphDto,
  UpdateReportTemplateDto,
} from './api'

export class ReportingService {
  private static basePath = process.env.REACT_APP_API_URL

  static findAll(
    query: FindReportTemplatesQuery,
  ): Promise<ReportTemplateDto[]> {
    return new Promise((resolve, reject) => {
      const url =
        this.basePath +
        `/reporting?${
          query.examIds?.map((ex) => `examIds[]=${ex}`).join('&') ?? ''
        }&createdById=${query.createdById}`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreateReportTemplateDto): Promise<ReportTemplateDto> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/reporting'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        {},
      )

      configs.data = dto

      axios(configs, resolve, reject)
    })
  }

  static update(
    id: number,
    dto: UpdateReportTemplateDto,
  ): Promise<ReportTemplateDto> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/reporting/' + id

      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        url,
        {},
      )

      configs.data = dto

      axios(configs, resolve, reject)
    })
  }
  static delete(id: number): Promise<ReportTemplateDto> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/reporting/' + id

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static createReport(dto: CreateReportDto): Promise<Report> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/reporting/report'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        {},
      )

      configs.data = dto

      axios(configs, resolve, reject)
    })
  }
  static updateReport(id: number, dto: CreateReportDto): Promise<Report> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/reporting/report/' + id

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        {},
      )

      configs.data = dto

      axios(configs, resolve, reject)
    })
  }

  static findParagraphs(templateId: number): Promise<ReportParagraphDto[]> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + `/reporting/${templateId}/paragraphs`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static createParagraph(dto: CreateParagraphDto): Promise<ReportParagraphDto> {
    const url = this.basePath + '/reporting/paragraphs'

    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }
  static updateParagraph(
    id: number,
    dto: UpdateParagraphDto,
  ): Promise<ReportParagraphDto> {
    const url = this.basePath + '/reporting/paragraphs/' + id

    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }
  static deleteParagraph(id: number): Promise<ReportParagraphDto> {
    const url = this.basePath + '/reporting/paragraphs/' + id

    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        {},
      )
      configs.data = null

      axios(configs, resolve, reject)
    })
  }
}
