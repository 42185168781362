import NucMedicineTable from '../../../components/tables/nuclear-medicine-table/NucMedicineTable'

const HomeTelemedUser = () => {
  return (
    <div
      style={{
        width: 'calc(100vw - 30px)',
      }}
    >
      <NucMedicineTable />
    </div>
  )
}

export default HomeTelemedUser
