import {
  CircularProgress,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { MedicalOrderDto } from '@services/api'
import { memo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import StorageService from '../../services/storage'
import theme from '../../utils/theme'

const useStyles = makeStyles((theme) => ({
  div: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: 250,
  },
  iconBtn: {
    padding: 0,
  },
  icon: {
    marginRight: '1.5rem',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
}))

type ReportTooltipProps = {
  study: MedicalOrderDto
}

const ReportTooltip = ({ study }: ReportTooltipProps) => {
  const intl = useIntl()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [loading] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  //   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  //     setAnchorEl(event.currentTarget)
  //   }
  //   const handlePopoverClose = () => {
  //     setAnchorEl(null)
  //   }
  const iconId = 'icon-' + study.id
  const popoverId = 'popover-' + study.id
  const open = Boolean(anchorEl)

  const _handleReportClick = (report: any) => {
    const d = new Date('2023-02-17T14:00:34.059Z')
    const basePath =
      new Date(report.createdAt) > d
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_STATIC_FILES_URL
    const token = StorageService.getAuthToken()
    const link = document.createElement('a')
    const url = `${report.ged_file.filePath}/${report.ged_file.filename}?token=${token}`
    link.href = `${basePath}/static/${url}`
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    handleClose()
  }
  const _isDisabled = study.reports.length === 0 && !study.resultConclusion
  return (
    <span>
      <Tooltip
        title={intl.formatMessage({ id: 'report' })}
        aria-label={intl.formatMessage({ id: 'report' })}
      >
        <IconButton
          size="small"
          color={_isDisabled ? 'primary' : undefined}
          id={iconId}
          aria-label={intl.formatMessage({ id: 'report' })}
          aria-describedby={popoverId}
          className={classes.iconBtn}
          onClick={handleClick}
          disabled={_isDisabled}
        >
          {!loading ? (
            <Icon
              id={popoverId}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              fontSize="small"
              className="fa fa-file-pdf"
              color={_isDisabled ? 'disabled' : 'primary'}
            />
          ) : (
            <CircularProgress size={20} />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container style={{ maxWidth: '40vw' }}>
          {study.reports.length !== 0 && (
            <Grid item xs={12}>
              <List
                style={{
                  padding: '0px',
                }}
              >
                {(study.reports as any).map((report, index) => (
                  <ListItem
                    key={index}
                    onClick={() => _handleReportClick(report)}
                    style={{ cursor: 'pointer' }}
                  >
                    <ListItemIcon>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                      >
                        <Icon
                          className="fas fa-file-alt"
                          style={{
                            fontSize: '1.2rem',
                            marginRight: '5px',
                            color: '#E91E63',
                          }}
                        />
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            color: '#E91E63',
                          }}
                        >
                          <FormattedMessage
                            id="seeReport"
                            defaultMessage="Voir le compte rendu"
                          />
                        </p>
                      </div>
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <span
                          style={{
                            color: 'gray',
                            fontSize: '0.8rem',
                          }}
                        >
                          ({report.ged_file?.filename})
                        </span>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
          <Grid item xs={12}>
            {study.resultConclusion && (
              <>
                <Divider />
                <Grid style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
                  <h3
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: '1rem',
                    }}
                  >
                    CONCLUSION:
                  </h3>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      marginTop: '-12px',
                    }}
                    variant="body1"
                  >
                    {study.resultConclusion}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Popover>
    </span>
    // </Tooltip>
  )
}

export const ReportTooltipMemo = memo(ReportTooltip)
