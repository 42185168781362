import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { AccountDto } from '@services/api'
import { useSelector } from '@state/store'
import moment from 'moment'
import 'moment/locale/fr'
import { useIntl } from 'react-intl'
import { Text } from '../texts'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
  tableContent: {
    padding: 5,
    overflow: 'auto',
  },
}))

type LastConnexionProps = {
  account?: AccountDto
}

export function LastConnexionForm({ account }: LastConnexionProps) {
  const classes = useStyles()
  const intl = useIntl()
  const devices = account?.devices
  const { language } = useSelector(({ auth }) => ({
    language: auth.language,
  }))
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fas fa-link" />
            </Grid>
            <Grid item>
              <Text text="Sécurité du compte" variant={'h6'} format />
              <Text text="10 dernières connexions" format />
            </Grid>
          </Grid>
        }
      />

      <Divider />
      <CardContent style={{ background: '#fff' }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({
                    id: 'date',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'location',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'deviceOS',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'browser',
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices && devices.length === 0 && (
                <TableRow className={classes.tableContent}>
                  <Text
                    text="noConnectionHistory"
                    format
                    className={classes.tableContent}
                  />
                </TableRow>
              )}
              {devices
                ? devices?.map((connexion, index) => {
                  const dateConnexion =
                      moment(connexion.lastLoggedIn)
                        .locale(language === 'fr' ? 'fr' : 'en')
                        .format('dddd') +
                      ' ' +
                      moment(connexion.lastLoggedIn)
                        .locale(language === 'fr' ? 'fr' : 'en')
                        .format('L')
                  const geoloc = connexion.location.split('/')
                  const deviceDetails = connexion.details.trim().split(' ')

                  return (
                    <TableRow key={index} className={classes.tableContent}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {dateConnexion}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {geoloc.map((el, index) => {
                          const geolocSplit =
                              index !== geoloc.length - 1
                                ? el + ' - '
                                : el === 'undefined'
                                  ? intl.formatMessage({ id: 'undefined' })
                                  : el
                          return geolocSplit
                        })}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {deviceDetails[1]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {deviceDetails[0]}
                      </TableCell>
                    </TableRow>
                  )
                })
                : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
