import { memo } from 'react'

import { Button, Grid, Tooltip } from '@material-ui/core'
import { Delete, Visibility } from '@material-ui/icons'

import { Text } from '@components/texts'
import { Document, UploadSourceEnum } from '../../../services/api'
import { EtherFile } from '@services/extendedType'

interface Props {
  index: number
  file: Document
  filetype: string
  onDelete: (item: any) => void
  onDownload: (item: any) => void
  onChangeStatus?: (item: EtherFile) => void
}

const FileItem = ({ file, filetype, onDelete, onDownload }: Props) => {
  const handleDelete = () => {
    onDelete && onDelete(file)
  }
  const handleDownload = () => {
    onDownload && onDownload(file)
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={8}>
        <Text text={filetype} format />
        <Text
          text={file.gedFile.filename}
          style={{ fontSize: '12px', color: 'grey' }}
          format
        />
      </Grid>
      <Grid item xs={4}>
        <Tooltip title="Download">
          <Button
            onClick={handleDownload}
            size="small"
            color="primary"
            style={{ marginRight: '-20px' }}
          >
            <Visibility fontSize="small" />
          </Button>
        </Tooltip>
        {file.gedFile.uploadSource === UploadSourceEnum.PORTAL && (
          <Tooltip title="Delete">
            <Button onClick={handleDelete} size="small" color="secondary">
              <Delete fontSize="small" />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}

export default memo(FileItem)
