import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { Text } from '../texts'
import { AccountDto, AccountRoleEnum } from '@services/api'
import { Close } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { useState } from 'react'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  dialogTitle: {
    background: '#a1c5c5',
    color: '#fff',
  },
  dialogContent: {
    width: 500,
  },
  searchTitle: {
    marginTop: 10,
  },
  searchTips: {
    color: 'grey',
    fontSize: '12px',
  },
  sharingTitle: {
    marginBottom: 10,
  },
  table: {
    marginBottom: 10,
  },
  tableContent: {
    padding: 5,
    overflow: 'auto',
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '550px',
  },
}))

type BlackListedFormProps = {
  account?: AccountDto
}

const blackListedMedecinConst = [
  {
    rppsNumber: '001',
    firstName: 'Bruno',
    lastName: 'leLegiste',
    title: 'Dr',
    profession: 'Legiste',
    ville: 'Antony',
    CP: ['92160', '92160', '92160', '92160', '92160', '92160', '92160'],
    banDate: '02/02/2022',
    banTime: '16:32',
  },
  {
    rppsNumber: '002',
    firstName: 'Jeanne',
    lastName: 'laSageFemme',
    title: 'Dr',
    profession: 'SageFemme',
    ville: 'Antony',
    CP: ['92160', '92160', '92160', '92160', '92160'],
    banDate: '31/01/2022',
    banTime: '10:00',
  },
  {
    rppsNumber: '003',
    firstName: 'George',
    lastName: 'leGérénaliste',
    title: 'Dr',
    profession: 'Généraliste',
    ville: 'Marseille',
    CP: ['13001'],
    banDate: '22/12/2021',
    banTime: '02:55',
  },
  {
    rppsNumber: '004',
    firstName: 'Marina',
    lastName: 'laRadiologue',
    title: 'Dr',
    profession: 'Radiologue',
    ville: 'Paris',
    CP: ['00000', '00000', '00000', '00000', '00000', '00000', '00000'],
    banDate: '23/11/2021',
    banTime: '08:01',
  },
]

export function BlackListedForm({ account }: BlackListedFormProps) {
  const classes = useStyles()
  const intl = useIntl()
  const [blackListedMedecin, setBlackListedMedecin] = useState(
    blackListedMedecinConst,
  )

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fas fa-list-ul" />
            </Grid>
            <Grid item>
              <Text text="Praticiens bloqués" variant={'h6'} format />
            </Grid>
          </Grid>
        }
      />

      <Divider />
      <CardContent>
        {account?.role === AccountRoleEnum.patient && (
          <>
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {`${intl.formatMessage({
                        id: 'firstName',
                      })} ${intl.formatMessage({ id: 'lastName' })}`}
                    </TableCell>
                    <TableCell>{intl.formatMessage({ id: 'job' })}</TableCell>
                    <TableCell>{intl.formatMessage({ id: 'city' })}</TableCell>
                    <TableCell>{intl.formatMessage({ id: 'date' })}</TableCell>
                    <TableCell align="right">X</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blackListedMedecin.length === 0 && (
                    <TableRow className={classes.tableContent}>
                      <Text
                        text="noSharing" //changer la translation et dire que pas de médecin bloqué
                        format
                        className={classes.tableContent}
                      />
                    </TableRow>
                  )}
                  {blackListedMedecin.map((medecin, index) => {
                    const unBlackMedecin = (rpps: string) => {
                      const blackListMedecinTemp = [...blackListedMedecin]

                      blackListMedecinTemp.splice(
                        blackListMedecinTemp.findIndex(
                          (el) => el.rppsNumber == rpps,
                        ),
                        1,
                      )

                      setBlackListedMedecin(blackListMedecinTemp)
                    }
                    return (
                      <TableRow key={index} className={classes.tableContent}>
                        <TableCell component="th" scope="row">
                          {`${medecin.firstName} ${medecin.lastName}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`${medecin.profession}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {medecin.CP.map((el, index) => {
                            const cpMedecin =
                              index !== medecin.CP.length - 1 ? el + ', ' : el
                            return cpMedecin
                          })}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {medecin.banDate}
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            onClick={() => unBlackMedecin(medecin.rppsNumber)}
                          >
                            <Close />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </CardContent>
    </Card>
  )
}
