import React from 'react'
import { MedicalOrderDto, ReportTemplateDto } from '../../services/api'
import {
  Box,
  Button,
  Divider,
  Fade,
  Icon,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import { deleteReportTemplate } from '../../state/thunks/reportingThunk'
import moment from 'moment'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { makeStyles } from '@mui/styles'
import { useIntl } from 'react-intl'
import ReportPreview from './ReportPreview'
import UnparsedReportPreview from './UnparsedReportPreview'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'

interface Props {
  template: ReportTemplateDto
  onUseTemplateClick: (template: ReportTemplateDto) => void
  study?: MedicalOrderDto
  hideActions?: boolean
}

const useStyle = makeStyles(() => ({
  item: {
    padding: '0 8px',
    borderRadius: 8,
    margin: '4px 0',
    border: '1px solid #CCC',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const ReportTemplatePreview: React.FC<Props> = ({
  template,
  onUseTemplateClick,
  study,
  hideActions,
}) => {
  const classes = useStyle()
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = React.useState(false)

  const intl = useIntl()

  const client = useAppSelector((state) => state.study.client)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleDeleteClick = () => {
    const confirmed = confirm('Voulez-vous supprimer ce modèle ?')
    if (confirmed) {
      dispatch(deleteReportTemplate(template.id))
    }
  }

  return (
    <Box
      sx={{
        padding: '8px',
        margin: '4px 0',
        border: '1px solid #CCC',
        backgroundColor: 'white',
        borderRadius: '10px',
        marginBottom: '8px',
      }}
    >
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="left"
        transition
        style={{
          zIndex: 11111111,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <Box style={{}}>
                <IconButton
                  style={{ position: 'absolute', right: 0, zIndex: 111 }}
                  onClick={() => setOpen(false)}
                >
                  <Icon className="fas fa-times" color="error" />
                </IconButton>
                <Button
                  variant="contained"
                  onClick={() => {
                    onUseTemplateClick(template)
                    setOpen(false)
                  }}
                >
                  Appliquer
                </Button>
              </Box>
              <div>
                <Paper
                  style={{
                    width: 595,
                    height: 842,
                    overflow: 'scroll',
                  }}
                >
                  {client &&
                    (study ? (
                      <ReportPreview
                        reportHtml={template}
                        study={study}
                        client={client}
                      />
                    ) : (
                      <UnparsedReportPreview
                        reportHtml={template}
                        client={client}
                      />
                    ))}
                </Paper>
              </div>
            </div>
          </Fade>
        )}
      </Popper>

      <div
        style={{
          width: '100%',
          padding: '0 4px',
          borderRadius: 8,
          margin: '4px 0',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            width: '100%',
          }}
        >
          <div className={classes.header}>
            <Typography fontWeight="bold" variant="h6">
              {template.name}
            </Typography>
            {hideActions !== true && (
              <div>
                <Button
                  style={{
                    fontSize: '12px',
                    backgroundColor: '#ECFBF3',
                    color: 'black',
                    border: '1px solid #1EBC86',
                    textTransform: 'capitalize',
                    padding: '2px 8px',
                    borderRadius: 8,
                  }}
                  onClick={handleClick}
                >
                  <i className="fas fa-eye" style={{ marginRight: 2 }}></i>
                  Aperçu
                </Button>
                <Button
                  style={{
                    marginLeft: 4,
                    fontSize: '12px',
                    backgroundColor: '#FEF1F2',
                    color: 'black',
                    border: '1px solid #FECACA',
                    textTransform: 'capitalize',
                    padding: '2px 8px',
                    borderRadius: 8,
                  }}
                  onClick={handleDeleteClick}
                >
                  <i
                    style={{ marginRight: 2 }}
                    className="far fa-trash-alt"
                  ></i>
                  Supprimer
                </Button>
              </div>
            )}
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CalendarTodayIcon
                style={{
                  color: '#6A6A6A',
                  fontSize: '14px',
                  marginRight: '2px',
                }}
              />
              <Typography fontSize="12px" variant="body2">
                {moment(template.createdAt).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: '4px',
              }}
            >
              <PersonOutlineIcon
                style={{
                  color: '#6A6A6A',
                  fontSize: '18px',
                  marginRight: '2px',
                }}
              />
              <Typography fontSize="12px" variant="body2">
                {template.createdBy.member.firstName}{' '}
                {template.createdBy.member.lastName}
              </Typography>
            </div>
          </Box>
        </Box>
      </div>
      <Divider />
      <div className={classes.itemRow}>
        <div className={classes.item}>
          {intl.formatMessage({ id: 'enums.modality.' + template.modality })}
        </div>
      </div>
    </Box>
  )
}

export default ReportTemplatePreview
