import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ParagraphEditor from './ParagraphEditor'
import ReportingDataPanel from './ReportingDataPanel'

const ReportParagraphs = () => {
  const [id, setId] = useState(-1)
  const t = useParams()
  useEffect(() => {
    if (t.id === undefined) return

    const _id = parseInt(t.id, 10)
    setId(_id)
  }, [t.id])

  return (
    <Grid
      container
      sx={{
        marginTop: '80px',
        backgroundColor: 'white',
        flex: 1,
      }}
    >
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            borderRight: '1px solid #e0e0e0',
            padding: '4px',
          }}
        >
          <ReportingDataPanel />
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        {id !== -1 && <ParagraphEditor templateId={id} />}
      </Grid>
    </Grid>
  )
}

export default ReportParagraphs
