import { Button } from '@components/buttons'
import { RppsAutocompleteByNameInput } from '@components/inputs/RppsAutocompleteByNameInput'
import { CheckBoxInput, SelectInput, TextInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, makeStyles } from '@material-ui/core'
import {
  SavoirFaireDto,
  SharingExpirationEnum,
  SharingPermissionEnum,
} from '@services/api'
import { AppDispatch, useSelector } from '@state/store'
import { shareWithGuest } from '@state/thunks/studyThunk'
import { shareExamSchema } from '@utils/schemas'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Text } from '../texts'
import { getDoctorByRpps } from '../../state/thunks/doctorThunk'
import { setDoctorByRpps } from '../../state/reducers/doctorReducer'

type Values = {
  firstName: string
  lastName: string
  email: string
  expiration: SharingExpirationEnum
  permission: SharingPermissionEnum
  doctor?: SavoirFaireDto
}

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
  searchTitle: {
    marginTop: -10,
    marginBottom: -15,
  },
  searchTips: {
    color: 'darkgrey',
    fontSize: '14px',
  },
}))

type AddSharingFormProps = {
  medicalOrderId: number
  setOpen: (open: boolean) => void
}

export function ShareWithEmailForm({
  medicalOrderId,
  setOpen,
}: AddSharingFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const intl = useIntl()

  const [doctorNotFound, setDoctorNotFound] = useState(false)

  const { loading, doctorByRpps } = useSelector(
    ({ loading, auth, doctor }) => ({
      loading: loading['study/share'],
      account: auth?.account,
      doctorByRpps: doctor.doctorByRpps,
    }),
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Values>({
    resolver: yupResolver(shareExamSchema) as any,
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      expiration: SharingExpirationEnum.ONE_YEAR,
      permission: SharingPermissionEnum.VIEW,
    },
  })

  const onSubmit = async (values: Values) => {
    try {
      await dispatch(
        shareWithGuest({
          rppsNumber: values.doctor?.rppsNumber,
          email: values.email,
          medicalOrderId,
          expiration: values.expiration,
          permission: values.permission,
        }),
      )
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  const studySharingPermissions = Object.keys(SharingPermissionEnum).map(
    (key) => ({
      value: SharingPermissionEnum[key],
      label: intl.formatMessage({
        id: 'enums.sharingPermission.' + SharingPermissionEnum[key],
      }),
    }),
  )
  const studySharingExpirations = Object.keys(SharingExpirationEnum).map(
    (key) => ({
      value: SharingExpirationEnum[key],
      label: intl.formatMessage({
        id: 'enums.sharingExpiration.' + SharingExpirationEnum[key],
      }),
    }),
  )
  const handleChange = () => {
    setDoctorNotFound(!doctorNotFound)
    if (doctor) {
      setValue('doctor', undefined)
    }
  }
  const doctor = watch('doctor')

  useEffect(() => {
    if (doctor) {
      dispatch(getDoctorByRpps(doctor.rppsNumber))
    } else {
      dispatch(setDoctorByRpps(null))
    }
  }, [doctor])

  useEffect(() => {
    if (doctorByRpps) {
      setValue('email', doctorByRpps?.account?.authMethodLocalEmail || '')
    } else {
      setValue('email', '')
    }
  }, [doctorByRpps])

  return (
    <Grid container spacing={3} className={classes.container}>
      {!doctorNotFound && (
        <>
          <Grid item xs={12}>
            <Text
              variant="h6"
              text="searchDoctorFromRpps"
              className={classes.searchTitle}
              format
            />
          </Grid>
          <Grid item xs={12}>
            <Text text="rppsTips" className={classes.searchTips} format />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <RppsAutocompleteByNameInput
              control={control}
              errors={errors}
              name="doctor"
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12}>
            {doctorByRpps?.account?.authMethodLocalEmail ? (
              <Grid item xs={12}>
                <Text
                  text="hasAnAccountTips"
                  style={{
                    color: 'orange',
                    fontSize: '16px',
                  }}
                  format
                />
              </Grid>
            ) : (
              <TextInput
                name="email"
                variant={'outlined'}
                size="small"
                control={control}
                errors={errors}
              />
            )}
          </Grid>
        </>
      )}
      {doctorNotFound && (
        <>
          <Grid item xs={12}>
            <Text text="guestTips" className={classes.searchTips} format />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="email"
              variant={'outlined'}
              size="small"
              control={control}
              errors={errors}
            />
          </Grid>
        </>
      )}
      {!doctorByRpps && (
        <Grid item xs={12}>
          <CheckBoxInput
            label={intl.formatMessage({ id: 'cantFindDoctor' })}
            name={intl.formatMessage({ id: 'cantFindDoctor' })}
            control={control}
            onChange={handleChange}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <SelectInput
          options={studySharingPermissions}
          control={control}
          name="permission"
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          options={studySharingExpirations}
          control={control}
          name="expiration"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          text="submit"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          color="secondary"
          format
        />
      </Grid>
    </Grid>
  )
}
