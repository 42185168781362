import {
  Chip,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { DialogTitle } from '@components/titles'
import { Close } from '@material-ui/icons'
import { ShareWithEmailForm } from '../forms/ShareWithEmailForm'

import {
  AccountRoleEnum,
  MedicalOrderDto,
  Shares,
  SharingExpirationEnum,
} from '@services/api'
import { unshare } from '@state/thunks/studyThunk'
import theme from '@utils/theme'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { Text } from '../texts'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
  dialogContent: {
    width: 500,
    padding: 20,
  },
  searchTitle: {
    marginTop: 10,
  },
  searchTips: {
    color: 'grey',
    fontSize: '12px',
  },
  sharingTitle: {
    marginBottom: 10,
  },
  table: {
    marginBottom: 10,
    overflow: 'auto',
    maxHeight: 240,
  },
  tableContent: {
    padding: 5,
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '550px',
  },
}))

type ReportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}

export function ShareStudyDialog({ open, setOpen, study }: ReportDialogProps) {
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()

  const { shares, account } = useSelector(({ study, auth }) => ({
    shares: study.selected?.shares as Shares[],
    account: auth.account,
    // loading: loading['study/unshare'] || loading['study/share'],
  }))

  const handleUnshare = (id: number) => {
    dispatch(unshare(id))
  }

  const onClose = () => setOpen(false)

  const getFormatedDate = (
    startDate: Date,
    expiration: SharingExpirationEnum,
  ) => {
    let expirationDate = new Date()
    switch (expiration) {
      case SharingExpirationEnum.ONE_YEAR:
        expirationDate = moment(startDate).add(6, 'months').toDate()
        break
      case SharingExpirationEnum.NO_EXPIRATION:
      default:
        return intl.formatMessage({
          id: 'enums.sharingExpiration.' + 'NO_EXPIRATION',
        })
    }
    if (expirationDate.getTime() < Date.now()) return 'Expired'
    else return moment(expirationDate).format('DD/MM/YYYY')
  }
  const capitalize = (name: string) =>
    name.charAt(0).toUpperCase() + name.slice(1)

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xl"
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={
          account?.role === AccountRoleEnum.patient
            ? 'sharing'
            : 'shareWithEmail'
        }
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
          filter: ' drop-shadow(0px 0px 2px #00000070)',
        }}
        format
      />
      <DialogContent className={classes.dialogContent}>
        <ShareWithEmailForm medicalOrderId={study.id} setOpen={setOpen} />

        {account?.role === AccountRoleEnum.patient && (
          <>
            <Divider className={classes.divider} />

            <Text
              variant="h6"
              text="activeSharing"
              className={classes.sharingTitle}
              format
            />
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {intl.formatMessage({ id: 'recipient' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'permission' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'expiration' })}
                    </TableCell>
                    <TableCell align="right">
                      {intl.formatMessage({ id: 'remove' })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shares.length === 0 && (
                    <TableRow className={classes.tableContent}>
                      <Text
                        text="noSharing"
                        format
                        className={classes.tableContent}
                      />
                    </TableRow>
                  )}
                  {shares.map((share, index) => {
                    const account: any = share.account
                    let title = ''
                    const user =
                      account.doctor || account.patient || account.guest
                    if (account.role === AccountRoleEnum.guest && !user) {
                      title = 'Non consulté'
                    } else {
                      title =
                        capitalize(user?.firstName) +
                        ' ' +
                        capitalize(user?.lastName)
                    }
                    return (
                      <TableRow key={index} className={classes.tableContent}>
                        <TableCell component="th" scope="row">
                          {account.doctor && 'Dr'} {title}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={intl.formatMessage({
                              id: 'enums.sharingPermission.' + share.permission,
                            })}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {getFormatedDate(share.startDate, share.expiration)}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => handleUnshare(share.id)}
                            disabled={share.createdBy === 'hl7' ? true : false}
                          >
                            <Close />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
