import React from 'react'
import { Exam, Patient } from '../../services/api'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import moment from 'moment'

interface Props {
  patient: Patient
  exam: Exam
}
const PatientSummary: React.FC<Props> = ({ patient, exam }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Prénom: {patient.firstName}</TableCell>
            <TableCell>Nom: {patient.lastName}</TableCell>
            <TableCell>
              Age: {moment().diff(patient.birthDate, 'years')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Sexe: {patient.sex === 'M' ? 'Masculin' : 'Féminin'}
            </TableCell>
            <TableCell>Adresse: {patient.address}</TableCell>
            <TableCell>
              Téléphone: {patient.mobileNumber || patient.phoneNumber}
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SSN: {patient.ssn}</TableCell>
            <TableCell>Examen: {exam.label}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PatientSummary
