import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CreateParagraphDto,
  CreateReportDto,
  CreateReportTemplateDto,
  FindReportTemplatesQuery,
  UpdateParagraphDto,
  UpdateReportTemplateDto,
} from '../../services/api'
import { ReportingService } from '../../services/reporting.service'

export const createReportTemplate = createAsyncThunk(
  'reporting/createReportTemplate',
  async (dto: CreateReportTemplateDto) => {
    return await ReportingService.create(dto)
  },
)

export const findAllReportTemplates = createAsyncThunk(
  'reporting/findAllReportTemplates',
  async (query: FindReportTemplatesQuery) => {
    return await ReportingService.findAll(query)
  },
)

export const updateReportTemplate = createAsyncThunk(
  'reporting/updateReportTemplate',
  async (data: { id: number; dto: UpdateReportTemplateDto }) => {
    return await ReportingService.update(data.id, data.dto)
  },
)

export const deleteReportTemplate = createAsyncThunk(
  'reporting/deleteReportTemplate',
  async (id: number) => {
    return await ReportingService.delete(id)
  },
)

export const createReport = createAsyncThunk(
  'reporting/createReport',
  async (data: { dto: CreateReportDto }) => {
    return await ReportingService.createReport(data.dto)
  },
)
export const updateReport = createAsyncThunk(
  'reporting/updateReport',
  async (data: { id: number; dto: CreateReportDto }) => {
    return await ReportingService.updateReport(data.id, data.dto)
  },
)

export const findParagraphs = createAsyncThunk(
  'reporting/findParagraphs',
  async (templateId: number) => {
    return await ReportingService.findParagraphs(templateId)
  },
)

export const createParagraph = createAsyncThunk(
  'reporting/createParagraph',
  async (data: CreateParagraphDto) => {
    return await ReportingService.createParagraph(data)
  },
)
export const updateParagraph = createAsyncThunk(
  'reporting/updateParagraph',
  async ({ id, data }: { id: number; data: UpdateParagraphDto }) => {
    return await ReportingService.updateParagraph(id, data)
  },
)

export const deleteParagraph = createAsyncThunk(
  'reporting/deleteParagraph',
  async (id: number) => {
    return await ReportingService.deleteParagraph(id)
  },
)
