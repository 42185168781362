import React, { useEffect, useState } from 'react'
import { Client, MedicalOrderDto } from '../../services/api'
import { parseReport } from '../../utils/reporting-utils'
import { capitalize } from 'lodash'
import moment from 'moment'
import { ReportHtml } from './ReportingEditor'
import { toDataUrl } from '../../utils/helpers'

interface ReportPreviewProps {
  reportHtml: ReportHtml
  study: MedicalOrderDto
  client: Client | undefined
}
const ReportPreview: React.FC<ReportPreviewProps> = ({
  reportHtml,
  study,
  client,
}) => {
  const [logo, setLogo] = useState<React.ReactElement>(<></>)

  const getValue = (key: string) => {
    const values = {
      patientFirstName: capitalize(study.patient.firstName),
      patientLastName: capitalize(study.patient.lastName),
      patientBirthDate: moment(study.patient.birthDate).format('DD/MM/YYYY'),
      patientEmail: study.patient.email,
      patientPhone: study.patient.phoneNumber,
      patientAddress: study.patient.address,
      attendingDoctorFirstName: capitalize(study.attendingDoctor?.firstName),
      attendingDoctorLastName: capitalize(study.attendingDoctor?.lastName),
      referrerFirstName: study.referringDoctor?.firstName,
      referrerLastName: study.referringDoctor?.lastName,
      examCode: study.exam.code,
      examDate: moment(study.plannedDate).format('DD/MM/YYYY'),
      examLabel: study.exam.label,
    }
    return values[key]
  }

  useEffect(() => {
    toDataUrl(client?.logo || '').then((b64) => {
      setLogo(
        <img
          src={b64 as string}
          style={{
            width: 150,
          }}
        />,
      )
    })
  }, [client?.logo])
  return (
    <div
      id="report_preview"
      style={{
        height: '297mm',
        width: '210mm',
        backgroundColor: 'white',
      }}
    >
      <div className="ql-container ql-editor ql-snow">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              paddingRight: 8,
              width: '200px',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 9,
            }}
          >
            <div
              style={{
                padding: 16,
                paddingLeft: 18,
                flex: 6,
                flexDirection: 'row',
              }}
            >
              <h4
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                {client?.name}
              </h4>
              <p
                style={{
                  fontSize: 12,
                }}
              >
                {client?.postalCode} {client?.address}
              </p>
              <p
                style={{
                  fontSize: 12,
                }}
              >
                {client?.phoneNumber}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 16,
                height: 100,
                flex: 6,
              }}
            >
              {logo}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // paddingBottom: '4px',
          }}
        >
          <div
            style={{
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              paddingRight: 8,
              width: '200px',
              paddingBottom: '4px',
            }}
          ></div>
          <div style={{ textAlign: 'center', flex: 9 }}>
            <h4
              style={{
                display: 'block',
                textAlign: 'center',
                width: '100%',
                marginBottom: '8px',
              }}
            >
              {study.exam.label}
            </h4>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #1ebc86',
          }}
        >
          <div
            style={{
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              paddingRight: 8,
              width: '200px',
              paddingTop: '4px',
            }}
          >
            <h4 style={{ fontWeight: 'bold', fontSize: 12 }}>
              Medecin requerant
            </h4>
          </div>
          <div
            style={{
              flex: 9,
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '4px',
            }}
          >
            <div
              style={{
                paddingLeft: 18,
                flex: 6,
              }}
            >
              Dr. {study.referringDoctor?.firstName?.toUpperCase()}{' '}
              {capitalize(study.referringDoctor?.lastName)}
              <h4
                style={{
                  fontSize: 12,
                }}
              >
                {study.referringDoctor?.rppsNumber}
              </h4>
              <h4
                style={{
                  fontSize: 12,
                }}
              >
                {study.referringDoctor?.profession}{' '}
                {study.referringDoctor?.skill}
              </h4>
            </div>
            <div
              style={{
                flex: 6,
              }}
            >
              {study.referringDoctor?.address}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              backgroundColor: '#feffff',
              textAlign: 'right',
              width: 200,
            }}
          >
            <h4
              style={{
                fontWeight: 'bold',
                fontSize: 12,
                borderTop: '1px solid #1EBC86',
                paddingTop: '6px',
                paddingRight: '4px',
              }}
            >
              Patient
            </h4>
          </div>
          <div
            style={{
              backgroundColor: '#FEFFFF',
              paddingTop: 7,
              borderTop: '1px solid #1EBC86',
              flex: 9,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                paddingLeft: 18,
                flex: 6,
              }}
            >
              {capitalize(study.patient.firstName)}{' '}
              {study.patient.lastName.toUpperCase()}
            </div>
            <div
              style={{
                flex: 6,
              }}
            >
              Nee le {moment(study.patient.birthDate).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              borderTop: '1px solid #1EBC86',
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',

              width: '200px',
            }}
          >
            <h4 style={{ fontWeight: 'bold', fontSize: 12 }}>
              Détails de l&apos;examen
            </h4>
          </div>
          <div
            style={{
              borderTop: '1px solid #1EBC86',
              paddingTop: '4px',
              paddingRight: '4px',
              flex: 9,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                paddingLeft: 18,
                flex: 6,
              }}
            >
              Examen realisé le {moment(study.plannedDate).format('DD/MM/YYYY')}
            </div>
            <div
              style={{
                flex: 6,
              }}
              dangerouslySetInnerHTML={{
                __html: parseReport(reportHtml.examDetails, getValue),
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              borderTop: '1px solid #1EBC86',
              paddingTop: '4px',
              paddingRight: '4px',
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              width: '200px',
            }}
          >
            <h4 style={{ fontWeight: 'bold', fontSize: 12 }}>Indication</h4>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: parseReport(reportHtml.indication, getValue),
            }}
            style={{
              paddingLeft: 18,
              borderTop: '1px solid #1EBC86',
              paddingTop: '4px',
              paddingRight: '4px',
              flex: 9,
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              borderTop: '1px solid #1EBC86',
              paddingTop: '4px',
              paddingRight: '4px',
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              width: '200px',
            }}
          >
            <h4 style={{ fontWeight: 'bold', fontSize: 12 }}>Technique</h4>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: parseReport(reportHtml.technique, getValue),
            }}
            style={{
              paddingLeft: 18,
              borderTop: '1px solid #1EBC86',
              paddingTop: '4px',
              paddingRight: '4px',
              flex: 9,
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              borderTop: '1px solid #1EBC86',
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              paddingRight: 8,
              width: '200px',
            }}
          >
            <h4
              style={{
                fontWeight: 'bold',
                fontSize: 12,
                paddingTop: '4px',
                paddingRight: '4px',
              }}
            >
              Resultat
            </h4>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: parseReport(reportHtml.resultat, getValue),
            }}
            style={{
              paddingLeft: 18,
              borderTop: '1px solid #1EBC86',
              paddingTop: '4px',
              flex: 9,
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              borderTop: '1px solid #1EBC86',
              backgroundColor: '#ecfcf4',
              textAlign: 'right',
              borderRight: '2px solid #1ebc86',
              paddingRight: 8,
              width: '200px',
            }}
          >
            <h4
              style={{
                fontWeight: 'bold',
                fontSize: 12,
                paddingTop: '4px',
                paddingRight: '4px',
              }}
            >
              Conclusion
            </h4>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: parseReport(reportHtml.conclusion, getValue),
            }}
            style={{
              paddingLeft: 18,
              borderTop: '1px solid #1EBC86',
              flex: 9,
            }}
          ></div>
        </div>
        {/* <Grid item xs={12} className="">
          the footer
        </Grid> */}
      </div>
    </div>
  )
}

export default ReportPreview
