import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@components/buttons'
import { PasswordInput, TextInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, makeStyles } from '@material-ui/core'

import { AppDispatch, useSelector } from '@state/store'
import { registerSchema } from '@utils/schemas'

import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { registerDoctor } from '@state/thunks/authThunk'
import { AuthCard } from '../../components/cards/AuthCard'
import { PasswordStrengthMeter } from './PasswordStrengthMeter'

const useStyles = makeStyles({
  grid: {
    padding: '0px 20px',
  },
})

interface Values {
  email: string
  newPassword: string
  confirmNewPassword: string
  rppsNumber: string
}

interface LocationState {
  state?: { rppsNumber?: string }
}
export default function Register() {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const loading = useSelector(({ loading }) => loading['auth/register'])
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Values>({
    resolver: yupResolver(registerSchema),
  })
  const watchPassword = watch('newPassword', '')

  const onSubmit = (values: Values) => {
    dispatch(
      registerDoctor({
        email: values.email.toLowerCase().trim(),
        password: values.newPassword.trim(),
        rppsNumber: values.rppsNumber,
      }),
    ).then(() => navigate('/auth/login', { replace: true }))
  }

  useEffect(() => {
    if (location.state && location.state.rppsNumber) {
      const { rppsNumber } = location.state
      setValue('rppsNumber', rppsNumber)
    } else {
      dispatch(
        enqueueSnackbar({
          type: 'error',
          message: 'Invalid user informations',
        }),
      )
      navigate('/auth/login')
    }
  }, [dispatch, history, location])

  const Content = (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.grid}
    >
      <Grid item xs={12} sm={12}>
        <TextInput
          label="email"
          name="email"
          errors={errors}
          control={control}
          variant="filled"
        />
      </Grid>

      <Grid item xs={12} sm={12} style={{ marginBottom: -20 }}>
        <PasswordInput
          name="newPassword"
          label="Password"
          variant="filled"
          errors={errors}
          control={control}
          autoComplete="new-password"
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordStrengthMeter password={watchPassword || ''} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <PasswordInput
          name="confirmNewPassword"
          variant="filled"
          label="Password"
          errors={errors}
          control={control}
          autoComplete="new-password"
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          color="secondary"
          format
        />
      </Grid>
    </Grid>
  )

  return (
    <AuthCard>
      <AuthCard.Header title="register" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions backToLoginLink />
    </AuthCard>
  )
}
