// import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

// const RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  password: Yup.string().required('validation.password.required'),
  captcha: Yup.string().required('validation.field.required'),
})

export const firstconnexionSchema = Yup.object().shape({
  medicalOrderId: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  captcha: Yup.string(),
})

export const contactFormSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  email: Yup.string().required('validation.field.required').email(),
  phoneNumber: Yup.string().required('validation.field.required'),
  subject: Yup.string().required('validation.field.required'),
  message: Yup.string().required('validation.field.required'),
})

export const forgottenPassowrdSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const registerGuestSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const updateEmailSchema = Yup.object().shape({
  email: Yup.string().lowercase().trim().email('validation.email.invalid'),
})

export const phoneCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required('validation.field.required')
    .length(6)
    .matches(/^[0-9]{6}$/, 'validation.code.match'),
})

export const shareExamSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const createReportTemplateSchema = Yup.object().shape({
  name: Yup.string().required('validation.field.required'),
  modality: Yup.string().required('validation.field.required'),
})

export const createParagraphSchema = Yup.object().shape({
  title: Yup.string().required('validation.field.required'),
  text: Yup.string().optional(),
  sex: Yup.string().optional(),
  reportTemplateId: Yup.number().required('validation.field.required'),
})

export const CreateMedicalOrderSchema = Yup.object().shape({
  examDate: Yup.date().required('Date de l’examen est obligatoire'),
  examTime: Yup.string().required('Heure de l’examen est obligatoire'),
  accessionNumber: Yup.string().required('Numéro d’accession est obligatoire'),
  requestingDoctor: Yup.object().required('Médecin est obligatoire'),
  patientId: Yup.number().optional(),
  patientPID: Yup.string().optional(),
  patientFirstName: Yup.string().optional(),
  patientLastName: Yup.string().optional(),
  patientBirthdate: Yup.date().optional(),
  patientSSN: Yup.string().optional(),
  weight: Yup.number().optional(),
  height: Yup.number().optional(),
  examId: Yup.number().required('Examen est obligatoire'),
  sex: Yup.bool().required('Sexe est obligatoire'),
})
