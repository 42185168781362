import axios from 'axios'

type ResponseToken = {
  token_type: string
  access_token: string
  refresh_token: string
  expires_in: string
  id_token: string
}
/**
 * @see https://tech.esante.gouv.fr/outils-services/pro-sante-connect-e-cps/documentation-technique
 */
type UserInfos = {
  family_name?: string
  given_name?: string
  sub: string
  iss: string
  aud: string
  SubjectNameID: string // Identifiant National du professionnel de santé
  //Liste des données du Référentiel Professionnel du PS identifié
  // Voir des exemples pour le champs SubjectRefPro, pour différents types d'utilisateurs
  // Voir le mapping Données UserInfo & correspondance avec le MOS
  SubjectRefPro: string
  UITVersion: string //	Version du jeton utilisée. « 1.0 »
  Palier_authentification: string
  // « APPPRIP3^1.2.250.1.213.1.5.1.1.1 » pour le palier 3 de l'authentification privée des acteurs sanitaires, médico-sociaux et sociaux personnes physiques.
  // « APPPRIP2^1.2.250.1.213.1.5.1.1.1 » pour le palier 2 de l'authentification privée des acteurs sanitaires, médico-sociaux et sociaux personnes physiques.
  // « APPPRIP1^1.2.250.1.213.1.5.1.1.1 » pour le palier 1 de l'authentification privée des acteurs sanitaires, médico-sociaux et sociaux personnes physiques.
  PSI_Locale: string //	1.2.250.1.213.1.3.1.1
  // contient le « [Code SubjectRole]^1.2.250.1.213.1.1.5.5» avec Code SubjectRole de la ligne sélectionnée par le PS parmi les données de SubjectRefPro.
  // Sinon, contient la valeur correspondante d’une ligne des données de SubjectRefPro.
  SubjectRole: string
  //contient le « [Code Secteur_Activite]^1.2.250.1.71.4.2.4» avec Code Secteur_Activite de la ligne sélectionnée par le PS parmi les données de SubjectRefPro.
  // Sinon, contient la valeur correspondante d’une ligne des données de SubjectRefPro.
  Secteur_Activite: string
  // contient le nom ou description de la personne morale, structure d’exercice de la ligne sélectionnée par le PS parmi les données de SubjectRefPro.
  // Sinon, contient la valeur correspondante d’une ligne des données de SubjectRefPro.
  SubjectOrganization: string
  // contient l’identifiant de la personne morale, structure d’exercice de la ligne sélectionnée par le PS parmi les données de SubjectRefPro.
  // Sinon, contient la valeur correspondante d’une ligne des données de SubjectRefPro.
  SubjectOrganizationID: string
  // VRAI si accès pour régulation médicale (exemple : Urgentiste).
  // Sinon FAUX par défaut.
  Acces_Regulation_Medicale: string
  // si Acces_Regulation_Medicale est VRAI, contient un commentaire.
  // Sinon « » par défaut.
  Mode_Acces_Raison: string
  otherIds: any[] //	Contient la table de correspondance des identifiants du PS (sous forme de liste), chaque identifiant est composé de 3 attributs : identifiant, origine, qualité (voir quelques exemples). Cette information sera mise à disposition au moment de la bascule ADELI-RPPS des infirmiers.
}
export class OpenIdService {
  static url = new URL(process.env.REACT_APP_OIDC_PROVIDER_URI as string)
  static params: any = {
    response_type: 'code',
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    scope: 'openid scope_all',
    redirect_uri: `https://${window.location.hostname}/login`,
    acr_value: 'eidas1',
    state: '',
    nonce: '',
  }

  static auth() {
    this.url.search = new URLSearchParams(this.params).toString()
    this.url.pathname = '/auth'
    //@ts-ignore
    window.open(this.url, '_self')
  }
  static async getToken(code: string) {
    const body = {
      client_secret: process.env.REACT_APP_OIDC_SECRET,
      grant_type: 'authorization_code',
      redirect_uri: this.params.redirect_uri,
      client_id: this.params.client_id,
      code,
    }
    const params = new URLSearchParams()
    for (const key in body) {
      params.append(key, body[key])
    }

    const res = await axios.post<ResponseToken>(
      'https://auth.esw.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/token',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    return res.data
  }

  static async userinfo(access_token: string) {
    const res = await axios.get<UserInfos>(
      'https://auth.esw.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/userinfo',
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    )
    return res.data
  }
}
