import { useAuthCheck } from '../hooks/useAuthCheck'
import { useSelector } from '@state/store'
import { Avatar, Backdrop, makeStyles, Theme } from '@material-ui/core'
import { AppLayout } from './appLayout'
import Logo from '../assets/img/logos/logo.png'
import { Navigate, useLocation } from 'react-router-dom'

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  animatedLogo: {
    animation: '$animation 2s ease-in-out alternate infinite',
  },
}))

const AppLayoutBase = () => {
  const isAuth = useSelector(({ auth }) => auth.isAuth)

  const isAuthChecked = useAuthCheck()
  const location = useLocation()

  const classes = useStyles()

  if (!isAuth) {
    const search = location.search

    return (
      <Navigate to={`auth/login${search}`} state={{ from: location }} replace />
    )
  }
  return isAuthChecked ? (
    <AppLayout />
  ) : (
    <Backdrop className={classes.backdrop} open={true}>
      <Avatar src={Logo} alt="logo" className={classes.animatedLogo} />
    </Backdrop>
  )
}

export default AppLayoutBase
