import { createAsyncThunk } from '@reduxjs/toolkit'
import { ExamService } from '../../services/exam.service'

export const getExamAquisitionProtocol = createAsyncThunk(
  'exam/exam-aquisition-protocol',
  async (examId: number, { rejectWithValue }) => {
    try {
      return await ExamService.getExamAquisitionProtocol(examId)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const getExamsBySiteId = createAsyncThunk(
  'exam/exams-by-site-id',
  async (siteIds: number[], { rejectWithValue }) => {
    try {
      return await ExamService.getExamsBySiteId(siteIds)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)
