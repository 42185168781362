import { useState } from 'react'

import { ConfirmPhoneDialog } from '@components/dialogs'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'

import theme from '@utils/theme'
import PhoneVerificationProfile from '../../containers/auth/PhoneVerification'
import { Text } from '../texts'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

export function UpdatePhoneForm() {
  const classes = useStyles()
  const [openPhoneDialog, setOpenPhoneDialog] = useState(false)

  const handleClosePhoneDialog = () => {
    setOpenPhoneDialog(false)
  }
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon
                className="fa fa-phone"
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item>
              <Text
                text="phoneNumberLabel"
                variant={'h6'}
                format
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
          </Grid>
        }
      />

      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PhoneVerificationProfile />
          </Grid>
        </Grid>
      </CardContent>
      <ConfirmPhoneDialog
        open={openPhoneDialog}
        onClose={handleClosePhoneDialog}
      />
    </Card>
  )
}
