import React, { useEffect, useState } from 'react'
import { Client } from '../../services/api'
import { ReportHtml } from './ReportingEditor'
import { Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { toDataUrl } from '../../utils/helpers'

const useStyles = makeStyles(() => ({
  leftPanel: {
    backgroundColor: '#E2E6F2',
    textAlign: 'right',
    borderRight: '2px solid #3A0CA1',
    paddingRight: 8,
  },
  patientLeftPanel: {
    backgroundColor: '#FEFFFF',
    textAlign: 'right',
    padding: 8,
  },
  content: {
    paddingTop: 2,
    paddingleft: '18px',
  },
  right: {
    paddingLeft: 18,
  },
  footer: {
    textAlign: 'center',
  },
}))

interface UnparsedReportPreviewProps {
  reportHtml: ReportHtml
  client: Client
}
const UnparsedReportPreview: React.FC<UnparsedReportPreviewProps> = ({
  reportHtml,
  client,
}) => {
  const [logo, setLogo] = useState<React.ReactElement>(<></>)

  const classes = useStyles()

  useEffect(() => {
    toDataUrl(client.logo).then((b64) => {
      setLogo(
        <img
          src={b64 as string}
          style={{
            width: 150,
            // height: 200,
          }}
        />,
      )
    })
  }, [client?.logo])

  return (
    <div
      id="report_preview"
      style={{
        backgroundColor: 'white',
        overflowY: 'scroll',
        height: '100%',
        zoom: 0.6,
      }}
    >
      <Grid
        container
        className="ql-container ql-editor ql-snow"
        style={{ padding: 0 }}
      >
        <Grid item xs={3} className={classes.leftPanel}></Grid>
        <Grid item xs={9} container>
          <Grid item xs={6} className={classes.right} style={{ padding: 16 }}>
            <Typography variant="h4" fontSize={12} fontWeight="bold">
              {client.name}
            </Typography>
            <Typography variant="body2" fontSize={12}>
              {client.postalCode} {client.address}
            </Typography>
            <Typography variant="body2" fontSize={12}>
              {client.phoneNumber}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 16,
            }}
          >
            {logo}
          </Grid>
        </Grid>

        <Grid item xs={3} className={classes.leftPanel}></Grid>
        <Grid item xs={9} container style={{ textAlign: 'center' }}>
          <Typography
            style={{
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
            variant="h5"
          >
            Libelle de l&apos;examen
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.leftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Medecin requerant
          </Typography>
        </Grid>
        <Grid item xs={9} container className={classes.content}>
          <Grid item xs={6} className={classes.right}>
            Dr. Jon Doe
            <Typography fontSize={12}>1122334455667788</Typography>
            <Typography fontSize={12}>Medcin generaliste</Typography>
          </Grid>
          <Grid item xs={6}>
            12 rue de la paix
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#3A0CA1' }} />
        </Grid>
        <Grid item xs={3} className={classes.patientLeftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Patient
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          style={{
            backgroundColor: '#FEFFFF',
            paddingTop: 7,
          }}
          className={classes.content}
          container
        >
          <Grid item xs={6} className={classes.right}>
            Joe Doe
          </Grid>
          <Grid item xs={6}>
            Nee le 20/12/1980
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#3A0CA1' }} />
        </Grid>
        <Grid item xs={3} className={classes.leftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Détails de l&apos;examen
          </Typography>
        </Grid>
        <Grid item xs={9} container className={classes.content}>
          <Grid item xs={6} className={classes.right}>
            Examen realise le 20/12/2020
          </Grid>
          <Grid
            item
            xs={6}
            dangerouslySetInnerHTML={{
              __html: reportHtml.examDetails,
            }}
          ></Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#3A0CA1' }} />
        </Grid>
        <Grid item xs={3} className={classes.leftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Indication
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          dangerouslySetInnerHTML={{
            __html: reportHtml.indication,
          }}
          className={classes.content}
          style={{
            paddingLeft: 18,
          }}
        ></Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#3A0CA1' }} />
        </Grid>
        <Grid item xs={3} className={classes.leftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Technique
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          dangerouslySetInnerHTML={{
            __html: reportHtml.technique,
          }}
          className={classes.content}
          style={{
            paddingLeft: 18,
          }}
        ></Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#3A0CA1' }} />
        </Grid>
        <Grid item xs={3} className={classes.leftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Resultat
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          dangerouslySetInnerHTML={{
            __html: reportHtml.resultat,
          }}
          className={classes.content}
          style={{
            paddingLeft: 18,
          }}
        ></Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#3A0CA1' }} />
        </Grid>
        <Grid item xs={3} className={classes.leftPanel}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Conclusion
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          dangerouslySetInnerHTML={{
            __html: reportHtml.conclusion,
          }}
          className={classes.content}
          style={{
            paddingLeft: 18,
          }}
        ></Grid>
        <Grid item xs={12} className={classes.footer}>
          Pied de page
        </Grid>
      </Grid>
    </div>
  )
}

export default UnparsedReportPreview
