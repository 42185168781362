import { Button } from '@components/buttons'
import { TextInput } from '@components/inputs/TextInput'
import { SelectInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Icon } from '@material-ui/core'
import { contactFormSchema } from '@utils/schemas'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import theme from '../../utils/theme'
import { Text } from '../texts'

type Values={
  source: string
  firsName: string
  lastName: string
  email: string
  phoneNumber: string
  subject: string
  message: string
}

const ContactForm = () => {
  const [isSent, setIsSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(contactFormSchema),
  })
  const navigate = useNavigate()
  const onSubmit = async (values: Values) => {
    if (isSent) {
      return
    }
    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/mail/contact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({...values, source: 'portal-web'}),
        },
      )
      if (response.status === 201) {
        setIsSent(true)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const _getText = () => {
    if (isSent) {
      return intl.formatMessage({ id: 'sent' })
    } else {
      return intl.formatMessage({ id: 'submit' })
    }
  }
  useEffect(() => {
    if (isSent) {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }, [isSent])

  return isSent ? (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div
        style={{
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          backgroundColor: '#d2f8d2',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Icon
          className="fas fa-check"
          style={{ color: theme.palette.primary.main, fontSize: '55px' }}
        />
      </div>
      <div>
        <Text text="email-sent-success" color="primary" format />
      </div>
    </Grid>
  ) : (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Grid
        item
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Link to="/">
          <Text
            text="back"
            align="left"
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#8a59ff',
              marginRight: '15px',
            }}
            format
          />
        </Link>
        <Text
          text="contact-us"
          format
          style={{ fontSize: '48px', fontWeight: 'bold', color: '#3A0BA3' }}
        />
      </Grid>

      <Grid
        container
        component="form"
        style={{
          display: 'flex',
          width: '30%',
          background: '#fff',
          border: '1px solid #dadada',
          boxShadow: '0px 5px 5px #3A0BA350',
          borderRadius: '25px',
          padding: 20,
        }}
      >
        <Grid item xs={12} sm={12}>
          <Text text="firstName" format />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="firstName"
            placeholder="firstName"
            size="small"
            variant="outlined"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Text text="lastName" format />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="lastName"
            placeholder="lastName"
            size="small"
            variant="outlined"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Text text="email" format />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="email"
            placeholder="Email"
            size="small"
            type="email"
            variant="outlined"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Text text="phoneNumberLabel" format />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name="phoneNumber"
            size="small"
            variant="outlined"
            control={control}
            errors={errors}
            inputProps={{ maxLength: 13 }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Text text="subject" format />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectInput
            name="subject"
            label="subject"
            size="small"
            variant="outlined"
            control={control}
            errors={errors}
            options={[
              { value: 'support', label: 'Aide' },
              { value: 'security', label: 'Sécurité' },
              { value: 'misc', label: 'Autre' },
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Text text="message" format />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="message"
            placeholder="Message"
            size="small"
            variant="outlined"
            control={control}
            errors={errors}
            multiline
            rows={6}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
          <Button
            type="submit"
            text={_getText()}
            onClick={handleSubmit(onSubmit)}
            color="secondary"
            loading={loading}
            fullWidth
            format
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactForm
