import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QuestionDto } from '@services/api'
import { findAllQuestions } from '@state/thunks/questionThunk'

type questionState={
  questions: QuestionDto[]
}
const initialState: questionState = {
  questions: [],
}
export interface AnswerValues {
  answer: string
  choice: boolean
  date: Date
}

const questionSlice=createSlice({
  name: 'question',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(
      findAllQuestions.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.questions = payload
      },
    ),
})

// eslint-disable-next-line no-empty-pattern
export const {} = questionSlice.actions
export default questionSlice.reducer
