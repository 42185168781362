import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  DoctorService,
  MemberService,
  PortalUserPreferencesDto,
} from '../../services/api'
import { updateColumnOrder, updateColumnWidths } from '../reducers/authReducer'

export const resetDoctorColumns = createAsyncThunk(
  'doctor/reset-portal-columns',
  async (userId: number, { rejectWithValue, dispatch }) => {
    try {
      const dto: PortalUserPreferencesDto = await DoctorService.resetColumns({
        id: userId,
      })
      dispatch(updateColumnOrder(dto.preferencesDisplayHomeColumnorder))
      dispatch(updateColumnWidths(dto.preferencesDisplayHomeColumnwidth))
      window.location.reload()
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const resetMemberColumns = createAsyncThunk(
  'doctor/reset-portal-columns',
  async (userId: number, { rejectWithValue, dispatch }) => {
    try {
      const dto: PortalUserPreferencesDto = await MemberService.resetColumns({
        id: userId,
      })
      dispatch(updateColumnOrder(dto.preferencesDisplayHomeColumnorder))
      dispatch(updateColumnWidths(dto.preferencesDisplayHomeColumnwidth))
      window.location.reload()
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const getDoctorByRpps = createAsyncThunk(
  'doctor/get-by-rpps',
  async (rppsNumber: string, { rejectWithValue }) => {
    try {
      return DoctorService.getByRpps({ rppsNumber })
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)
