import { useSelector } from '@state/store'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@components/buttons'
import { PasswordInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'

import { resetPasswordSchema } from '@utils/schemas'

import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { resetPassword } from '@state/thunks/authThunk'
import { AuthCard } from '../../components/cards/AuthCard'

import { useQuery } from '@hooks/useQuery'
import { PasswordStrengthMeter } from './PasswordStrengthMeter'

type Values = {
  newPassword: string
  confirmNewPassword: string
}

export default function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const loading = useSelector(({ loading }) => loading['auth/reset-password'])
  const [id, setId] = useState<number>()
  const [resetPasswordUUID, setResetPasswordUUID] = useState('')
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Values>({
    resolver: yupResolver(resetPasswordSchema),
  })
  const location = useLocation()
  const watchPassword = watch('newPassword', '')

  const onSubmit = async (value: Values) => {
    await dispatch(
      resetPassword({
        id: id as number,
        body: {
          newPassword: value.newPassword,
          resetPasswordUUID,
        },
      }),
    )

    navigate('/auth/login')
  }

  useEffect(() => {
    if (location && location.search) {
      const resetUUID = query.get('resetUUID')
      const userId = query.get('userId')
      if (resetUUID && userId) {
        setResetPasswordUUID(resetUUID)
        setId(+userId)
      } else {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: 'Invalid user informations',
          }),
        )
        navigate('/auth/login')
      }
    } else {
      navigate('/auth/login', { replace: true })
    }
  }, [dispatch, history, location, query, control, setValue])

  const Content = (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: 20 }}
    >
      <Grid item xs={12} style={{ marginBottom: -20 }}>
        <PasswordInput
          name="newPassword"
          size="small"
          variant="outlined"
          errors={errors}
          control={control}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordStrengthMeter password={watchPassword || ''} />
      </Grid>
      <Grid item xs={12}>
        <PasswordInput
          name="confirmNewPassword"
          size="small"
          variant="outlined"
          errors={errors}
          control={control}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          color="secondary"
          format
        />
      </Grid>
    </Grid>
  )

  return (
    <AuthCard>
      <AuthCard.Header title="forgotten-password" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions backToLoginLink />
    </AuthCard>
  )
}
