import { useDispatch } from 'react-redux'

import {
  Card,
  CardHeader,
  Grid,
  Icon,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'

import { setLanguage } from '@state/reducers/authReducer'
import theme from '@utils/theme'
import { Text } from '../texts'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type LanguageFormProps = {
  language: string
}

export function LanguageForm({ language }: LanguageFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onChange = (event: React.ChangeEvent<any>) => {
    dispatch(setLanguage(event.target.value))
  }

  const options = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
  ]

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon
                className="fa fa-globe"
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item>
              <Text
                text="language"
                variant={'h6'}
                format
                style={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select value={language} onChange={onChange}>
                {options.map((option, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    <em>{option.label}</em>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        }
      />
    </Card>
  )
}
