export const reportingVariables = [
  'patientFirstName',
  'patientLastName',
  'patientEmail',
  'patientPhone',
  'patientAddress',
  'patientCity',
  'patientZipCode',
  'patientBirthDate',
  'attendingDoctorFirstName',
  'attendingDoctorLastName',
  'referrerFirstName',
  'referrerLastName',
  'examCode',
  'examDate',
  'examLabel',
]

export function parseReport(
  report: string,
  getValue: (varName: string) => string,
) {
  const regex = /{{(.*?)}}/g
  let regexpExec: RegExpExecArray | null
  let result = report
  while ((regexpExec = regex.exec(report)) !== null) {
    if (regexpExec.index === regex.lastIndex) {
      regex.lastIndex++
    }
    console.log({ m: regexpExec })
    if (regexpExec[1]) {
      let value = getValue(regexpExec[1].trim())
      console.log({ value })
      if (!value) {
        value = `{{ Non Renseigné - ${regexpExec[1]} }}`
      }
      result = result.replace(regexpExec[0], value)
    }
  }
  return result
}
