import { TextField, TextFieldProps } from '@material-ui/core'
import { Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

export type TextInputProps = TextFieldProps & {
  name: string
  placeholder?: string
  control?: any
  errors?: DeepMap<any, FieldError>
  fullWidth?: boolean
  readOnly?: boolean
  label?: string
  inputStyle?: React.CSSProperties
}

export function TextInput({
  name,
  placeholder,
  control,
  fullWidth = true,
  errors,
  readOnly,
  InputProps,
  label,
  inputStyle,
  ...props
}: TextInputProps) {
  const intl = useIntl()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          fullWidth={fullWidth}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
              ...inputStyle,
            },
            readOnly,
            ...InputProps,
          }}
          name={name}
          placeholder={placeholder}
          error={errors && !!errors[name]}
          helperText={
            errors &&
            errors[name] &&
            intl.formatMessage({ id: errors[name].message })
          }
          label={label && intl.formatMessage({ id: label })}
        />
      )}
    />
  )
}
