/* eslint-disable indent */
import Quill from 'quill'
import React, {
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import ReportPreview from './ReportPreview'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { Client, MedicalOrderDto, ReportTemplateDto } from '../../services/api'
import { DialogTitle } from '../titles'
import SaveTemplateForm from './SaveTemplateForm'
import SaveReportForm from './SaveReportForm'
import UnparsedReportPreview from './UnparsedReportPreview'
import ReportContextMenu from './ReportContextMenu'
import { useSelector } from '../../state/store'
import ToggleButton from '../buttons/ToggleButton'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import Draggable from 'react-draggable'
import ReportMessages from './ReportMessages'
import ReportPage from './ReportPage'
import { CustomizablePage, RowInfos } from '../../common/interfaces'
import { v4 as uuidv4 } from 'uuid'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { getClient } from '../../state/thunks/studyThunk'
import { useAppDispatch } from '../../hooks/reduxHooks'
import PatientSummary from './PatientSummary'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import './ReportingEditor.css'
import DicomGallery from './DicomGallery'

export interface ReportingEditorRef {
  useTemplate: (template: ReportHtml) => void
}

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
]

export interface ReportHtml {
  examDetails: string
  indication: string
  technique: string
  resultat: string
  conclusion: string
}

export function destroy_quill(quill, el) {
  quill.theme.modules.toolbar.container.remove()
  quill.theme.modules.clipboard.container.remove()
  quill.theme.tooltip.root.remove()

  el.classList.forEach((cls) => {
    if (cls.startsWith('ql-')) {
      requestAnimationFrame(() => {
        el.classList.remove(cls)
      })
    }
  })
  el.innerHTML = quill.root.innerHTML
}

interface ReportingEditorProps {
  study?: MedicalOrderDto
  hiddenActions: (
    | 'chat'
    | 'saveModel'
    | 'saveReport'
    | 'addPage'
    | 'edit'
    | 'patientInfos'
    | 'dicomGallery'
  )[]
  editingTemplate?: ReportTemplateDto
}

const ReportingEditor = forwardRef<
  {
    useTemplate: (template: ReportTemplateDto) => void
  },
  ReportingEditorProps
>(({ study, hiddenActions, editingTemplate }, ref) => {
  const _quill = useRef<Quill>()
  const contextMenuRef = useRef<{
    divRef: RefObject<HTMLDivElement>
  }>(null)

  const [reportHtml, setReportHtml] = useState<ReportHtml>({
    indication: '',
    technique: '',
    resultat: '',
    conclusion: '',
    examDetails: '',
  })

  const dispatch = useAppDispatch()

  const [isSaveTemplateVisible, setIsSaveTemplateVisible] = useState(false)
  const [selectedEditor, setSelectedEditor] = useState('')
  const [isSaveReportVisible, setIsSaveReportVisible] = useState(false)
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isChatVisible, setIsChatVisible] = useState(false)
  const [selectedPage, setSelectedPage] = useState(0)
  const [pages, setPages] = useState<CustomizablePage[]>([])
  const [isPatientInfosVisible, setIsPatientInfosVisible] = useState(false)
  const [isdicomGalleryVisible, setIsdicomGalleryVisible] = useState(false)

  const currentMousePosition = useRef({
    x: 0,
    y: 0,
  })

  const { paragraphs, client } = useSelector((state) => ({
    paragraphs: state.reporting.paragraphs,
    client: state.study.client,
  }))

  useEffect(() => {
    if (!isEditMode || !selectedEditor) {
      return
    }
    if (_quill.current) {
      destroy_quill(_quill.current, _quill.current.root)
    }
    const placeholderRegex = /{{(.*?)}}/g

    _quill.current = new Quill(selectedEditor, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
      },
    })
    _quill.current.on('text-change', (delta) => {
      const insert =
        delta.ops.length === 2 ? delta.ops[1].insert : delta.ops[0].insert
      if (
        delta.ops.length !== 0 &&
        insert &&
        (insert as string).match(placeholderRegex)
      ) {
        setTimeout(() => {
          _quill.current?.setSelection(_quill.current.getText().length - 1, 0)
        }, 20)
      }
    })
    toggleToolbar(isEditMode)

    _quill.current?.setSelection(_quill.current.getText().length - 1, 0)

    if (selectedEditor === '#resultEditor') {
      _quill.current.root.oncontextmenu = (ev) => {
        ev.preventDefault()
        if (!contextMenuRef.current || !contextMenuRef.current.divRef.current) {
          return
        }

        contextMenuRef.current.divRef.current.style.top =
          currentMousePosition.current.y + 'px'
        contextMenuRef.current.divRef.current.style.left =
          currentMousePosition.current.x + 'px'

        setIsContextMenuVisible(true)
      }
    }
  }, [selectedEditor, isEditMode])

  useEffect(() => {
    const container = document.getElementById('editor-container')

    const reportLeft = document.getElementById('report-left')
    if (!container || !reportLeft) {
      return
    }
    container.addEventListener('mousemove', (ev) => {
      if (!contextMenuRef.current || !contextMenuRef.current.divRef.current) {
        return
      }
      currentMousePosition.current = {
        x: ev.clientX - reportLeft.clientWidth,
        y: ev.clientY + container.scrollTop,
      }
    })

    document.addEventListener('click', (ev) => {
      let node = ev.target as HTMLElement
      let isContextMenu = false
      while (node.parentNode) {
        if (node.id === 'report-context-menu') {
          isContextMenu = true
          break
        }
        node = node.parentNode as HTMLElement
      }
      if (!isContextMenu) {
        setIsContextMenuVisible(false)
      }
    })
    return () => {
      document.removeEventListener('mousemove', () => {})
      document.removeEventListener('click', () => {})
    }
  }, [])

  useEffect(() => {
    toggleToolbar(isEditMode || false)
  }, [isEditMode])

  useEffect(() => {
    if (study && study.reports.length !== 0) {
      // TODO handle multiple reports
      const report = study.reports[0]
      if (report.pages) {
        setPages(JSON.parse(report.pages))
      }
    }
  }, [study])

  useEffect(() => {
    if (!study?.site.clientId) {
      return
    }
    const clientId = study.site.clientId
    dispatch(getClient({ id: clientId }))
  }, [study?.site.clientId])

  useEffect(() => {
    if (isEditMode) {
      setIsEditMode(false)
    }
  }, [selectedPage])

  useImperativeHandle(ref, () => ({
    useTemplate(template: ReportTemplateDto) {
      let d = document.getElementById('indicationEditor')

      if (d) {
        d.innerHTML = template.indication
      }
      d = document.getElementById('techniqueEditor')
      if (d) {
        d.innerHTML = template.technique
      }
      d = document.getElementById('resultEditor')
      if (d) {
        d.innerHTML = template.resultat
      }
      d = document.getElementById('conclusionEditor')
      if (d) {
        d.innerHTML = template.conclusion
      }
      d = document.getElementById('detailsEditor')
      console.log({ d, template })
      if (d) {
        d.innerHTML = template.examDetails
      }

      setReportHtml(template)
      setSelectedEditor('')
    },
  }))

  const updateReportHtml = () => {
    const html = _quill.current?.root.innerHTML || ''

    let newReportHtml = reportHtml

    switch (selectedEditor) {
      case '#indicationEditor':
        newReportHtml = {
          ...reportHtml,
          indication: html,
        }
        break
      case '#techniqueEditor':
        newReportHtml = {
          ...reportHtml,
          technique: html,
        }
        break
      case '#resultEditor':
        newReportHtml = {
          ...reportHtml,
          resultat: html,
        }
        break
      case '#conclusionEditor':
        newReportHtml = {
          ...reportHtml,
          conclusion: html,
        }
        break
      case '#detailsEditor':
        newReportHtml = {
          ...reportHtml,
          examDetails: html,
        }
        break
      default:
        break
    }

    setReportHtml(newReportHtml)
  }

  const toggleToolbar = (isVisible: boolean) => {
    const el = document.querySelector('.ql-toolbar')
    if (el) {
      if (isVisible) {
        el.classList.remove('ql-toolbar-hidden')
      } else {
        el.classList.add('ql-toolbar-hidden')
      }
    }
  }
  const onEditorChange = (editorId: string) => {
    const el = document.querySelector(editorId)
    if (el) {
      const existing = document.querySelector('.active-editor')
      if (existing) {
        existing.classList.remove('active-editor')
      }
      el.classList.add('active-editor')
    }
    updateReportHtml()
    setSelectedEditor(editorId)
  }

  const handlePageRowsChange = (i: number, rows: RowInfos[]) => {
    const newPages = [...pages]
    const index = newPages.findIndex((p) => p.index === i)
    newPages[index].rowInfos = rows
    setPages(newPages)
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          md={10}
          style={{
            position: 'relative',
            paddingTop: 50,
            paddingBottom: 50,
            maxHeight: 'calc(100vh - 200px)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              border: '1px solid #CCC',
              alignItems: 'center',
              backgroundColor: 'white',
              height: 40,
            }}
          >
            <IconButton
              onClick={() => {
                if (selectedPage === 0) {
                  return
                }
                setSelectedPage(selectedPage - 1)
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography variant="body1">
              {selectedPage + 1}/{pages.length + 1}
            </Typography>
            <IconButton
              onClick={() => {
                if (selectedPage === pages.length) {
                  return
                }
                setSelectedPage(selectedPage + 1)
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton
              disabled={selectedPage === 0}
              onClick={() => {
                if (selectedPage === 0) {
                  return
                }
                const newPages = [...pages]
                for (let i = selectedPage; i < newPages.length; i++) {
                  newPages[i].index -= 1
                }
                newPages.splice(selectedPage - 1, 1)
                setPages(newPages)
                setSelectedPage(selectedPage - 1)
              }}
            >
              <i
                style={{
                  fontSize: '18px',
                }}
                className="fas fa-trash"
              ></i>
            </IconButton>
          </div>
          {study && isPatientInfosVisible && (
            <div
              style={{
                margin: 'auto',
                width: '210mm',
                position: 'sticky',
                top: 0,
                marginTop: -50,
                zIndex: 10,
                marginBottom: 20,
              }}
            >
              <PatientSummary patient={study.patient} exam={study.exam} />
            </div>
          )}
          {study && isdicomGalleryVisible && (
            <div
              style={{
                position: 'fixed',
                top: 5,
                left: 5,
                zIndex: 10,
                marginBottom: 20,
                backgroundColor: '#f2f4f9',
                padding: 10,
                borderRadius: 10,
                boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.6)',
                border: '1px solid lightgrey',
                maxWidth: 'calc(100vw - 60%)',
              }}
            >
              <DicomGallery study={study} />
            </div>
          )}

          <ReportContextMenu
            close={() => {
              setIsContextMenuVisible(false)
            }}
            ref={contextMenuRef}
            quill={_quill.current as Quill}
            paragraphs={paragraphs}
            style={{
              display: isContextMenuVisible ? 'block' : 'none',
            }}
          />

          {(selectedPage === 0 || selectedPage === -1) && (
            <div
              style={{
                display: isEditMode ? 'none' : 'flex',
                marginTop: 38,
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {study && client ? (
                <ReportPreview
                  reportHtml={reportHtml}
                  study={study}
                  client={client}
                />
              ) : (
                <UnparsedReportPreview
                  reportHtml={reportHtml}
                  client={{} as Client}
                />
              )}
            </div>
          )}
          {isEditMode && (
            <h3 style={{ marginTop: 58 }}>Détails de l&apos;examen</h3>
          )}
          <div
            style={{
              padding: '8px',
            }}
          >
            <div
              style={{
                visibility:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? 'visible'
                    : 'hidden',
                ...(!isEditMode && { height: 0 }),
                minHeight: isEditMode ? '30px' : 0,
                maxHeight: 400,
              }}
              className="ql-container ql-editor ql-snow"
              onClick={() => {
                onEditorChange('#detailsEditor')
              }}
              id="detailsEditor"
              onBlur={() => {
                updateReportHtml()
              }}
            ></div>
            {isEditMode && <h3>Indication</h3>}
            <div
              style={{
                visibility:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? 'visible'
                    : 'hidden',
                ...(!isEditMode && { height: 0 }),
                minHeight:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? '30px'
                    : 0,
                maxHeight: 400,
              }}
              className="ql-container ql-editor ql-snow"
              onClick={() => {
                onEditorChange('#indicationEditor')
              }}
              id="indicationEditor"
              onBlur={() => {
                updateReportHtml()
              }}
            ></div>
            {isEditMode && <h3>Technique</h3>}
            <div
              className="ql-container ql-editor ql-snow"
              onClick={() => {
                onEditorChange('#techniqueEditor')
              }}
              style={{
                visibility:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? 'visible'
                    : 'hidden',
                ...(!isEditMode && { height: 0 }),
                minHeight:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? '30px'
                    : 0,
                maxHeight: 400,
              }}
              id="techniqueEditor"
              onBlur={() => {
                updateReportHtml()
              }}
            ></div>
            {isEditMode && <h3>Résultat</h3>}
            <div
              className="ql-container ql-editor ql-snow"
              style={{
                visibility:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? 'visible'
                    : 'hidden',
                ...(!isEditMode && { height: 0 }),
                minHeight:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? '30px'
                    : 0,
                maxHeight: 600,
              }}
              onClick={() => {
                onEditorChange('#resultEditor')
              }}
              id="resultEditor"
              onBlur={() => {
                updateReportHtml()
              }}
            ></div>
            {isEditMode && <h3>Conclusion</h3>}
            <div
              className="ql-container ql-editor ql-snow"
              style={{
                visibility:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? 'visible'
                    : 'hidden',
                ...(!isEditMode && { height: 0 }),
                minHeight:
                  (selectedPage === 0 || selectedPage === -1) && isEditMode
                    ? '30px'
                    : 0,
              }}
              onClick={() => {
                onEditorChange('#conclusionEditor')
              }}
              id="conclusionEditor"
              onBlur={() => {
                updateReportHtml()
              }}
            ></div>
          </div>
        </Grid>
        {/* #region ------------------------------------- ACTION BUTTONS  ------------------------------ */}
        <Grid
          md={2}
          item
          marginTop="42px"
          position="fixed"
          right={0}
          top={0}
          style={{
            borderLeft: '1px solid #CCC',
            backgroundColor: '#E2E6F2',
            height: '100vh',
            width: '10vw',
            paddingTop: 50,
            // box shadow to the left
            boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.4)',
          }}
        >
          {!hiddenActions.includes('patientInfos') && (
            <ToggleButton
              onClick={() => {
                if (selectedPage !== 0) {
                  setSelectedPage(0)
                }
                setIsPatientInfosVisible(!isPatientInfosVisible)
              }}
              isActive={isPatientInfosVisible}
              icon={<InfoIcon style={{ color: '#1EBC86' }} />}
              text="Infos Patient"
            />
          )}
          {!hiddenActions.includes('dicomGallery') && (
            <ToggleButton
              onClick={() => {
                if (selectedPage !== 0) {
                  setSelectedPage(0)
                }
                setIsdicomGalleryVisible(!isdicomGalleryVisible)
              }}
              isActive={isdicomGalleryVisible}
              icon={<CropOriginalIcon style={{ color: '#1EBC86' }} />}
              text="Images Clés"
            />
          )}
          {!hiddenActions.includes('edit') && (
            <ToggleButton
              onClick={() => {
                if (selectedPage !== 0) {
                  setSelectedPage(0)
                }
                setIsEditMode(!isEditMode)
              }}
              isActive={isEditMode}
              icon={<DriveFileRenameOutlineIcon style={{ color: '#1EBC86' }} />}
              text="Edit mode"
            />
          )}
          {!hiddenActions.includes('chat') && (
            <ToggleButton
              isActive={isChatVisible}
              icon={<ChatBubbleOutlineIcon style={{ color: '#1EBC86' }} />}
              text="Messages"
              onClick={() => {
                setIsChatVisible(!isChatVisible)
              }}
              style={{ marginTop: 12 }}
            />
          )}
          {!hiddenActions.includes('saveModel') && (
            <ToggleButton
              isActive={false}
              icon={
                <i
                  style={{ color: '#1EBC86', fontSize: 20 }}
                  className="far fa-map"
                ></i>
              }
              text="Enregistrer Modeles"
              style={{ marginTop: 12 }}
              onClick={() => {
                updateReportHtml()
                setIsSaveTemplateVisible(true)
              }}
            />
          )}
          {!hiddenActions.includes('saveReport') && (
            <ToggleButton
              isActive={false}
              icon={
                <i
                  style={{ color: '#1EBC86', fontSize: 20 }}
                  className="far fa-clipboard"
                ></i>
              }
              text="Enregistrer Compte rendu"
              style={{ marginTop: 12 }}
              onClick={() => {
                updateReportHtml()
                setIsSaveReportVisible(true)
                setSelectedPage(-1)
              }}
            />
          )}
          {!hiddenActions.includes('addPage') && (
            <ToggleButton
              isActive={false}
              icon={
                <i
                  style={{ color: '#1EBC86', fontSize: 20 }}
                  className="far fa-plus-square"
                ></i>
              }
              text="Ajouter page"
              style={{ marginTop: 12 }}
              onClick={() => {
                updateReportHtml()
                const index = pages.length + 1
                setPages([
                  ...pages,
                  {
                    index,
                    rowInfos: [{ children: [{ id: uuidv4() }] }],
                    title: '',
                  },
                ])
                setSelectedPage(index)
              }}
            />
          )}
        </Grid>
        {/* #endregion ----------------------------------------------------------------- */}
        <Grid xs={10} item>
          {pages.map((p) => (
            <>
              {(selectedPage === p.index || selectedPage === -1) && (
                <ReportPage
                  key={p.index}
                  pageIndex={p.index}
                  onChange={(rows) => handlePageRowsChange(p.index, rows)}
                  rowInfos={p.rowInfos}
                  isSaving={selectedPage === -1}
                />
              )}
            </>
          ))}
        </Grid>
      </Grid>

      <Dialog
        open={isSaveTemplateVisible}
        onClose={() => {
          setIsSaveTemplateVisible(false)
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 20,
            width: '100%',
            background: '#E2E6F2',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
          }}
        >
          <DialogTitle title="Save template" />
          <DialogContent>
            <SaveTemplateForm
              onClose={() => setIsSaveTemplateVisible(false)}
              reportBody={reportHtml}
              defaultModality={study?.exam?.modality}
              editingTemplate={editingTemplate}
            />
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog
        open={isSaveReportVisible}
        onClose={() => {
          setIsSaveReportVisible(false)
          setSelectedPage(0)
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 20,
            width: '100%',
            background: '#E2E6F2',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
          }}
        >
          <DialogTitle title="Save template" />
          <DialogContent>
            {study && (
              <SaveReportForm
                pages={pages}
                medicalOrder={study}
                reportHtml={reportHtml}
                onClose={() => {
                  setIsSaveReportVisible(false)
                  setSelectedPage(0)
                }}
              />
            )}
          </DialogContent>
        </Box>
      </Dialog>
      {isChatVisible && (
        <div>
          <Draggable>
            <div
              style={{
                width: '50vw',
                height: '50vh',
                position: 'fixed',
                bottom: 40,
                right: 0,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  backgroundColor: 'white',
                }}
              >
                <Button
                  color="error"
                  className="times"
                  onClick={() => setIsChatVisible(false)}
                  style={{
                    backgroundColor: '#FEF1F2',
                  }}
                >
                  <i
                    style={{
                      fontSize: '1.5rem',
                    }}
                    className="fas fa-times"
                  ></i>
                </Button>
              </div>
              <ReportMessages medicalOrderId={study?.id || -1} />
            </div>
          </Draggable>
        </div>
      )}
    </>
  )
})

export default ReportingEditor
