import Footer from '@components/Footer'
import { Outlet } from 'react-router-dom'
import background from '../assets/img/bg-portal-no-logo.png'

export default function AuthLayout() {
  return (
    <>
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#2f2f2f',
        }}
      >
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            zIndex: 2,
          }}
        >
          <Outlet />
          <Footer />
        </div>
      </main>
    </>
  )
}
