import {
  LanguageForm,
  LastConnexionForm,
  UpdateEmailForm,
  UpdatePasswordForm,
  UpdatePhoneForm,
} from '@components/forms'
import { Grid } from '@material-ui/core'
import { useSelector } from '@state/store'
import { makeStyles } from '@material-ui/core/styles'

import { UserInfosCard } from '@components/cards'
import { AccountDto } from '@services/api'

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '85vh',
    overflow: 'auto',
    zIndex: 1,
    paddingTop: 10,
    paddingLeft: '10vw',
    paddingRight: '10vw',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '5vw',
      paddingRight: '5vw',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15vw',
      paddingRight: '15vw',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '20vw',
      paddingRight: '20vw',
    },
  },
}))

export default function Profile() {
  const classes = useStyles()

  const { language, user, account } = useSelector(({ auth }) => ({
    language: auth.language,
    account: auth.account as AccountDto,
    user: auth.user,
  }))

  return (
    <Grid
      container
      spacing={1}
      style={{
        paddingLeft: 10,
        paddingRight: 10,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={12}>
          <LanguageForm language={language} />
        </Grid>
        <Grid item xs={12}>
          {user && <UserInfosCard user={user} account={account} />}
        </Grid>
        <Grid item xs={12}>
          <UpdatePasswordForm account={account} />
        </Grid>
        <Grid item xs={12}>
          <UpdateEmailForm currentEmail={account.email} />
        </Grid>
        <Grid item xs={12}>
          <UpdatePhoneForm />
        </Grid>
        <Grid item xs={12}>
          <LastConnexionForm account={account} />
        </Grid>
      </Grid>
    </Grid>
  )
}
