import { Text } from '@components/texts'
import { Grid, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '56px',
  },
  links: {
    marginRight: '20px',
    fontSize: '14px',
    color: '#a0a0a0',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.footer}
    >
      <Link to="/public/terms">
        <Text text="privacy" align="center" className={classes.links} format />
      </Link>
     
      <Link to="/public/contact">
        <Text
          text="contact-us"
          align="center"
          className={classes.links}
          format
        />
      </Link>
      <Grid item xs={12} style={{ marginTop: '-10px' }}>
        <Text
          text="copyright"
          align="center"
          style={{ marginRight: '20px', fontSize: '12px', color: '#a0a0a0' }}
          format
        />
      </Grid>
    </Grid>
  )
}
