import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Autocomplete,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { RppsAutocompleteByNameInput } from '@components/inputs/RppsAutocompleteByNameInput'
import './MedicalOrderForm.css'

import { findPatients } from '../../state/thunks/patientThunk'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import {
  CreateMedicalOrderDto,
  Member,
  RppsDto,
  SexEnum,
} from '../../services/api'
import { getExamsBySiteId } from '../../state/thunks/examThuk'
import { createStudy } from '../../state/thunks/studyThunk'
import { CreateMedicalOrderSchema } from '../../utils/schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { setIsCreateReportDialogOpen } from '../../state/reducers/studyReducer'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'

interface IFormInput {
  examDate: Date
  examTime: string
  accessionNumber: string
  requestingDoctor: RppsDto
  patientId?: number
  patientPID: string
  patientFirstName: string
  patientLastName: string
  patientBirthdate: Date
  patientSSN: string
  weight: number
  height: number
  examId: number
  sex: boolean
}

const MedicalOrderForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(CreateMedicalOrderSchema),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFileType, setSelectedFileType] = useState<string>('')
  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{ type: string; file: File }>
  >([])
  const [isNewPatient, setIsNewPatient] = useState(false)
  const selectedPatient = watch('patientId')
  const [openDialog, setOpenDialog] = useState(false)
  const [isSearchPatientOpen, setIsSearchPatientOpen] = useState(false)
  const [tempFile, setTempFile] = useState<File | null>(null)
  const [isFetching, setIsFetching] = useState(false)

  const patients = useAppSelector((state) => state.patients.patients)
  const member = useAppSelector((state) => state.auth.user) as any as Member
  const exams = useAppSelector((state) => state.exam.exams)

  const dispatch = useAppDispatch()

  const onSubmit = (data: IFormInput) => {
    const dto: CreateMedicalOrderDto = {
      examId: data.examId,
      accessionNumber: data.accessionNumber,
      date: data.examDate,
      time: data.examTime,
      doctorRpps: data.requestingDoctor.rppsNumber,
      patientId: data.patientId,
      patient: {
        firstName: data.patientFirstName,
        lastName: data.patientLastName,
        birthDate: data.patientBirthdate,
        ssn: data.patientSSN,
        pId: data.patientPID,
        weight: data.weight,
        height: data.height,
        sex: data.sex ? SexEnum.M : SexEnum.F,
      },
    }
    setIsLoading(true)
    dispatch(createStudy(dto))
      .unwrap()
      .then(() => {
        setIsLoading(false)
        dispatch(setIsCreateReportDialogOpen(false))
        dispatch(
          enqueueSnackbar({
            message: 'Examen créé avec succès',
            type: 'success',
          }),
        )
      })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      setTempFile(file)
      setOpenDialog(true)
    }
  }

  const confirmUpload = () => {
    if (tempFile && selectedFileType) {
      setUploadedFiles([
        ...uploadedFiles,
        { type: selectedFileType, file: tempFile },
      ])
    }
    setOpenDialog(false)
    setTempFile(null)
    setSelectedFileType('')
  }

  const cancelUpload = () => {
    setOpenDialog(false)
    setTempFile(null)
    setSelectedFileType('')
  }

  const removeFile = (index: number) => {
    const newFiles = [...uploadedFiles]
    newFiles.splice(index, 1)
    setUploadedFiles(newFiles)
  }
  const fetchPatients = debounce((value: string) => {
    dispatch(findPatients(value))
      .unwrap()
      .then(() => {
        setIsFetching(false)
      })
  }, 400)

  const onSearchPatient = (value: string) => {
    setIsFetching(true)
    fetchPatients(value)
  }

  useEffect(() => {
    dispatch(getExamsBySiteId(member.client.sites.map((s) => s.id)))
  }, [])

  const patientId = watch('patientId')

  useEffect(() => {
    if (patientId) {
      setIsNewPatient(false)
      const patient = patients.find((p) => p.id === patientId)
      if (!patient) {
        return
      }
      if (patient.external_id.length !== 0) {
        setValue('patientPID', patient.external_id[0].value)
      }
      setValue('patientFirstName', patient.firstName)
      setValue('patientLastName', patient.lastName)
      setValue('patientBirthdate', patient.birthDate)
      setValue('patientSSN', patient.ssn)
      setValue('weight', patient.weight)
      setValue('height', patient.height)
      setValue('sex', patient.sex === SexEnum.M ? true : false)
    } else {
      setValue('patientPID', '')
      setValue('patientFirstName', '')
      setValue('patientLastName', '')
      setValue('patientBirthdate', new Date())
      setValue('patientSSN', '')
      setValue('weight', 0)
      setValue('height', 0)
    }
  }, [patientId])

  useEffect(() => {
    if (isNewPatient && patientId) {
      setValue('patientId', undefined)
    }
  }, [isNewPatient, patientId])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={1}
        sx={{
          padding: '2rem',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h2>Register new exam</h2>
        </Grid>
        <Grid item xs={12}>
          <h3>Rendez-vous</h3>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="examDate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Exam Date"
                type="date"
                error={!!errors.examDate}
                helperText={errors.examDate?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="examTime"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Exam Time"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.examTime}
                helperText={errors.examTime?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="accessionNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Accession Number"
                fullWidth
                error={!!errors.accessionNumber}
                helperText={errors.accessionNumber?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <h3>Docteur</h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <RppsAutocompleteByNameInput
            control={control}
            errors={errors}
            name="requestingDoctor"
            setValue={setValue}
          />
          <FormHelperText error={!!errors.requestingDoctor}>
            {(errors.requestingDoctor as any)?.message}
          </FormHelperText>
        </Grid>

        <Grid item xs={12}>
          <h3>Patient</h3>
        </Grid>
        <Grid item xs={6}>
          {!isNewPatient && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  sx={{
                    width: '300px',
                  }}
                  open={isSearchPatientOpen}
                  componentsProps={{
                    popper: {
                      style: {
                        width: 'fit-content',
                      },
                    },
                  }}
                  onOpen={() => {
                    setIsSearchPatientOpen(true)
                  }}
                  onClose={() => {
                    setIsSearchPatientOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) =>
                    option.firstName + ' ' + option.lastName
                  }
                  options={patients}
                  onChange={(_, value) =>
                    setValue('patientId', value?.id || -1)
                  }
                  loading={isFetching}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(ev) => {
                        onSearchPatient(ev.target.value)
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isFetching ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <FormHelperText error={!!errors.patientId}>
                  {errors.patientId?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Checkbox onChange={(_, checked) => setIsNewPatient(checked)} />
              Créer un nouveau
            </div>
          </FormControl>
        </Grid>

        {(isNewPatient || selectedPatient) && (
          <>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Sexe</FormLabel>
                <Controller
                  name="sex"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      {...field}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Femme"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Homme"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientPID"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Patient PID" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientFirstName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="First Name" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientLastName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Last Name" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientBirthdate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Birthdate"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientSSN"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Social Security Number"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="weight"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Weight (kg)" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="height"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Height (cm)" fullWidth />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <h3>Examen</h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Exam</InputLabel>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={exams}
              onChange={(_, value) => setValue('examId', value?.id || -1)}
              renderInput={(params) => <TextField {...params} label="Exam" />}
            />
            <FormHelperText error={!!errors.examId}>
              {errors.examId?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" component="label" className="button-main">
            Ajouter un document
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <ul className="file-list">
            {uploadedFiles.map((uploadedFile, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${uploadedFile.type}: ${uploadedFile.file.name}`}
                  sx={{
                    textTransform: 'uppercase',
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="view"
                    onClick={() => {
                      /* implement view functionality */
                    }}
                    className="view-button"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeFile(index)}
                    className="delete-button"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </ul>

          <Dialog
            open={openDialog}
            onClose={cancelUpload}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Select Document Type</DialogTitle>
            <DialogContent>
              <FormControl
                fullWidth
                sx={{
                  marginTop: '2rem ',
                }}
              >
                <InputLabel>Type of File</InputLabel>
                <Select
                  value={selectedFileType}
                  onChange={(e) => setSelectedFileType(e.target.value)}
                >
                  <MenuItem value="prescription">Prescription</MenuItem>
                  <MenuItem value="labResult">Lab Result</MenuItem>
                  <MenuItem value="oldReport">Old Report</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={cancelUpload}
                variant="contained"
                className="button-secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={confirmUpload}
                variant="contained"
                className="button-main"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            fullWidth
            className="button-main"
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sauvegarder'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default MedicalOrderForm
