import { useSelector } from '@state/store'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

import { ConsentDialog } from '@components/dialogs'
import Footer from '@components/Footer'
import Navbar from '@components/NavBar'

// import ConfirmMobileModal from '@containers/app/Confir mMobileModal'
// import useWindowSize from '@hooks/useWindowSize'

import * as Sentry from '@sentry/react'
import { AccountDto, AccountRoleEnum, DoctorDto, Patient } from '@services/api'
import { findMyInfos, logout, updatePatient } from '@state/thunks/authThunk'
import { debounce } from 'lodash'

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

export function AppLayout() {
  const dispatch = useDispatch()
  // const [open, setOpen] = useState(false)
  const { isAuth, account, user } = useSelector(({ auth, loading }) => ({
    isAuth: auth.isAuth,
    account: auth.account as AccountDto, //Account will exist
    loading: loading['user/findMyInfos'],
    hasConsent: auth.hasConsent,
    mobileConfirmed: true, //auth.user.isPhoneConfirmed,
    user: auth.user,
  }))

  let logoutTimeout: NodeJS.Timeout | undefined
  let expirationTime: number | undefined

  /**
   * Clear the time out if it exists
   */
  const clearTimeOut = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  /**
   * Set the time out with the account infos
   */
  const setTimeOut = () => {
    // if (account) {
    //   const timeout = 120 * 1000
    //   expirationTime = Date.now() + timeout
    //   logoutTimeout = setTimeout(() => setOpen(true), timeout)
    // }
  }

  /**
   * Clear the time out and set a new one
   */
  const resetTimeout = () => {
    clearTimeOut()
    setTimeOut()
  }

  const handleLogout = () => {
    clearTimeOut()
    dispatch(logout())
  }
  // const handleStayLogged = () => {
  //   resetTimeout()
  //   setOpen(false)
  // }

  const setConsent = () => {
    if (!user) {
      return
    }
    dispatch(updatePatient({ id: user.id, body: { hasConsent: true } }))
  }

  useEffect(() => {
    if (isAuth) dispatch(findMyInfos())
  }, [])

  const debouncedResetTimeout = useCallback(
    debounce(() => {
      resetTimeout()
      setTimeOut() // Move setTimeOut here
    }, 200),
    [],
  )
  useEffect(() => {
    for (const i in events) {
      window.addEventListener(events[i], debouncedResetTimeout)
    }
    resetTimeout() // Set initial timeout
    return () => {
      clearTimeOut()
      for (const i in events) {
        window.removeEventListener(events[i], debouncedResetTimeout)
      }
    }
  }, [])

  useEffect(() => {
    if (expirationTime && expirationTime < Date.now()) {
      handleLogout()
      Sentry.captureMessage('Something went wrong', {
        contexts: {
          params: { logoutTimeout: logoutTimeout?.toString() },
        },
      })
    }
  }, [logoutTimeout])

  const navigate = useNavigate()
  useEffect(() => {
    if (isAuth) {
      if (!account.email && account.role === AccountRoleEnum.doctor) {
        navigate('/auth/register-doctor', {
          state: { rppsNumber: (user as DoctorDto).rppsNumber },
        })
      } else if (!account.isEmailVerified) {
        navigate('/auth/login')
      } else {
        navigate('/')
      }
    }
  }, [isAuth, account])
  const _isConsentOpen = !(user as Patient)?.hasConsent
  return (
    <>
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#E2E6F2',
          height: '100vh',
        }}
      >
        <Navbar />
        <Outlet />
        <Footer />
      </main>
      {/* <AutoLogoutDialog
        open={open}
        onClose={() => setOpen(false)}
        handleStayLogged={handleStayLogged}
        handleLogout={handleLogout}
      /> */}
      {account?.role === AccountRoleEnum.patient && (
        <ConsentDialog open={_isConsentOpen} acceptConsent={setConsent} />
      )}
    </>
  )
}
